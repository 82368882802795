<template>
  <v-container
    fluid
    style="
      background-color: white;
      min-height: 300px;
      width: 700px;
      border-radius: 5px;
    "
  >
    <v-card-title class="font-weight-bold text-h5" style="text-align: center">
      Reminder Details
    </v-card-title>

    <v-card-text class="text-body-1">
      <v-row class="mt-2">
        <v-col cols="6">
          <strong class="font-weight-bold">Title:</strong> {{ reminder.title }}
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Type:</strong>
          <v-chip :color="getTypeColor(reminder.type)" dark>
            {{ lowercaseSecondToEnd(reminder.type) }}
          </v-chip>
          <v-chip v-if="reminder.taskId" color="red" dark> Task </v-chip>
          <v-chip v-if="reminder.meetingId" color="yellow-darken-3" dark>
            Meeting
          </v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">
            {{
              reminder.type === 'SCHEDULED' ? 'Early Reminder:' : 'Recurrence:'
            }}
          </strong>
          <div>
            {{
              reminder.type === 'SCHEDULED'
                ? reminder.earlyReminder
                : reminder.recurrence
            }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Alarm Date:</strong>
          <div>{{ formatDate(reminder.alarmDate) }}</div>
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Creation Date:</strong>
          <div>{{ formatDate(reminder.createDate) }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Time Remaining:</strong>
          <div>{{ reminder.remained }}</div>
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Created By:</strong>
          <br />
          <v-chip :style="{ color: 'blue' }">{{ reminder.ownerName }}</v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <strong class="font-weight-bold">Note:</strong>
          <div>{{ reminder.note }}</div>
        </v-col>
      </v-row>
      <div v-if="reminder.taskId === null && reminder.meetingId === null">
        <v-divider class="my-4"></v-divider>
        <v-row justify="end">
          <v-btn
            variant="outlined"
            class="mr-1 ma-3"
            color="button_color"
            @click="editReminder(reminder)"
          >
            Edit Reminder
          </v-btn>
          <v-btn
            :disabled="disableDeleteButton"
            class="mr-9 ma-3"
            color="red"
            @click="deleteReminder(reminder)"
          >
            Delete
          </v-btn>
        </v-row>
      </div>
    </v-card-text>
  </v-container>
</template>

<script>
export default {
  name: 'ReminderDetailsModal',
  props: ['reminder'],
  data: () => ({
    disableDeleteButton: false,
  }),
  methods: {
    getTypeColor(type) {
      return type === 'SCHEDULED' ? 'blue' : 'green';
    },
    lowercaseSecondToEnd(str) {
      if (str.length < 2) return str;
      return str[0] + str.slice(1).toLowerCase();
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return date.toLocaleDateString('en-US', options);
    },
    editReminder(reminder) {
      this.$emit('editReminder', reminder);
    },
    deleteReminder(reminder) {
      this.disableDeleteButton = true;
      this.$emit('deleteReminder', reminder.id);
    },
  },
};
</script>
