<script>
import { formatNumber, formatNumberInternal } from '@/utils/helpers';

export default {
  name: 'pastInternalTransferConfirmationModal',
  props: ['internalTransferType', 'registerForm'],

  data() {
    return {};
  },
  methods: {
    formatNumberInternal,
    emitClose() {
      this.$emit('close');
    },
    emitConfirm() {
      this.$emit('confirm');
    },
    internalTransferTypeFormat(internalTransferType) {
      let transactionTypeDisplay = '';
      if (internalTransferType === 'TRUSTEE_TO_TRUSTEE') {
        transactionTypeDisplay = 'Past Internal Trustee';
      } else if (internalTransferType === 'CUSTOMER_TO_CUSTOMER') {
        transactionTypeDisplay = 'Past Internal Customer';
      }
      return transactionTypeDisplay;
    },
  },
  computed: {
    sourceActualAmount() {
      return this.registerForm.sourceAccountId
        ? formatNumber(this.registerForm.amount) +
            ' ' +
            this.registerForm.sourceCurrency.symbol
        : null;
    },

    targetActualAmount() {
      return this.registerForm.sourceAccountId
        ? formatNumber(this.registerForm.amount) +
            ' ' +
            this.registerForm.targetCurrency.symbol
        : null;
    },
    withdrawalCommissionAmount() {
      return this.registerForm.withdrawalCommissionAmount
        ? formatNumber(this.registerForm.withdrawalCommissionAmount) +
            ' ' +
            this.registerForm.sourceCurrency.symbol
        : null;
    },
    depositCommissionAmount() {
      return this.registerForm.depositCommissionAmount
        ? formatNumber(this.registerForm.depositCommissionAmount) +
            ' ' +
            this.registerForm.sourceCurrency.symbol
        : null;
    },

    withdrawalCommissionPercent() {
      return this.registerForm.withdrawalCommissionPercent
        ? formatNumber(this.registerForm.withdrawalCommissionPercent) + ' %'
        : null;
    },

    depositCommissionPercent() {
      return this.registerForm.depositCommissionPercent
        ? formatNumber(this.registerForm.depositCommissionPercent) + ' %'
        : null;
    },

    spreadPercent() {
      return this.registerForm.spreadValue
        ? formatNumber(this.registerForm.spreadValue) + ' %'
        : null;
    },
  },
};
</script>

<template>
  <v-container
    fluid
    style="
      background-color: white;
      min-height: 300px;
      width: 900px;
      border-radius: 5px;
    "
  >
    <v-card style="padding: 20px">
      <div class="border-1">
        <h5 style="text-align: center">
          {{ internalTransferTypeFormat(internalTransferType) }}
        </h5>
        <div class="grid-card-2">
          <p>Date:</p>
          <p class="m-l-35">{{ registerForm.date }}</p>
          <p>Source Account:</p>
          <p class="m-l-35">{{ registerForm.sourceAccountCofferName }}</p>
          <p>Source Amount:</p>
          <p class="m-l-35">{{ sourceActualAmount }}</p>
          <p>Target Account:</p>
          <p class="m-l-35">{{ registerForm.targetAccountCofferName }}</p>
          <p>Target Amount:</p>
          <p class="m-l-35">
            {{ targetActualAmount }}
          </p>
          <p v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'">
            Payer/Payee:
          </p>
          <p
            v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'"
            class="m-l-35"
          >
            {{ registerForm.secondaryThirdPartyCofferName }}
          </p>
          <p>Spread%:</p>
          <p class="m-l-35">{{ spreadPercent }}</p>
          <p>Ask Amount:</p>
          <p class="m-l-35">
            {{ formatNumberInternal(registerForm.askAmount) }}
          </p>
          <p>Bid Amount:</p>
          <p class="m-l-35">
            {{ formatNumberInternal(registerForm.bidAmount) }}
          </p>
          <p>{{ 'exchange rate' }}:</p>
          <p class="m-l-35">{{ registerForm.exchangeRate }}</p>
          <p>Reference No:</p>
          <p class="m-l-35">{{ registerForm.referenceNo }}</p>
          <p>Source Com Amt:</p>
          <p class="m-l-35">{{ withdrawalCommissionAmount }}</p>
          <p>Source Com %:</p>
          <p class="m-l-35">{{ withdrawalCommissionPercent }}</p>
          <p>Target Com Amt:</p>
          <p class="m-l-35">{{ depositCommissionAmount }}</p>
          <p>Target Com %:</p>
          <p class="m-l-35">{{ depositCommissionPercent }}</p>
          <p>Note:</p>
          <p class="m-l-35">{{ registerForm.description }}</p>
        </div>
        <v-row justify="end">
          <div class="d-flex top-20">
            <v-btn
              color="button_color"
              style="margin-right: 10px"
              @click="emitConfirm"
              >{{ 'confirm' }}
            </v-btn>
            <v-btn color="button_color" @click="emitClose">Cancel </v-btn>
          </div>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<style scoped></style>
