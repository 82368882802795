import axios from 'axios';
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class individualService {
  async addIndividual(individual) {
    try {
      return await axios.post(BASE_URL + 'identity/addIdentity', individual, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async editIndividual(individual) {
    try {
      return await axios.post(BASE_URL + 'identity/editIdentity', individual, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getIndividuals() {
    try {
      return await axios.get(BASE_URL + 'identity/identities', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteIdentity(id) {
    try {
      let response = await axios.delete(BASE_URL + 'identity/delete', {
        headers: authHeader(),
        params: {
          identityId: id,
        },
      });

      return response;
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new individualService();
