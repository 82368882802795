<template>
  <div>
    <v-container
      fluid
      style="
        background-color: white;
        width: 500px;
        height: 450px;
        border-radius: 5px;
      "
    >
      <v-form :disabled="disableForm" ref="form">
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-text-field
              v-model="currencyName"
              :rules="requiredRule"
              label="Currency Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="currencySymbol"
              :rules="symbolValidationRule"
              label="Symbol"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="currencyCode"
              :rules="isTwoDigitNumber"
              label="Currency Code"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="xeequivalent"
              label="Equivalent (must have 3 characters)"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <div class="d-flex top-20 mb-5 mr-5">
            <v-btn
              :disabled="disableForm"
              v-if="!is_editing_currency"
              class="ml-4"
              color="button_color"
              @click="addCurrency"
            >
              Add Currency
            </v-btn>
            <v-btn
              :disabled="disableForm"
              v-else
              class="ml-4"
              color="button_color"
              @click="addCurrency"
            >
              Edit Currency
            </v-btn>
          </div>
        </v-row>
      </v-form>
      <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
      ></Success_Modal>
      <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
      ></Error_Modal>
    </v-container>
  </div>
</template>
<script>
import {
  requiredRule,
  isTwoDigitNumber,
  symbolValidationRule,
} from '@/utils/rules.js';
import { unifiedResponseHandler } from '@/utils/helpers';
import currencyService from '@/services/currency.service';
import emitter from '@/plugins/mitt';
import { mapGetters } from 'vuex';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';

export default {
  name: 'new_currency_modal_v01',
  components: { Success_Modal, Error_Modal },
  data() {
    return {
      error: false,
      success: false,
      message: null,
      currencyName: null,
      currencySymbol: null,
      currencyCode: null,
      xeequivalent: null,
      disableForm: false,
      id: null,
      requiredRule: requiredRule,
      symbolValidationRule: symbolValidationRule,
      isTwoDigitNumber: isTwoDigitNumber,
    };
  },
  methods: {
    async addCurrency() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.disableForm = true;
        let form = {
          id: this.id,
          name: this.currencyName,
          symbol: this.currencySymbol,
          currencyCode: this.currencyCode,
          xeequivalent: this.xeequivalent,
        };
        let response = null;
        if (!this.is_editing_currency) {
          response = unifiedResponseHandler(
            await currencyService.addCurrency(form)
          );
        } else {
          response = unifiedResponseHandler(
            await currencyService.editCurrency(form)
          );
        }
        if (response.success) {
          this.success = true;
          this.message = response.data;
          emitter.emit('new_currency', response);
        } else {
          this.message = response.message;
          this.error = true;
        }
      }
      this.disableForm = false;
    },
  },
  async mounted() {
    if (this.is_editing_currency) {
      this.id = this.chosen_currency.id;
      this.currencyCode = this.chosen_currency.currencyCode;
      this.currencySymbol = this.chosen_currency.symbol;
      this.currencyName = this.chosen_currency.name;
      this.xeequivalent = this.chosen_currency.xeequivalent;
      console.log(this.chosen_currency);
    }
  },
  computed: {
    ...mapGetters(['chosen_currency', 'is_editing_currency']),
  },
  beforeUnmount() {
    emitter.emit('new_currency', null);
    this.$store.dispatch('setChosenCurrency', null);
    this.$store.dispatch('setIsEditingCurrency', false);
  },
};
</script>
