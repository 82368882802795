import axios from 'axios';
import { authFormDataHeader, authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class ThirdPartyService {
  async addThirdParty(third_party) {
    try {
      return await axios.post(BASE_URL + 'thirdparty/add', third_party, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async editThirdParty(third_party) {
    try {
      return await axios.post(BASE_URL + 'thirdparty/edit', third_party, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async addContact(new_contact) {
    try {
      return await axios.post(
        BASE_URL + 'thirdparty/addcontactperson',
        new_contact,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteContactPerson(thirdPartyId, contactPersonId) {
    try {
      return await axios.delete(
        BASE_URL +
          'thirdparty/deletecontactperson?thirdPartyId=' +
          thirdPartyId +
          '&contactPersonId=' +
          contactPersonId,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getThirdParties() {
    try {
      return await axios.get(BASE_URL + 'thirdparty/thirdparties', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getThirdPartiesAccount() {
    try {
      return await axios.get(BASE_URL + 'account/getAll', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getCustomers() {
    try {
      return await axios.get(BASE_URL + 'thirdparty/customers', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getTrustees() {
    try {
      return await axios.get(BASE_URL + 'thirdparty/trustees', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getSecondaryThirdParties() {
    try {
      return await axios.get(BASE_URL + 'thirdparty/secondaryThirdParties', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getSafeBoxes() {
    try {
      return await axios.get(BASE_URL + 'thirdparty/safeBoxes', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getThirdPartiesByType(type) {
    try {
      return await axios.get(BASE_URL + 'thirdparty/' + type, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getContactPersons(id) {
    try {
      return await axios.get(
        BASE_URL + 'thirdparty/contactpersons?thirdPartyId=' + id,
        { headers: authHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getBankInfo(id) {
    console.log('getting bank info', id);
    try {
      let response = await axios.get(
        BASE_URL + 'thirdparty/getBankInfo?thirdPartyId=' + id,
        { headers: authHeader() }
      );
      console.log('bank info', response);
      return response;
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getThirdPartyAccounts(id) {
    try {
      return await axios.get(
        BASE_URL + 'thirdparty/thirdparty-accounts?thirdPartyId=' + id,
        { headers: authHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteThirdParty(id) {
    try {
      return await axios.delete(BASE_URL + 'thirdparty/delete', {
        headers: authHeader(),
        params: {
          thirdPartyId: id,
        },
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async attachFiles(id, file) {
    try {
      return await axios.post(
        BASE_URL + 'thirdparty/uploadAttachment',
        { thirdPartyId: id, attachment: file },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getThirdPartyFiles(Id) {
    try {
      return await axios.get(BASE_URL + 'thirdparty/attachments?Id=' + Id, {
        responseType: 'json', // Specify the response type as 'json'
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new ThirdPartyService();
