<template>
  <v-card>
    <v-form ref="edit_task_form">
      <v-card-text>
        <v-row dense>
          <v-col v-if="isEditingTask" md="2">
            <v-text-field v-model="id" label="ID" readonly variant="outlined">
            </v-text-field>
          </v-col>
          <v-col :md="isEditingTask ? 4 : 6">
            <v-text-field
              v-model="title"
              :rules="requiredRule()"
              label="Title"
              color="primaryColor1"
            >
            </v-text-field>
          </v-col>
          <v-col md="3">
            <v-select
              v-model="selectedTaskGroup"
              :items="taskGroups"
              item-title="title"
              item-value="id"
              clearable
              label="Task Group"
              color="primaryColor1"
              :rules="requiredRule()"
            >
            </v-select>
          </v-col>
          <v-col md="3">
            <v-autocomplete
              v-model="assignee"
              :items="this.users"
              item-title="firstName"
              item-value="id"
              label="Assign to"
              color="primaryColor1"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col md="5">
            <v-select
              v-model="selectedImportanceLevel"
              :items="importanceLevels"
              item-title="text"
              item-value="value"
              label="Importance"
              color="primaryColor1"
            >
            </v-select>
          </v-col>
          <v-col md="7">
            <v-text-field
              v-model="description"
              clearable
              label="Note"
              color="primaryColor1"
            >
            </v-text-field>
          </v-col>
          <v-col md="3">
            <v-select
              v-model="selectedReminderType"
              :items="reminderTypes"
              item-title="text"
              item-value="value"
              clearable
              label="Reminder Type"
              color="primaryColor1"
            >
            </v-select>
          </v-col>
          <v-col md="3">
            <v-select
              :disabled="selectedReminderType === null"
              v-model="selectedReminderPeriod"
              :items="
                selectedReminderType === 'SCHEDULED'
                  ? scheduledReminderOptions
                  : continuousReminderOptions
              "
              item-title="text"
              item-value="value"
              clearable
              label="Reminder"
              color="primaryColor1"
            >
            </v-select>
          </v-col>
          <v-col md="3">
            <VueDatePicker
              :readonly="selectedReminderType === null"
              placeholder="Alarm Date"
              v-model="alarmDate"
              format="yyyy-MM-dd"
              auto-apply
              :enable-time-picker="false"
              teleport-center
              class="custom-datepicker"
            ></VueDatePicker>
          </v-col>
          <v-col md="3">
            <v-text-field
              :readonly="selectedReminderType === null"
              variant="outlined"
              type="text"
              v-model="alarmTime"
              v-maska="options"
              placeholder="Alarm Time"
              maxlength="5"
              prepend-inner-icon="mdi-clock-outline"
              class="custom-text-field"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="this.disableButton"
        :variant="isEditingTask ? 'outlined' : 'flat'"
        :rules="requiredRule()"
        class="mr-4"
        color="button_color"
        @click="addOrUpdateTask"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="closeEntireModal()"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { unifiedResponseHandler } from '@/utils/helpers.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import { requiredRule } from '@/utils/rules.js';
import userService from '@/services/user.service.js';
import taskService from '@/services/task.service.js';
import TaskGroupService from '@/services/taskGroup.service.js';

export default {
  name: 'createTaskModal',
  components: { Success_Modal, Error_Modal },
  props: ['taskToEdit'],

  data() {
    return {
      options: {
        mask: '##:##',
        eager: true,
      },
      message: '',
      taskGroups: [],
      id: null,
      isEditingTask: false,
      task: null,
      success: false,
      error: false,
      title: null,
      description: null,
      done: false,
      selectedImportanceLevel: 'Medium',
      disableButton: false,
      selectedTaskGroup: null,
      importanceLevels: [
        { text: 'Low', value: 'LOW' },
        { text: 'Medium', value: 'MEDIUM' },
        { text: 'High', value: 'HIGH' },
        { text: 'Critical', value: 'CRITICAL' },
      ],
      selectedReminderType: null,
      reminderTypes: [
        { text: 'Continuous', value: 'CONTINUOUS' },
        { text: 'Scheduled', value: 'SCHEDULED' },
      ],
      continuousReminderOptions: [
        { text: 'Every Day', value: 'DAY' },
        { text: 'Every Week', value: 'WEEK' },
        { text: 'Every Month', value: 'MONTH' },
      ],
      scheduledReminderOptions: [
        { text: '30 Minutes', value: 'HALF_HOUR' },
        { text: '1 Hour', value: 'HOUR' },
        { text: '2 Hours', value: 'TWO_HOURS' },
        { text: '1 Day', value: 'DAY' },
      ],
      selectedReminderPeriod: null,
      users: [],
      alarmTime: null,
      alarmDate: null,
      assignee: null,
    };
  },

  computed: {
    buttonText() {
      return this.isEditingTask ? 'Update Task' : 'Create Task';
    },
  },

  methods: {
    requiredRule() {
      return requiredRule;
    },
    closeEntireModal() {
      this.$emit('closeTaskModal');
    },
    async addOrUpdateTask() {
      if (this.hasMissingFields()) {
        this.displayError('Please fill in all required fields');
        return;
      }
      if (this.alarmTime !== null && this.alarmTime.length !== 5) {
        this.displayError('Please fill in all required fields');
        return;
      }
      this.isEditingTask ? await this.editTask() : await this.addTask();
    },

    hasMissingFields() {
      return (
        !this.title ||
        !this.selectedTaskGroup ||
        (this.selectedReminderType !== null &&
          (this.selectedReminderPeriod === null ||
            this.alarmDate === null ||
            this.alarmTime === null))
      );
    },
    formatDateString(isoString) {
      const date = new Date(isoString);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    },

    formatTimeWithZeroPadding({ hours, minutes }) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    },

    displayError(message) {
      this.message = message;
      this.error = true;
    },

    formatDateTime(date, time) {
      return `${this.formatDateString(date)} ${this.formatTimeWithZeroPadding(time)}`;
    },
    buildTaskForm() {
      return {
        id: this.id,
        title: this.title,
        description: this.description,
        taskGroupId: this.selectedTaskGroup,
        done: this.done,
        importanceLevel: this.selectedImportanceLevel.toUpperCase(),
        assigneeId: this.assignee,
        reminderType: this.selectedReminderType,
        recurrence:
          this.selectedReminderType === 'CONTINUOUS'
            ? this.selectedReminderPeriod
            : null,
        earlyReminder:
          this.selectedReminderType === 'SCHEDULED'
            ? this.selectedReminderPeriod
            : null,
        alarmDate: this.selectedReminderType
          ? this.formatDateTime(this.alarmDate, {
              hours: this.alarmTime.slice(0, 2),
              minutes: this.alarmTime.slice(3, 5),
              seconds: 0,
            })
          : null,
      };
    },

    async editTask() {
      if (await this.$refs.edit_task_form.validate()) {
        const form = this.buildTaskForm();
        this.disableButton = true;
        const response = unifiedResponseHandler(
          await taskService.editTask(form)
        );
        response.success
          ? this.handleSuccess('Task has been edited successfully')
          : this.displayError(response.message);
        this.disableButton = false;
      }
    },

    async addTask() {
      if (await this.$refs.edit_task_form.validate()) {
        const form = this.buildTaskForm();
        this.disableButton = true;
        const response = unifiedResponseHandler(
          await taskService.addTask(form)
        );
        if (response.success) {
          this.handleSuccess('Task has been added successfully', response.data);
        } else {
          this.displayError(response.message);
        }
        this.disableButton = false;
      }
    },

    async getUsers() {
      const response = unifiedResponseHandler(await userService.getUsers());
      this.users = response.success ? response.data : [];
    },
    async getTaskGroups() {
      const response = unifiedResponseHandler(
        await TaskGroupService.getAllTaskGroups()
      );
      this.taskGroups = response.success ? response.data : [];
    },

    setTaskToEditVariables(newTask) {
      if (!newTask) return;
      this.isEditingTask = true;
      this.task = newTask;
      this.id = newTask.id;
      this.title = newTask.title;
      this.selectedImportanceLevel = newTask.importanceLevel;
      this.selectedTaskGroup = newTask.taskGroup.id;
      this.done = newTask.done;
      this.description = newTask.description;
      this.selectedReminderType = newTask.reminderType;
      this.alarmDate = newTask.alarmDate
        ? newTask.alarmDate.slice(0, 10)
        : null;
      this.alarmTime = newTask.alarmDate
        ? newTask.alarmDate.slice(11, 16)
        : null;
      this.selectedReminderPeriod =
        newTask.reminderType === 'SCHEDULED'
          ? newTask.earlyReminder
          : newTask.recurrence;
      this.assignee = newTask.assignee.id;
    },

    handleSuccess(message, data = null) {
      this.message = message;
      this.success = true;
      if (data) this.setTaskToEditVariables(data);
      this.$emit('successfulTaskOperation');
    },
  },

  async mounted() {
    this.setTaskToEditVariables(this.taskToEdit);
    await this.getTaskGroups();
    await this.getUsers();
  },
  watch: {
    selectedReminderType(newVal, oldVal) {
      if (oldVal !== null) this.selectedReminderPeriod = null;
      if (newVal === null) {
        this.alarmDate = null;
        this.alarmTime = null;
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-text-field .v-icon) {
  font-size: 20px;
  color: #5e5e5e;
}

.custom-datepicker :deep(input) {
  height: 56px;
}

.custom-text-field :deep(div) {
  font-size: 15px;
  border-color: #7e7e7e;
  transition: border-color 2s ease;
}
</style>
