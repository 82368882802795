<script>
import { ACCEPTED_FILE_TYPES } from '@/config/constants';
import { mapGetters } from 'vuex';
import { convertByteCode, unifiedResponseHandler } from '@/utils/helpers';
import third_partyService from '@/services/third_party.service';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import { multipleFileRequiredRule, sizeRule } from '@/utils/rules';

export default {
  name: 'ThirdPartyFiles',
  components: { Error_Modal, Success_Modal },

  data() {
    return {
      selectedFiles: [],
      files: [],
      message: null,
      error: false,
      success: false,
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      fileInputRules: [this.multipleFileRequiredRule(), this.sizeRule()],
    };
  },
  methods: {
    sizeRule() {
      return sizeRule;
    },
    multipleFileRequiredRule() {
      return multipleFileRequiredRule;
    },
    async uploadFiles() {
      let { valid } = await this.$refs.file_upload_form.validate();
      console.log('validate', valid);
      if (valid) {
        if (!this.chosen_third_party) {
          this.message = 'the third party is not loaded';
          this.error = true;
          return;
        }

        let response = unifiedResponseHandler(
          await third_partyService.attachFiles(
            this.chosen_third_party.id,
            this.selectedFiles[0]
          )
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          this.selectedFiles.length = 0;
          await this.getFiles(this.chosen_third_party.id);
        } else {
          this.message = 'File size should be less than 5MB';
          this.error = true;
        }
      }
    },

    remove(file) {
      this.selectedFiles = this.selectedFiles.filter((item) => item !== file);
    },

    async downLoad(file) {
      this.success = await convertByteCode(file);
    },

    async getFiles(id) {
      let response = unifiedResponseHandler(
        await third_partyService.getThirdPartyFiles(id)
      );
      if (response.success) {
        this.files = response.data;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },
  computed: {
    ...mapGetters(['chosen_third_party', 'is_editing_third_party']),
  },
  async mounted() {
    if (this.is_editing_third_party) {
      await this.getFiles(this.chosen_third_party.id);
    }
  },
};
</script>

<template>
  <v-row class="mt-4">
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <v-form ref="file_upload_form">
            <v-file-input
              v-model="selectedFiles"
              label="Select File"
              multiple
              show-size
              :accept="acceptedFileTypes"
              :rules="fileInputRules"
            >
            </v-file-input>
          </v-form>
        </v-col>
        <v-col cols="auto">
          <v-btn color="button_color" @click="uploadFiles">Upload</v-btn>
          <span>
            {{ message }}
          </span>
        </v-col>
        <v-col cols="auto">
          <p v-if="selectedFiles.length === 0" class="mt-4">
            please select a file
          </p>
        </v-col>
      </v-row>
      <v-list>
        <v-list-item v-for="(file, index) in selectedFiles" :key="index">
          <v-icon @click="remove(file)">mdi-delete</v-icon>
          {{ file.name }}
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-toolbar-title> all files:</v-toolbar-title>
      <v-list class="mt-4">
        <v-list-item v-for="(file, index) in files" :key="index">
          <v-icon @click="downLoad(file)">mdi-download</v-icon>
          {{ file.name }}
        </v-list-item>
      </v-list>
    </v-container>
  </v-row>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<style scoped></style>
