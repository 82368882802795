<template>
  <div>
    <v-container fluid style="background-color: white">
      <h3 class="font-weight-medium">Manual Transaction</h3>
      <v-form ref="manual_transaction_add_form">
        <v-row>
          <v-col cols="12" md="4" sm="4" xs="4">
            <v-autocomplete
              label="Manual Transaction Types"
              v-model="manualTransactionType"
              :items="manualTypes"
              item-title="name"
              item-value="id"
              filled
              return-object
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" sm="4" xs="4">
            <v-text-field
              label="Enter Event ID that needs to be corrected"
              v-model="eventId"
              :rules="requiredRule"
            >
              <template v-slot:append-inner v-if="eventId">
                <v-btn
                  color="button_color"
                  @click="filterTableByEventId"
                  icon="mdi-check"
                >
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4" xs="4">
            <label for="date-picker" style="color: black; margin-top: -8px"
              >Date</label
            >
            <VueDatePicker
              auto-apply="true"
              id="date-picker"
              v-model="date"
              :show-timepicker="false"
            ></VueDatePicker>
          </v-col>
        </v-row>

        <EasyDataTable
          v-if="displayTable"
          :headers="headers"
          :items="transactionsDetails"
          :search-field="searchField"
          :search-value="searchValue"
          body-text-direction="right"
          header-text-direction="right"
          class="custom-data-table"
          border-cell
        >
          <template #header-transactionStatus="header">
            <div class="filter-column">
              <v-icon @click.stop="showStatusFilter = !showStatusFilter">
                mdi-filter
              </v-icon>
              {{ header.text }}
              <div class="filter-menu" v-if="showStatusFilter">
                <v-select
                  class="filter-input"
                  v-model="statusCriteria"
                  name="status"
                  :items="['ALL', 'INITIATED', 'COMPLETED']"
                >
                </v-select>
              </div>
            </div>
          </template>
        </EasyDataTable>

        <v-row class="mt-2" id="trustee_commission">
          <v-col cols="12" md="4" sm="4" xs="4">
            <v-tab
              @click="tAccountListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>

            <v-text-field
              label="Trustee Account"
              v-model="tAccountName"
              @keyup.backspace="clearTAccount"
              @keydown="handleKeyDown"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4" xs="4">
            <v-text-field
              class="mt-6"
              v-maska="moneyOption"
              label="Trustee Debit Amt (will decrease balance)"
              v-model="trusteeDebitAmount"
            ></v-text-field>

            <v-text-field
              v-maska="moneyOption"
              label="Trustee Com Debit Amt (will decrease balance)"
              v-model="trusteeCommissionDebitAmount"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4" xs="4">
            <v-text-field
              class="mt-6"
              v-maska="moneyOption"
              label="Trustee Credit Amt (will increase balance)"
              v-model="trusteeCreditAmount"
            ></v-text-field>

            <v-text-field
              v-maska="moneyOption"
              label="Trustee Com Credit Amt (will increase balance)"
              v-model="trusteeCommissionCreditAmount"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row id="customer_commission">
          <v-col cols="12" md="4" sm="4" xs="4">
            <v-tab
              @click="cAccountListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>

            <v-text-field
              label="Customer Account"
              v-model="cAccountName"
              @keyup.backspace="clearCAccount"
              @keydown="handleKeyDown"
            ></v-text-field>
          </v-col>
          <v-col class="mt-6" cols="12" md="4" sm="4" xs="4">
            <v-text-field
              v-maska="moneyOption2"
              label="Customer Debit Amt (will decrease balance)"
              v-model="customerDebitAmount"
            ></v-text-field>

            <v-text-field
              v-maska="moneyOption2"
              label="Customer Com Debit Amt (will decrease balance)"
              v-model="customerCommissionDebitAmount"
            ></v-text-field>
          </v-col>
          <v-col class="mt-6" cols="12" md="4" sm="4" xs="4">
            <v-text-field
              v-maska="moneyOption2"
              label="Customer Credit Amt (will increase balance)"
              v-model="customerCreditAmount"
            ></v-text-field>

            <v-text-field
              v-maska="moneyOption2"
              label="Customer Com Credit Amt (will increase balance)"
              v-model="customerCommissionCreditAmount"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          class="mt-4"
          label="Note"
          v-model="description"
        ></v-text-field>

        <v-row class="ma-4" justify="end">
          <v-btn
            class="mr-1"
            color="button_color"
            @click="createManualTransfer"
          >
            Add Manual Transaction
          </v-btn>
        </v-row>
      </v-form>

      <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
      ></Success_Modal>

      <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
      ></Error_Modal>

      <v-dialog v-model="tAccountListModal" width="auto">
        <list_of_accounts_modal_v01
          third-party-type="TRUSTEE"
        ></list_of_accounts_modal_v01>
      </v-dialog>
      <v-dialog v-model="cAccountListModal" width="auto">
        <list_of_accounts_modal_v01
          third-party-type="CUSTOMER"
        ></list_of_accounts_modal_v01>
      </v-dialog>
      <v-dialog v-model="sAccountListModal" width="auto">
        <list_of_accounts_modal_v01
          third-party-type="SAFE_BOX"
        ></list_of_accounts_modal_v01>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import {
  extractNumber,
  formatNumber,
  handleKeyDown,
  unifiedResponseHandler,
  setPage,
} from '@/utils/helpers';
import { requiredRule } from '@/utils/rules';
import list_of_accounts_modal_v01 from '../Modals/List_Of_Accounts_Modal_V01.vue';
import emitter from '@/plugins/mitt';
import { MoneyOption } from '@/utils/maska-options.js';
import transactionService from '@/services/transaction.service';
import { PAGE_SIZE } from '@/config/constants';
import manual_transferService from '@/services/manual_transfer.service';
import { getEventTypeDisplay } from '@/utils/NewFunctions/getEventTypeDisplay';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';

export default {
  name: 'manual_transaction_new_v01',

  components: {
    Error_Modal,
    Success_Modal,
    list_of_accounts_modal_v01: list_of_accounts_modal_v01,
  },
  data() {
    return {
      transactions: [],
      headers: [
        { text: 'Status', value: 'transactionStatus' },
        { text: 'Account No', value: 'accountNumber' },
        { text: 'Third Party', value: 'thirdPartyFullName' },
        { text: 'Coffer', value: 'cofferName' },
        { text: 'Debit', value: 'debitAmount' },
        { text: 'Credit', value: 'creditAmount' },
        { text: 'Balance', value: 'balance' },
        { text: 'Ledger', value: 'ledgerCode' },
        { text: 'Type', value: 'eventType' },
        { text: 'Event', value: 'eventId' },
        { text: 'Modified At', value: 'modifiedAt' },
      ],
      formValid: false,
      error: false,
      success: false,
      message: null,
      selectedAccount: null,
      description: null,
      requiredRule: requiredRule,
      date: null,
      manualTransactionType: null,
      manualTypes: [
        { name: 'Money / Internal Transfer - Correction', id: 0 },
        { name: 'Bill Payment - Correction', id: 1 },
        { name: 'No Event Transaction', id: 2 },
      ],
      tAccount: null,
      cAccount: null,
      sAccount: null,
      tAccountListModal: false,
      cAccountListModal: false,
      sAccountListModal: false,
      eventId: '',
      trusteeDebitAmount: null,
      trusteeCommissionDebitAmount: null,
      safeboxDebitAmount: null,
      safeboxCommissionDebitAmount: null,
      customerDebitAmount: null,
      customerCommissionDebitAmount: null,
      trusteeCreditAmount: null,
      trusteeCommissionCreditAmount: null,
      safeboxCreditAmount: null,
      safeboxCommissionCreditAmount: null,
      customerCreditAmount: null,
      customerCommissionCreditAmount: null,
      searchField: '',
      searchValue: '',
      searchTargetOwner: '',
      searchCurrency: '',
      searchTargetAccount: '',
      searchRequestId: '',
      searchAccountingCode: '',
      currentPage: 0,
      pageSize: PAGE_SIZE,
      totalItems: 0,
      statusCriteria: 'ALL',
      showStatusFilter: false,
      showTargetThirdPartyFilter: false,
      showTransactionTargetAccount: false,
      showTransactionAccountingCode: false,
      showTransactionRequestId: false,
      showTransactionCurrency: false,
      showAmountFilter: false,
      QTransactionModal: false,
      minAmount: null,
      maxAmount: null,
      displayTable: false,
    };
  },
  methods: {
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearTAccount() {
      this.tAccount = null;
    },
    clearCAccount() {
      this.cAccount = null;
    },
    clearSAccount() {
      this.sAccount = null;
    },

    async createManualTransfer() {
      let { valid } = await this.$refs.manual_transaction_add_form.validate();
      if (valid) {
        let form = {
          date: this.date,
          trusteeAccountId: this.tAccountId,
          customerAccountId: this.cAccountId,
          safeboxAccountId: this.sAccountId,
          manualTransactionType: this.manualTransactionType.id,
          trusteeDebitAmount: extractNumber(this.trusteeDebitAmount),
          trusteeCreditAmount: extractNumber(this.trusteeCreditAmount),
          trusteeCreditCommissionAmount: extractNumber(
            this.trusteeCommissionCreditAmount
          ),
          trusteeDebitCommissionAmount: extractNumber(
            this.trusteeCommissionDebitAmount
          ),
          safeboxDebitAmount: extractNumber(this.safeboxDebitAmount),
          safeboxCreditAmount: extractNumber(this.safeboxCreditAmount),
          safeboxCreditCommissionAmount: extractNumber(
            this.safeboxCommissionCreditAmount
          ),
          safeboxDebitCommissionAmount: extractNumber(
            this.safeboxCommissionDebitAmount
          ),
          customerCreditAmount: extractNumber(this.customerCreditAmount),
          customerCreditCommissionAmount: extractNumber(
            this.customerCommissionCreditAmount
          ),
          customerDebitAmount: extractNumber(this.customerDebitAmount),
          customerDebitCommissionAmount: extractNumber(
            this.customerCommissionDebitAmount
          ),
          description: this.description,
          eventId: this.eventId,
        };
        console.log('hi,', form);
        let response = unifiedResponseHandler(
          await manual_transferService.createManualTransfer(form)
        );

        this.message = response.message;
        if (response.success) {
          this.success = true;
          setTimeout(() => {
            setPage('manual report');
          }, 1000);
        } else {
          this.error = true;
        }
      } else {
        this.error = true;
        this.message =
          'Please make sure to fill out all the required fields and complete the form before submitting it';
      }
    },
    async filterTableByEventId() {
      try {
        const response = await transactionService.filterTableByEventId(
          this.eventId
        );
        console.log('response.data', response.data);
        if (response.status === 200) {
          this.transactions = response.data;
          this.displayTable = true;
          console.log('transactions', this.transactions);
        } else {
          this.error = true;
          this.message = response.message;
        }
      } catch (error) {
        console.error('Error:', error);
        this.error = true;
        this.message = 'Network error occurred.';
      }
    },
  },
  async mounted() {
    emitter.on('chosenAccount', (value) => {
      if (this.tAccountListModal) {
        this.tAccount = value;
        this.tAccountListModal = false;
      }
      if (this.cAccountListModal) {
        this.cAccount = value;
        this.cAccountListModal = false;
      }
      if (this.sAccountListModal) {
        this.sAccount = value;
        this.sAccountListModal = false;
      }
    });
  },
  computed: {
    tAccountName() {
      if (this.tAccount) {
        return this.tAccount.cofferName;
      } else {
        return null;
      }
    },
    cAccountName() {
      if (this.cAccount) {
        return this.cAccount.cofferName;
      } else {
        return null;
      }
    },
    sAccountName() {
      if (this.sAccount) {
        return this.sAccount.cofferName;
      } else {
        return null;
      }
    },
    tAccountId() {
      if (this.tAccount) {
        return this.tAccount.id;
      } else {
        return null;
      }
    },
    cAccountId() {
      if (this.cAccount) {
        return this.cAccount.id;
      } else {
        return null;
      }
    },
    sAccountId() {
      if (this.sAccount) {
        return this.sAccount.id;
      } else {
        return null;
      }
    },
    moneyOption() {
      if (this.tAccount) {
        return MoneyOption(this.tAccount.currency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    moneyOption2() {
      if (this.cAccount) {
        return MoneyOption(this.cAccount.currency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    moneyOption3() {
      if (this.sAccount) {
        return MoneyOption(this.sAccount.currency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    transactionsDetails() {
      if (!this.displayTable) {
        return [];
      }
      return this.transactions.map((transaction) => {
        let transactionTypeDisplay;
        transactionTypeDisplay = getEventTypeDisplay(transaction);
        return {
          id: transaction.id,
          transactionStatus: transaction.transactionStatus,
          accountId: transaction.account.id,
          accountNumber: transaction.thirdParty.accountNumber,
          thirdPartyFullName: transaction.thirdParty.fullName,
          cofferName: transaction.account.cofferName,
          ledgerCode: transaction.ledgerCode,
          creditAmount:
            transaction.accountDirection === 'CREDIT'
              ? transaction.currency + ' ' + formatNumber(transaction.amount)
              : '',
          debitAmount:
            transaction.accountDirection === 'DEBIT'
              ? transaction.currency + ' ' + formatNumber(transaction.amount)
              : '',
          amount: transaction.currency + ' ' + formatNumber(transaction.amount),
          direction: transaction.ledgerDirection,
          balance:
            transaction.currency +
            ' ' +
            formatNumber(transaction.ledgerBalance),
          eventId: transaction.eventId,
          createdAt: transaction.createdAt,
          modifiedAt: new Date(transaction.modifiedAt)
            .toISOString()
            .split('T')[0],
          transactionType: transaction.transactionType,
          eventType: transactionTypeDisplay,
        };
      });
    },
  },
  watch: {
    eventId: {
      handler: function () {
        this.displayTable = false;
      },
      deep: true,
    },
  },
};
</script>
