<template>
  <div>
    <v-container v-if="!broken_page" fluid style="background-color: white">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field label="Search" v-model="searchValue"></v-text-field>
        </v-col>
      </v-row>
      <download-excel :data="generalLedgers">
        <v-icon>mdi-export</v-icon>
      </download-excel>
      <EasyDataTable
        class="datatable-container"
        :headers="headers"
        :items="generalLedgers"
        :search-field="searchField"
        :search-value="searchValue"
        alternating
        border-cell
      >
        <template #item-choose="item">
          <v-icon @click="chooseGeneralLedger(item)">mdi-check-circle</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>

    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>

    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
  </div>
</template>
<script>
import { formatNumber, unifiedResponseHandler } from '@/utils/helpers';
import general_ledgerService from '@/services/general_ledger.service';
import emitter from '@/plugins/mitt';
import Broken_Page_V01 from '../Broken_Page_V01.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import getAccountGroupDisplay from '@/utils/NewFunctions/getAccountGroupDisplay.js';

export default {
  name: 'general_ledger_list_modal_v01',
  components: {
    Success_Modal,
    Error_Modal,
    Broken_Page_V01,
  },
  props: {
    currencyCode: {
      type: String,
      required: false,
      default: null,
    },
    currencyHavingLedgers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Label', value: 'label' },
        { text: 'Parent Account', value: 'parentAccount' },
        { text: 'Account Group', value: 'accountGroup' },
        { text: 'Balance', value: 'balance' },
        { text: 'Choose', value: 'choose' },
      ],
      searchField: '',
      searchValue: '',
      message: null,
      broken_page: false,
      success: false,
      error: false,
      generalLedgers: [],
    };
  },

  methods: {
    async getGeneralLedgers() {
      const response = unifiedResponseHandler(
        await general_ledgerService.getGeneralLedgers()
      );
      if (response.success) {
        this.generalLedgers = response.data
          .sort((a, b) => a.code.localeCompare(b.code))
          .filter(this.filterGeneralLedgers)
          .map(this.transformGeneralLedger);
      } else {
        this.broken_page = true;
      }
    },

    filterGeneralLedgers(generalLedger) {
      if (this.currencyCode) {
        return (
          generalLedger.code.length === 9 &&
          generalLedger.code.endsWith(this.currencyCode)
        );
      }
      if (this.currencyHavingLedgers) {
        return generalLedger.code.length === 9;
      }
      return true;
    },

    transformGeneralLedger(generalLedger) {
      return {
        ...generalLedger,
        parentAccount: generalLedger.parentLedger?.label || 'NULL',
        balance:
          generalLedger.balance === null
            ? 'NULL'
            : formatNumber(generalLedger.balance),
        accountGroup: getAccountGroupDisplay(generalLedger.accountGroup),
      };
    },
    chooseGeneralLedger(new_general_ledger) {
      emitter.emit('new_general_ledger', new_general_ledger);
    },
  },

  async mounted() {
    await this.getGeneralLedgers();
  },
  computed: {},
};
</script>
