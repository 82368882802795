<template>
  <v-card :width="dialogWidth" :height="dialogHeight">
    <iframe
      v-if="isPdf"
      :src="fileUrl"
      :width="dialogWidth"
      :height="dialogHeight"
      style="border: none"
    ></iframe>
    <img
      v-else
      :src="fileUrl"
      :width="dialogWidth"
      :height="dialogHeight"
      style="object-fit: contain"
      alt=""
    />
  </v-card>
</template>

<script>
export default {
  name: 'attachmentPreviewModal',
  props: ['attachment'],

  data() {
    return {
      fileUrl: null,
      dialogWidth: 800,
      dialogHeight: 600,
      isPdf: false,
    };
  },
  methods: {
    emitDimensions() {
      this.$emit('dimensions', {
        width: this.dialogWidth,
        height: this.dialogHeight,
      });
    },
    viewAttachment() {
      if (!this.attachment) return;
      this.fileUrl = URL.createObjectURL(this.attachment);
      this.isPdf = this.attachment.type === 'application/pdf';
      if (this.isPdf) {
        this.dialogWidth = 1600;
        this.dialogHeight = 900;
        this.emitDimensions();
      } else {
        const img = new Image();
        img.src = this.fileUrl;

        img.onload = () => {
          const maxWidth = window.innerWidth * 0.8;
          const maxHeight = window.innerHeight * 0.8;
          this.dialogWidth = Math.min(img.width, maxWidth);
          this.dialogHeight = Math.min(img.height, maxHeight);
          this.emitDimensions();
        };
        img.onerror = () => {
          console.error('Failed to load image');
        };
      }
    },
  },
  mounted() {
    this.viewAttachment();
  },
};
</script>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
}
</style>
