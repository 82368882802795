<template>
  <div
    style="
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 2rem;
    "
  >
    <h2>Broken Page!!!</h2>
    <p>Sorry, the page you are looking for is not found.</p>
    <v-img
      :width="600"
      aspect-ratio="16/9"
      cover
      src="../assets/img/broken-page/404-Error-g.svg"
    ></v-img>
  </div>
</template>
<script>
export default {
  name: 'broken_page_v01',
};
</script>
