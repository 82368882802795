import axios from 'axios';
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class attachmentService {
  async addOrUpdateAttachment(request) {
    try {
      return await axios.post(BASE_URL + 'attachment', request, {
        headers: {
          ...authHeader(),
        },
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getAttachment(targetID, target) {
    try {
      return await axios.get(
        BASE_URL + 'attachment?id=' + targetID + '&target=' + target,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}
export default new attachmentService();
