<template>
  <v-sheet max-width="800" min-width="600" style="padding: 20px">
    <div class="border-1">
      <h4 class="border-h4">Crypto Deal Summary</h4>
      <div class="grid-card-2" ref="pdfContent">
        <div
          class="summary-row"
          v-for="(value, label) in summaryData"
          :key="label"
        >
          <span style="flex: 1">{{ label }}:</span>
          <span style="flex: 1; text-align: left">{{ value }}</span>
        </div>
      </div>
      <v-row justify="end">
        <v-btn class="mr-4" color="button_color" @click="generatePDF">
          PDF
        </v-btn>
      </v-row>
    </div>
  </v-sheet>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import html2pdf from 'html2pdf.js';
import getStatusDisplay from '@/utils/NewFunctions/getStatusDisplay.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import { formatNumber } from '@/utils/helpers.js';

export default {
  name: 'request_modal_v01',
  components: {
    Error_Modal,
  },
  computed: {
    ...mapGetters(['chosen_crypto_deal']),
    summaryData() {
      if (!this.chosen_crypto_deal) return {}; // Handle undefined chosen_crypto_deal

      return {
        Event: this.chosen_crypto_deal.id,
        Status: this.getStatusDisplay(this.chosen_crypto_deal.status),
        'Reference No': this.chosen_crypto_deal.referenceNo,
        'Create Date': this.chosen_crypto_deal.createDate,
        'Third Party': this.chosen_crypto_deal.thirdParty?.fullName || ' ',
        'Exchange Rate': this.chosen_crypto_deal.exchangeRate,
        'Bought Amount': `${this.formatNumber(this.chosen_crypto_deal.receiveAmount)} ${this.chosen_crypto_deal.receiveCurrency.symbol}`,
        'Sold Amount': `${this.formatNumber(this.chosen_crypto_deal.sendAmount)} ${this.chosen_crypto_deal.sendCurrency.symbol}`,
        'Receive Our Acc':
          this.chosen_crypto_deal.ellingtonReceiveAccount?.cofferName || '',
        'Receive Third Party Acc':
          this.chosen_crypto_deal.counterPartyPayerAccount?.cofferName || '',
        'Send Our Acc':
          this.chosen_crypto_deal.ellingtonSendAccount?.cofferName || '',
        'Send Third Party Acc':
          this.chosen_crypto_deal.counterPartyPayeeAccount?.cofferName || '',
      };
    },
  },
  methods: {
    formatNumber,
    getStatusDisplay,
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: 'cryptoSummary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
      html2pdf(element, options);
    },
  },
};
</script>
