import axios from 'axios';
import { authHeader, authFormDataHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class moneyTransferRequestService {
  async createMoneyTransferRequest(transfer_request) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/create',
        transfer_request,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async doReconciliation(form) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/doReconciliation',
        form,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getAll(page, size) {
    try {
      return await axios.get(BASE_URL + 'transferrequest/getAll', {
        params: {
          page: page,
          size: size,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getAllIncomplete(page, size) {
    try {
      return await axios.get(BASE_URL + 'transferrequest/getAllIncomplete', {
        params: {
          page: page,
          size: size,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getById(id) {
    try {
      return await axios.get(BASE_URL + 'transferrequest/get', {
        headers: authHeader(),
        params: { requestId: id },
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteById(id) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/delete',
        { requestId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async changeRequestStatusToWaitingForApprove(id) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/status/requestForApprove',

        { requestId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async changeRequestStatusToApproved(id) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/status/approve',

        { requestId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async changeRequestStatusToCanceled(id) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/status/cancel',

        { requestId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async generateTransaction(id) {
    console.log('request id, requesting transactions', id);
    console.log(BASE_URL + 'transferrequest/status/transactionrequest');
    try {
      let response = await axios.post(
        BASE_URL + 'transferrequest/status/transactionrequest',

        { requestId: id },
        { headers: authFormDataHeader() }
      );
      console.log('the response is here', response);
      return response;
    } catch (error) {
      console.log('the exception is here', error);
      return { status: error.response.status, data: error.response.data };
    }
  }

  async trusteeConfirmed(id) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/status/trusteeConfirmed',

        { requestId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async customerConfirmed(id) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/status/complete',

        { requestId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async requestForAccountsUpdate(id) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/status/accountsUpdate',

        { requestId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async createPastMoneyTransferRequest(transfer_request) {
    try {
      return await axios.post(
        BASE_URL + 'transferrequest/pastMoneyTransfer',
        transfer_request,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async searchForMoneyTransfers(page, size, searchField, numericalSearchField) {
    try {
      return await axios.get(BASE_URL + 'transferrequest/search', {
        params: {
          page: page,
          size: size,
          searchField: searchField,
          numericalSearchField: numericalSearchField,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new moneyTransferRequestService();
