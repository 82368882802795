import axios from 'axios';
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class taskService {
  async addTask(task) {
    try {
      return await axios.post(BASE_URL + 'task', task, {
        headers: authHeader(),
      });
    } catch (error) {
      console.log(error);
      return { status: error.response.status, data: error.response.data };
    }
  }

  async editTask(task) {
    try {
      return await axios.post(BASE_URL + 'task/edit', task, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteTask(taskId) {
    try {
      return await axios.delete(BASE_URL + 'task?id=' + taskId, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getAllTasks(createdByMe, assignedToMe) {
    try {
      return await axios.get(
        BASE_URL +
          'task/getAll?createdByMe=' +
          createdByMe +
          '&assignedToMe=' +
          assignedToMe,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async updateTaskStatus(taskId, newStatus) {
    try {
      return await axios.post(
        BASE_URL + 'task/status?id=' + taskId + '&status=' + newStatus,
        {},
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new taskService();
