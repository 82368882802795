<template>
  <div>
    <v-container style="background-color: white" v-if="!broken_page" fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field label="Search" v-model="searchValue"></v-text-field>
        </v-col>
      </v-row>

      <EasyDataTable
        :headers="headers"
        :items="users"
        :search-field="searchField"
        :search-value="searchValue"
        alternating
        border-cell
        class="datatable-container"
      >
        <template #item-choose="item">
          <v-icon @click="choose(item)">mdi-check-circle</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <v-dialog v-model="success">
      <div class="success-dialog">
        <div class="success-left">
          <div class="green-circle">
            <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M50 100 L80 130 L150 50"
                stroke="white"
                stroke-width="25"
                fill="transparent"
                class="success-tick"
              />
            </svg>
          </div>
        </div>
        <div class="success-right">
          <span @click="success = false" style="text-align: right">✖</span>
          <h4>Success</h4>
          <p class="success-message">{{ message }}</p>
          <button class="success-close-button" @click="success = false">
            Close
          </button>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="error">
      <div class="error-dialog">
        <div class="error-left">
          <div class="red-icon"></div>
        </div>
        <div class="error-right">
          <span @click="error = false" style="text-align: right">✖</span>
          <h4>Error</h4>
          <p class="error-message">{{ message }}</p>
          <button class="close-button" @click="error = false">Close</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import individualService from '@/services/individual.service';
import { unifiedResponseHandler } from '@/utils/helpers';
import Broken_Page_V01 from '../Broken_Page_V01.vue';

import emitter from '@/plugins/mitt';

export default {
  name: 'individual_list_modal_v01',
  components: {
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        { text: 'National Id', value: 'nationalId' },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Choose', value: 'choose' },
      ],
      users: [],
      searchField: '',
      searchValue: '',

      message: null,
      broken_page: false,
      success: false,
      error: false,
    };
  },
  methods: {
    choose(new_individual) {
      emitter.emit('new_individual_id', new_individual);
      this.$emit('individualChosen', new_individual);
    },

    async getIndividualsList() {
      let response = unifiedResponseHandler(
        await individualService.getIndividuals()
      );

      if (response.success) {
        this.users = response.data;
      } else {
        this.broken_page = true;
      }
    },
  },
  async mounted() {
    await this.getIndividualsList();
  },
};
</script>
