<template>
  <v-container
    fluid
    style="
      background-color: white;
      min-height: 300px;
      width: 900px;
      border-radius: 5px;
    "
  >
    <v-form ref="edit_reminder_form" class="mt-4">
      <v-row dense>
        <v-col v-if="isEditingReminder" md="1">
          <v-text-field v-model="id" label="ID" readonly variant="outlined">
          </v-text-field>
        </v-col>
        <v-col :md="isEditingReminder ? '5' : '6'">
          <v-text-field
            v-model="title"
            :rules="requiredRule()"
            label="Title"
            color="primaryColor1"
          >
          </v-text-field>
        </v-col>
        <v-col md="6">
          <v-text-field
            v-model="note"
            clearable
            label="Note"
            color="primaryColor1"
          >
          </v-text-field>
        </v-col>
        <v-col md="4">
          <v-select
            v-model="selectedRecurrence"
            :items="this.recurrenceOptions"
            item-title="text"
            item-value="value"
            label="Recurrence"
            clearable
            prepend-inner-icon="mdi-repeat"
            color="primaryColor1"
          >
          </v-select>
        </v-col>

        <v-col md="4">
          <VueDatePicker
            v-model="alarmDate"
            format="yyyy-MM-dd"
            :auto-apply="true"
            placeholder="Alarm Date"
            :enable-time-picker="false"
            class="custom-datepicker"
          ></VueDatePicker>
        </v-col>
        <v-col md="4">
          <v-text-field
            variant="outlined"
            type="text"
            v-model="alarmTime"
            v-maska="options"
            placeholder="Alarm Time"
            maxlength="5"
            prepend-inner-icon="mdi-clock-outline"
            class="custom-text-field"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="end">
        <v-col cols="auto">
          <v-btn
            :variant="isEditingReminder ? 'outlined' : 'flat'"
            :rules="requiredRule()"
            class="mr-4"
            color="button_color"
            @click="addOrUpdateReminder"
          >
            {{ buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="closeEntireModal()"
    ></Success_Modal>

    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
  </v-container>
</template>

<script>
import { unifiedResponseHandler } from '@/utils/helpers.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import { requiredRule } from '@/utils/rules.js';
import reminderService from '@/services/reminder.service.js';

export default {
  name: 'createReminderModal',
  components: { Success_Modal, Error_Modal },
  props: ['reminderToEdit'],

  data() {
    return {
      options: {
        mask: '##:##',
        eager: true,
      },
      message: '',
      reminder: null,
      isEditingReminder: false,
      success: false,
      error: false,
      id: null,
      title: null,
      note: null,
      alarmDate: null,
      alarmTime: null,
      reminderType: 'SCHEDULED',
      done: true,
      owner: null,
      recurrenceOptions: [
        { text: 'Every Day', value: 'DAY' },
        { text: 'Every Week', value: 'WEEK' },
        { text: 'Every Month', value: 'MONTH' },
        { text: 'Every Year', value: 'YEAR' },
      ],
      selectedRecurrence: null,
    };
  },

  computed: {
    buttonText() {
      return this.isEditingReminder ? 'Update Reminder' : 'Create Reminder';
    },
  },

  methods: {
    requiredRule() {
      return requiredRule;
    },
    addOrUpdateReminder() {
      if (
        !this.title ||
        (this.reminderType === 'SCHEDULED' &&
          !this.alarmDate &&
          !this.alarmTime) ||
        (this.reminderType === 'CONTINUOUS' && !this.selectedRecurrence) ||
        this.alarmTime.length !== 5
      ) {
        this.message = 'Please fill in all required fields';
        this.error = true;
        return;
      }
      if (this.isEditingReminder) {
        this.editReminder();
      } else {
        this.addReminder();
      }
    },

    createForm() {
      return {
        id: this.id,
        title: this.title,
        note: this.note,
        type: this.reminderType,
        done: this.done,
        recurrence: this.selectedRecurrence,
        earlyReminder: null,
        alarmDate: this.formatDateTime(this.alarmDate, {
          hours: this.alarmTime.slice(0, 2),
          minutes: this.alarmTime.slice(3, 5),
          seconds: 0,
        }),
      };
    },

    async editReminder() {
      if (await this.$refs.edit_reminder_form.validate()) {
        let response = unifiedResponseHandler(
          await reminderService.editReminder(this.createForm())
        );
        if (response.success) {
          this.message = 'Reminder has been edited successfully';
          this.success = true;
          this.$emit('successfulReminderOperation');
        } else {
          this.message = response.message;
          this.error = true;
        }
      }
    },

    async addReminder() {
      if (await this.$refs.edit_reminder_form.validate()) {
        let response = unifiedResponseHandler(
          await reminderService.addReminder(this.createForm())
        );
        if (response.success) {
          this.message = 'Reminder has been added successfully';
          this.success = true;
          this.setReminderToEditVariables(response.data);
          this.isEditingReminder = true;
          this.$emit('successfulReminderOperation');
        } else {
          this.message = response.message;
          this.error = true;
        }
      }
    },
    closeEntireModal() {
      this.$emit('closeModal');
    },

    setReminderToEditVariables(newReminder) {
      if (newReminder) {
        this.isEditingReminder = true;
        this.reminder = newReminder;
        this.id = this.reminder.id;
        this.title = this.reminder.title;
        this.done = this.reminder.done;
        this.owner = this.reminder.owner;
        this.note = this.reminder.note;
        this.reminderType = this.reminder.type;
        this.alarmDate = this.reminder.alarmDate.slice(0, 10);
        this.alarmTime = this.reminder.alarmDate.slice(11, 16);
        this.selectedRecurrence = this.reminder.recurrence;
      }
    },

    formatDateString(isoString) {
      const date = new Date(isoString);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    },

    formatTimeWithZeroPadding({ hours, minutes }) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    },

    formatDateTime(date, time) {
      return `${this.formatDateString(date)} ${this.formatTimeWithZeroPadding(time)}`;
    },
  },
  async mounted() {
    this.setReminderToEditVariables(this.reminderToEdit);
  },
  watch: {
    selectedRecurrence(newVal, oldVal) {
      if (oldVal == null) {
        this.reminderType = 'CONTINUOUS';
      } else if (newVal == null) {
        this.reminderType = 'SCHEDULED';
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-text-field .v-icon) {
  font-size: 20px;
  color: #5e5e5e;
}
.custom-datepicker :deep(input) {
  height: 56px;
}
.custom-text-field :deep(div) {
  font-size: 15px;
  border-color: #7e7e7e;
  transition: border-color 2s ease;
}
</style>
