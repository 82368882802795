<template>
  <v-container fluid>
    <template v-if="users">
      <v-row>
        <v-col cols="12" md="4" sm="8">
          <div class="d-flex">
            <v-text-field placeholder="Search" v-model="searchValue">
              <template v-slot:prepend-inner>
                <v-icon
                  icon="mdi-magnify"
                  variant="tonal"
                  color="button_color"
                  class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            color="button_color"
            @click="permitUserToGroupModal = true"
            icon="mdi-plus"
            class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="users"
              ><v-icon>mdi-export</v-icon></download-excel
            >
          </v-btn>
        </v-col>
      </v-row>
      <EasyDataTable
        :headers="headers"
        :items="users"
        :search-field="searchField"
        :search-value="searchValue"
        alternating
        border-cell
        class="custom-data-table"
      >
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="removeUserAccessFromGroup(item)">mdi-delete</v-icon>
        </template>
      </EasyDataTable>
    </template>

    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
    <Confirmation_Modal
      :confirmation="confirmation"
      @DoConfirmation="getConfirmation()"
      @closeConfirmationModal="confirmation = false"
    ></Confirmation_Modal>
  </v-container>
  <v-dialog v-model="permitUserToGroupModal" width="auto">
    <permit-user-access-to-group-modal
      :third-party-group="this.thirdPartyGroup"
    >
    </permit-user-access-to-group-modal>
  </v-dialog>
</template>

<script>
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Confirmation_Modal from '@/components/Utils/Confirmation_Modal.vue';
import { unifiedResponseHandler, setPage } from '@/utils/helpers.js';
import third_party_groupService from '@/services/third_party_group.service.js';
import userService from '@/services/user.service.js';
import { requiredRule } from '@/utils/rules.js';
import emitter from '@/plugins/mitt.js';
import PermitUserAccessToGroupModal from '@/components/HomeManagement/ThirdParty/ThirdPartyGroup/permit-user-access-to-group-modal.vue';

export default {
  components: {
    PermitUserAccessToGroupModal,
    Confirmation_Modal,
    Error_Modal,
    Success_Modal,
  },
  props: ['thirdPartyGroup'],

  data() {
    return {
      response: null,
      message: '',
      searchField: '',
      searchValue: '',
      users: [],
      headers: [
        { text: 'ID', value: 'ID' },
        { text: 'Last name', value: 'lastName' },
        { text: 'First name', value: 'firstName' },
        { text: 'Email', value: 'email' },
        { text: 'Cell phone', value: 'cellPhoneNumber' },
        { text: 'Role', value: 'role' },
        { text: 'Delete', value: 'delete' },
        { text: 'Edit', value: 'edit' },
      ],
      confirmation: false,
      confirmed: false,
      success: false,
      error: false,
      permitUserToGroupModal: false,
      loading: true,
    };
  },

  methods: {
    requiredRule() {
      return requiredRule;
    },
    async getUsers() {
      this.loading = true;
      let response = unifiedResponseHandler(
        await third_party_groupService.getPermittedUsers(
          this.thirdPartyGroup.id
        )
      );
      if (response.success) {
        this.loading = false;
        this.users = response.data;
      }
    },

    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },
    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },
    async removeUserAccessFromGroup(user) {
      this.confirmation = true;
      await this.waitForConfirmation();
      if (this.confirmed) {
        this.confirmed = false;
        let response = unifiedResponseHandler(
          await userService.removeUserAccessToThirdPartyGroup(
            user.id,
            this.thirdPartyGroup.id
          )
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          await this.getUsers();
        } else {
          this.error = true;
        }
      }
    },
    goToEdit(user) {
      this.$store.dispatch('setChosenUser', user);
      this.$store.dispatch('setIsEditingUser', true);
      setPage('new_user');
    },
  },

  async mounted() {
    await this.getUsers();
    emitter.on('successPermitUserOperation', () => {
      this.getUsers();
    });
  },
};
</script>

<style scoped>
.styled-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.styled-table tr:hover {
  background-color: #f1f1f1;
}
</style>
