import { createRouter, createWebHistory } from 'vue-router';
import Dashboard_V01 from '@/views/Dashboard_V01.vue';
import Login_V01 from '@/views/Login_V01.vue';

const routes = [
  {
    path: '/dashboard_v01',
    name: 'dashboard_v01',
    component: Dashboard_V01,
  },

  {
    path: '/login_v01',
    name: 'login_v01',
    component: Login_V01,
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'dashboard_v01' },
  },
];
const history = createWebHistory();

const router = createRouter({
  routes,
  history,
});

export default router;
