import axios from 'axios';
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class CurrencyService {
  async getCurrencies() {
    try {
      return await axios.get(BASE_URL + 'currency/getAll', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async addCurrency(form) {
    try {
      return await axios.post(BASE_URL + 'currency/add', form, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async deleteCurrency(id) {
    try {
      return await axios.delete(BASE_URL + 'currency/delete', {
        headers: authHeader(),
        params: {
          currencyId: id,
        },
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async editCurrency(new_currency) {
    try {
      return await axios.post(BASE_URL + 'currency/edit', new_currency, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new CurrencyService();
