<script>
export default {
  name: 'ball-animation',
};
</script>

<template>
  <div
    class="ball-animation"
    style="
      width: 20px;
      height: 20px;
      background-color: var(--main-color);
      border-radius: 50%;
      margin-left: 5px;
    "
  ></div>
</template>

<style scoped>
/* Add the ball animation class */
/* Add the ball animation class */
.ball-animation {
  animation-name: moveBall;
  animation-duration: 3s; /* Adjust the duration as needed */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; /* Infinite animation loop */
  opacity: 1;
  width: 20px;
  height: 20px;
  background-color: var(--main-color);
  border-radius: 50%;
  position: relative;
  animation-delay: 0.5s; /* Delay before starting the animation */
  /* box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2), 0 0 30px rgba(0,0,0, 0.3); Add a subtle shadow */
  box-shadow:
    0 20px 20px rgba(0, 0, 0, 0.2),
    0 0 30px rgba(0, 0, 0, 0.3),
    inset 0 0 10px rgba(0, 0, 0, 0.1); /* Inset shadow effect */
}
/* Keyframes for ball animation */
@keyframes moveBall {
  0%,
  100% {
    left: 0;
    top: 0;
    transform: translateY(0);
  }
  25% {
    left: 25%;
    transform: translateY(-15px);
  }
  50% {
    left: 50%;
    transform: translateY(15px);
  }
  75% {
    left: 90%;
    transform: translateY(-15px);
  }
}
</style>
