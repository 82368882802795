<template>
  <v-card variant="flat" color="side_color">
    <div class="d-flex align-center justify-space-between">
      <div>
        <v-tabs>
          <v-tab
            class="d-flex align-center"
            @click="changeSection('home_main', home_options)"
            :width="sideBarWidth"
          >
            <v-img
              elevation="2"
              :src="ellingtonLogo"
              class="imgLogo"
              :width="sideBarWidth / 5"
            ></v-img>
            {{ $t('home') }}
          </v-tab>
          <v-tab
            elevation="2"
            prepend-icon="mdi-cash-refund"
            @click="changeSection('money_transfer', money_transfer_options)"
            :width="tabWidth"
            >{{ $t('money transfer') }}
          </v-tab>
          <v-tab
            elevation="2"
            prepend-icon="mdi-bank"
            @click="changeSection('bank_and_cash', bank_and_cash_options)"
            :width="tabWidth"
            >{{ $t('bank and cash') }}
          </v-tab>
          <v-tab
            elevation="2"
            prepend-icon="mdi-calculator"
            @click="changeSection('accounting', accounting_options)"
            :width="tabWidth"
            >{{ $t('accounting') }}
          </v-tab>
          <v-tab
            elevation="2"
            prepend-icon="mdi-history"
            @click="changeSection('past_operation', past_operation_options)"
            :width="tabWidth"
            >{{ $t('past operation') }}
          </v-tab>
          <v-tab
            elevation="2"
            prepend-icon="mdi-file-chart"
            @click="changeSection('reports', reports_options)"
            :width="tabWidth"
            >{{ $t('reports') }}
          </v-tab>
          <v-tab
            elevation="2"
            prepend-icon="mdi-shopping"
            @click="changeSection('commerce', commerce_options)"
            :width="tabWidth"
            >{{ $t('commerce') }}
          </v-tab>
        </v-tabs>
      </div>
      <div>
        <v-menu location="right">
          <template v-slot:activator="{ props }">
            <!-- <span class="mt-3 hide-10">{{ authEmail }}</span> -->
            <v-btn
              size="small"
              color="button_color"
              class="mr-4 ml-2"
              icon="mdi-dots-vertical"
              v-bind="props"
              @click="getServerTime"
            ></v-btn>
          </template>
          <v-list>
            <v-list-item>{{ authEmail }}</v-list-item>
            <v-list-item
              title="change password"
              class="pointer"
              @click="openChangePassword"
            ></v-list-item>

            <v-list-item
              title="change language to persian"
              class="pointer"
              @click="changeLanguageToPersian"
            ></v-list-item>
            <v-list-item
              title="change language to english"
              class="pointer"
              @click="changeLanguageToEnglish"
            ></v-list-item>
            <v-list-item class="pointer">
              <div style="display: flex">
                <Dark_v01></Dark_v01>
                <Light_v01></Light_v01>
              </div>
            </v-list-item>
            <v-list-item>{{ timeServer }}</v-list-item>
            <v-list-item
              title="logout"
              class="pointer"
              @click="logout"
            ></v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-card>
  <v-dialog v-model="change_password" width="auto">
    <change-password-modal></change-password-modal>
  </v-dialog>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import free_cash_reportService from '@/services/free_cash_report.service';
import { setPage } from '@/utils/helpers';
import { requiredRule } from '@/utils/rules';
import { changeSection } from '@/utils/helpers';
import { authEmail } from '@/services/auth-header';
import { mapGetters } from 'vuex';
import emitter from '@/plugins/mitt';
import AuthService from '@/services/auth.service';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Dark_v01 from '@/components/Dark_v01.vue';
import Light_v01 from '@/components/Light_v01.vue';
import ChangePasswordModal from '@/components/HomeManagement/Setup/change-password-modal.vue';
import ellingtonLogo from '@/assets/logo.png';

export default {
  name: 'navbar_v01',
  components: {
    ChangePasswordModal,
    Light_v01,
    Dark_v01,
    Error_Modal,
    Success_Modal,
  },
  data() {
    return {
      ellingtonLogo,
      windowWidth: window.innerWidth,
      change_password: false,
      requiredRule: requiredRule,
      message: null,
      authEmail: null,
      success: false,
      error: false,
      value: false,
      timeServer: null,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async getServerTime() {
      let response = await free_cash_reportService.getServerTime();
      console.log(response);
      if (response.status === 200) {
        this.timeServer = response.data;
        console.log('timeServer', this.timeServer);
      } else {
        this.timeServer = '00:00:00';
      }
    },
    changeSection(section, section_str) {
      changeSection(section, section_str);
    },
    setPage(page) {
      setPage(page);
    },
    logout() {
      AuthService.logout();
      this.$router.push('login_v01');
    },
    openChangePassword() {
      this.change_password = true;
    },
    changeLanguageToPersian() {
      this.value = true;
    },
    changeLanguageToEnglish() {
      this.value = false;
    },
  },

  computed: {
    ...mapGetters([
      'page',
      'customer_management_options',
      'feeding_info_options',
      'user_management_options',
      'request_management_options',
      'money_transfer_options',
      'bank_and_cash_options',
      'accounting_options',
      'section',
      'home_options',
      'individuals_options',
      'third_parties_options',
      'operations_options',
      'basic_info_options',
      'reports_options',
      'past_operation_options',
      'commerce_options',
      'is_editing_user',
    ]),
    sideBarWidth() {
      const screenWidth = this.windowWidth;
      return screenWidth * 0.15;
    },
    tabWidth() {
      const screenWidth = this.windowWidth;
      return screenWidth * 0.13;
    },
  },

  mounted() {
    this.authEmail = authEmail();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.$i18n.locale = 'fa';
        let style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.v-locale--is-ltr{direction: rtl !important;}';
        document.getElementsByTagName('head')[0].appendChild(style);
        emitter.emit('drawer_location', 'end');
      } else {
        this.$i18n.locale = 'en';

        let style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.v-locale--is-ltr{direction: ltr !important;}';
        document.getElementsByTagName('head')[0].appendChild(style);
        emitter.emit('drawer_location', 'start');
      }
    },
  },
};
</script>

<style>
.imgLogo {
  height: fit-content;
}

.no-shrink {
  flex-shrink: 0;
}
</style>
