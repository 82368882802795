<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field placeholder="Search" v-model="searchValue">
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="addThirdPartyModal = true"
          icon="mdi-plus"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="thirdParties"
            ><v-icon>mdi-export</v-icon></download-excel
          >
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="thirdParties">
      <div class="thirdPartiesList">
        <EasyDataTable
          :headers="headers"
          :items="thirdParties"
          :search-field="searchField"
          :search-value="searchValue"
          alternating
          border-cell
          class="custom-data-table"
        >
          <template #item-edit="item">
            <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
          </template>
          <template #item-delete="item">
            <v-icon @click="deleteThirdPartyFromGroup(item)">mdi-delete</v-icon>
          </template>
        </EasyDataTable>
      </div>
    </template>
  </v-container>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
  <Confirmation_Modal
    :confirmation="confirmation"
    @DoConfirmation="getConfirmation()"
    @closeConfirmationModal="confirmation = false"
  ></Confirmation_Modal>

  <v-dialog v-model="addThirdPartyModal" width="auto">
    <add-third-party-to-group-modal :third-party-group="this.thirdPartyGroup">
    </add-third-party-to-group-modal>
  </v-dialog>
</template>

<script>
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import third_party_groupService from '@/services/third_party_group.service.js';
import {
  handleKeyDown,
  setPage,
  unifiedResponseHandler,
} from '@/utils/helpers.js';
import Confirmation_Modal from '@/components/Utils/Confirmation_Modal.vue';
import emitter from '@/plugins/mitt.js';
import { requiredRule } from '@/utils/rules.js';
import AddThirdPartyToGroupModal from '@/components/HomeManagement/ThirdParty/ThirdPartyGroup/add_third_party_to_group_modal.vue';

export default {
  name: 'third_parties_list_in_group',
  props: ['thirdPartyGroup'],
  components: {
    AddThirdPartyToGroupModal,
    Confirmation_Modal,
    Success_Modal,
    Error_Modal,
  },

  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Full name', value: 'fullName' },
        { text: 'Type', value: 'thirdPartyType' },
        { text: 'Email', value: 'email' },
        { text: 'Delete', value: 'delete' },
        { text: 'Edit/Details', value: 'edit' },
      ],
      thirdParties: [],
      searchValue: '',
      searchField: '',
      message: '',
      addThirdPartyModal: false,
      success: false,
      error: false,
      loading: true,
      confirmation: false,
      confirmed: false,
    };
  },
  methods: {
    requiredRule() {
      return requiredRule;
    },
    handleKeyDown,
    goToEdit(third_party) {
      this.$store.dispatch('setChosenThirdParty', third_party);
      this.$store.dispatch('setIsEditingThirdParty', true);
      setPage('new_third_party');
    },
    async getThirdPartiesInGroup() {
      this.loading = true;
      let response = unifiedResponseHandler(
        await third_party_groupService.getAllThirdPartiesInGroup(
          this.thirdPartyGroup.id
        )
      );
      if (response.success) {
        this.thirdParties = response.data;
        console.log(this.thirdParties);
        this.loading = false;
      }
    },
    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },
    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },
    async deleteThirdPartyFromGroup(third_party) {
      this.confirmation = true;
      await this.waitForConfirmation();
      if (this.confirmed) {
        this.confirmed = false;
        let response = unifiedResponseHandler(
          await third_party_groupService.removeThirdPartyFromGroup(
            this.thirdPartyGroup.id,
            third_party.id
          )
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          await this.getThirdPartiesInGroup();
        } else {
          this.error = true;
        }
      }
    },
  },
  computed: {},
  async mounted() {
    emitter.on('successThirdPartyOperation', () => {
      this.getThirdPartiesInGroup();
    });
    await this.getThirdPartiesInGroup();
  },
};
</script>

<style scoped></style>
