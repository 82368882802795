<template>
  <div>
    <v-container fluid>
      <v-tabs fixed-tabs class="mb-5">
        <v-tab @click="CurrentTab = 'editThirdPartyGroup'"
          >Third Party Group Info</v-tab
        >
        <v-tab
          v-if="is_editing_third_party_group"
          @click="CurrentTab = 'thirdParties'"
          >Third Parties</v-tab
        >
        <v-tab
          v-if="is_editing_third_party_group"
          @click="CurrentTab = 'users'"
        >
          Allowed Users</v-tab
        >
      </v-tabs>

      <third-party-group-info v-if="CurrentTab === 'editThirdPartyGroup'">
      </third-party-group-info>

      <Third_parties_list_in_group
        v-if="CurrentTab === 'thirdParties'"
        :thirdPartyGroup="chosen_third_party_group"
      >
      </Third_parties_list_in_group>

      <Permitted_users_to_group_list
        v-if="CurrentTab === 'users'"
        :thirdPartyGroup="chosen_third_party_group"
      >
      </Permitted_users_to_group_list>
    </v-container>

    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>

    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
  </div>
</template>

<script>
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import ThirdPartyGroupInfo from '@/components/HomeManagement/ThirdParty/ThirdPartyGroup/third-party-group-info.vue';
import Third_parties_list_in_group from '@/components/HomeManagement/ThirdParty/ThirdPartyGroup/third-parties-list-in-group.vue';
import Permitted_users_to_group_list from '@/components/HomeManagement/ThirdParty/ThirdPartyGroup/permitted-users-to-group-list.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Permitted_users_to_group_list,
    Third_parties_list_in_group,
    ThirdPartyGroupInfo,
    Success_Modal,
    Error_Modal,
  },
  data() {
    return {
      CurrentTab: 'editThirdPartyGroup',
      individualListModal: false,
      accountTypeListModal: false,
      message: '',
      success: false,
      error: false,
    };
  },
  computed: {
    ...mapGetters(['chosen_third_party_group', 'is_editing_third_party_group']),
  },
};
</script>
