<template>
  <div class="paading_info">
    <v-container class="info-box" v-if="!broken_page" fluid>
      <h4>Company Database</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum
        doloremque necessitatibus ab magni ut dolor. Commodi, hic. Est numquam
        quam expedita aliquam ullam possimus, quas, cupiditate eius tenetur quo
        vel.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum
        doloremque necessitatibus ab magni ut dolor. Commodi, hic. Est numquam
        quam expedita aliquam ullam possimus, quas, cupiditate eius tenetur quo
        vel.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum
        doloremque necessitatibus ab magni ut dolor. Commodi, hic. Est numquam
        quam expedita aliquam ullam possimus, quas, cupiditate eius tenetur quo
        vel.
      </p>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <v-dialog v-model="success" width="auto">
      <v-alert type="success">success</v-alert>
    </v-dialog>

    <v-dialog v-model="error" width="auto">
      <v-alert type="error">error</v-alert>
    </v-dialog>
  </div>
</template>

<script>
import Broken_Page_V01 from '../Broken_Page_V01.vue';

export default {
  name: 'Q-BillsReport-Modal',
  components: {
    Broken_Page_V01,
  },
};
</script>
