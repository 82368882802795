<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h5>Proforma Invoice {{ type }}</h5>
      <v-form ref="add_account_type_form">
        <v-row>
          <v-col cols="4" md="4" sm="12" xs="12">
            <v-autocomplete
              label="Select Type"
              v-model="selectedType"
              :items="Types"
              item-title="name"
              item-value="id"
              filled
              return-object
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <div v-if="type == 'Type A'">
          <v-row>
            <v-col cols="12" md="3" sm="12" xs="12">
              <v-tab
                @click="thirdPartyListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
                >select</v-tab
              >
              <v-text-field
                label="Seller"
                v-model="thirdPartyFullName"
                @keyup.backspace="clearThirdParty"
                @keydown="handleKeyDown"
              ></v-text-field>
              <v-tab
                @click="secondaryThirdPartyListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
                >select</v-tab
              >
              <v-text-field
                label="Consignee"
                v-model="secondaryThirdPartyFullName"
                @keyup.backspace="clearSecondaryThirdParty"
                @keydown="handleKeyDown"
              ></v-text-field>
              <v-text-field
                label="Vessel flight no and Date"
                v-model="VesselflightnoandDate"
              ></v-text-field>
              <v-text-field
                label="Port of Arrival"
                v-model="PortofArrival"
              ></v-text-field>
              <v-text-field
                label="Port / Airport of Loading"
                v-model="PortAirportofLoading"
              ></v-text-field>
              <v-text-field
                label="Place of Delivery"
                v-model="PlaceofDelivery"
              ></v-text-field>
              <v-text-field
                label="Shipping marks"
                v-model="Shippingmarks"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="12" xs="12">
              <label for="date-picker" style="color: black">PFI Date:</label>
              <VueDatePicker
                auto-apply="true"
                id="date-picker"
                v-model="PFIDate"
                model-type="yyyy-MM-dd"
                :show-timepicker="false"
              ></VueDatePicker>

              <v-text-field
                style="margin-top: 55px"
                label="PFI Number"
                v-model="PFINumber"
              ></v-text-field>
              <v-text-field
                label="Buyer Referencr"
                v-model="BuyerReferencr"
              ></v-text-field>
              <v-text-field
                label="Sellers Referencr"
                v-model="SellersReferencr"
              ></v-text-field>
              <v-text-field
                label="Other Referencr"
                v-model="OtherReferencr"
              ></v-text-field>
              <v-text-field
                label="Buyer (if not consignee)"
                v-model="Buyernotconsignee"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="12" xs="12">
              <label for="date-picker" style="color: black">ETD:</label>
              <VueDatePicker
                auto-apply="true"
                id="date-picker"
                v-model="ETD"
                model-type="yyyy-MM-dd"
                :show-timepicker="false"
              ></VueDatePicker>
              <label for="date-picker" style="color: black; margin-top: 50px"
                >ETA:</label
              >
              <VueDatePicker
                auto-apply="true"
                id="date-picker"
                v-model="ETA"
                model-type="yyyy-MM-dd"
                :show-timepicker="false"
              ></VueDatePicker>
              <v-autocomplete
                style="margin-top: 20px"
                label="Country of Origin of Goods"
                v-model="CountryofOriginofGoods"
                :items="countries"
                item-title="name"
                item-value="id"
                filled
                return-object
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props">
                    <template v-slot:append>
                      <country-flag :country="item.raw.code" />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-autocomplete
                label="Country of Destination"
                v-model="CountryofDestination"
                :items="countries"
                item-title="name"
                item-value="id"
                filled
                return-object
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props">
                    <template v-slot:append>
                      <country-flag :country="item.raw.code" />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-autocomplete
                label="Terms of delivery and payment"
                v-model="selectedTypeDelivery"
                :items="TypesDelivery"
                item-title="name"
                item-value="id"
                filled
                return-object
              ></v-autocomplete>
              <label for="date-picker" style="color: black; margin-top: -5px"
                >PFI Expiration Date:</label
              >
              <VueDatePicker
                auto-apply="true"
                id="date-picker"
                v-model="PFIExpirationDate"
                model-type="yyyy-MM-dd"
                :show-timepicker="false"
              ></VueDatePicker>
            </v-col>
            <v-col cols="12" md="3" sm="12" xs="12">
              <v-autocomplete
                style="margin-top: 20px"
                label="Transaction Currency"
                v-model="selectedCurrency"
                :items="currencies"
                item-title="name"
                item-value="name"
                filled
                return-object
              ></v-autocomplete>
              <v-autocomplete
                class="mt-25px"
                label="Transshipment"
                v-model="Transshipment"
                :items="TypesTransshipment"
                item-title="name"
                item-value="id"
                filled
                return-object
              ></v-autocomplete>
              <v-autocomplete
                label="Partial Shipment"
                v-model="partialShipment"
                :items="TypespartialShipment"
                item-title="name"
                item-value="id"
                filled
                return-object
              ></v-autocomplete>
              <v-text-field label="Net Wt (Kg)" v-model="NetWt"></v-text-field>
              <v-text-field label="Gr Wt (Kg)" v-model="GrWt"></v-text-field>
              <v-text-field
                label="Quantity & Unit of Qty (Kg)"
                v-model="QuantityUnitofQty"
              ></v-text-field>
              <v-text-field
                label="Unit Price (AED)"
                v-model="UnitPrice"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-btn color="button_color" class="mr-4" @click="generatePdf"
              >Generate PDF Report</v-btn
            >
          </v-row>
        </div>

        <div v-if="type == 'Type B'">
          s
          <h6>Type B Content</h6>
        </div>

        <div v-if="type == 'Type C'">
          <h6>Type C Content</h6>
        </div>
      </v-form>

      <v-dialog v-model="thirdPartyListModal" width="auto">
        <third_party_list_modal thirdPartyType="ALL"></third_party_list_modal>
      </v-dialog>
      <v-dialog v-model="secondaryThirdPartyListModal" width="auto">
        <third_party_list_modal
          thirdPartyType="SECONDARY_THIRDPARTY"
        ></third_party_list_modal>
      </v-dialog>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <v-dialog v-model="error">
      <div class="error-dialog">
        <div class="error-left">
          <div class="red-icon"></div>
        </div>
        <div class="error-right">
          <span @click="error = false" style="text-align: right">✖</span>
          <h4>Error</h4>
          <p class="error-message">{{ message }}</p>
          <button class="close-button" @click="error = false">Close</button>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="success">
      <div class="success-dialog">
        <div class="success-left">
          <div class="green-circle">
            <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M50 100 L80 130 L150 50"
                stroke="white"
                stroke-width="25"
                fill="transparent"
                class="success-tick"
              />
            </svg>
          </div>
        </div>
        <div class="success-right">
          <span @click="success = false" style="text-align: right">✖</span>
          <h4>Success</h4>
          <p class="success-message">{{ message }}</p>
          <button class="success-close-button" @click="success = false">
            Close
          </button>
        </div>
      </div>
    </v-dialog>
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="750px"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div class="mt-8 mr-8" dir="ltr">
          <div class="Commerce-grid-container">
            <h6>Proforma Invoice</h6>
            <!-- part one -->
            <div class="Commerce-grid-col-2to1">
              <div class="Commerce-grid-col-2-1">
                <span> Seller: {{ thirdPartyFullName }} </span>
                <span> Consignee: {{ secondaryThirdPartyFullName }} </span>
                <span> Commercial card No: </span>
                <span>
                  National ID Number: {{ thirdPartyIdentificationCode }}
                </span>
                <div class="Commerce-grid-col-2to2">
                  <span>
                    Vessel flight no and Date: {{ VesselflightnoandDate }}
                  </span>
                  <span> Port of Arrival: {{ PortofArrival }} </span>
                </div>
                <div class="Commerce-grid-col-2to2">
                  <span>
                    Port / Airport of Loading: {{ PortAirportofLoading }}
                  </span>
                  <span> Place of Delivery: {{ PlaceofDelivery }} </span>
                </div>
              </div>
              <div class="Commerce-grid-col-2-2">
                <span> PFI Number: {{ PFINumber }} </span>
                <div class="Commerce-grid-col-2to2">
                  <span> PFI Date: {{ PFIDate }} </span>
                  <span> Buyer Referencr: {{ BuyerReferencr }} </span>
                </div>
                <div class="Commerce-grid-col-2to2">
                  <span> Sellers Referencr: {{ SellersReferencr }} </span>
                  <span> Other Referencr: {{ OtherReferencr }} </span>
                </div>
                <span> Buyer (if not consignee): {{ Buyernotconsignee }} </span>
                <div class="Commerce-grid-col-2to2">
                  <span> ETD: {{ ETD }} </span>
                  <span>
                    Country of Origin of Goods: {{ CountryofOriginofGoodsName }}
                  </span>
                </div>
                <div class="Commerce-grid-col-2to2">
                  <span> ETA: {{ ETA }} </span>
                  <span>
                    Country of Destination: {{ CountryofDestinationName }}
                  </span>
                </div>
                <span>
                  Terms of delivery and payment: {{ selectedTypeDeliveryName }}
                  <br />
                  Transaction Currency: {{ selectedCurrencyName }}
                  <br />
                  PFIExpirationDate: {{ PFIExpirationDate }}
                  <br />
                  Partial Shipment: {{ partialShipmentName }}
                  <br />
                  Transshipment: {{ TransshipmentName }}
                </span>
              </div>
              <div class="Commerce-grid-col-1">
                <span> Shipping marks:{{ Shippingmarks }} </span>
              </div>
            </div>

            <!-- Continue with other rows and columns -->
          </div>
        </div>
      </template>
    </vue3-html2pdf>
  </div>
</template>

<script>
import Vue3Html2pdf from 'vue3-html2pdf';
import { requiredRule } from '@/utils/rules';
import Broken_Page_V01 from '../Broken_Page_V01.vue';
import emitter from '@/plugins/mitt';
import third_party_list_modal from '../Modals/Third_Party_List_Modal_V01.vue';
import CountryService from '@/services/country.service';
import CountryFlag from 'vue-country-flag-next';
import { unifiedResponseHandler, formatCurrencyInfo } from '@/utils/helpers';
import currencyService from '@/services/currency.service';
export default {
  name: 'proforma_invoice_v01',
  components: {
    Broken_Page_V01,
    third_party_list_modal,
    CountryFlag,
    Vue3Html2pdf,
  },
  data() {
    return {
      thirdParty: null,
      secondaryThirdParty: null,
      searchField: '',
      searchValue: '',
      message: null,
      broken_page: false,
      success: false,
      error: false,
      type: null,
      selectedType: null,
      Types: [
        { name: 'Type A', id: 0 },
        { name: 'Type B', id: 1 },
        { name: 'Type C', id: 2 },
      ],
      selectedTypeDelivery: null,
      TypesDelivery: [
        { name: 'Type X', id: 0 },
        { name: 'Type Y', id: 1 },
        { name: 'Type Z', id: 2 },
      ],
      Transshipment: null,
      TypesTransshipment: [
        { name: 'Not Allowed', id: 0 },
        { name: 'Allowed', id: 1 },
      ],
      partialShipment: null,
      TypespartialShipment: [
        { name: 'Not Allowed', id: 0 },
        { name: 'Allowed', id: 1 },
      ],
      thirdPartyListModal: false,
      requiredRule: requiredRule,
      secondaryThirdPartyListModal: false,
      PFINumber: null,
      PFIDate: null,
      SellersReferencr: null,
      OtherReferencr: null,
      BuyerReferencr: null,
      ETD: null,
      ETA: null,
      PFIExpirationDate: null,
      VesselflightnoandDate: null,
      PortofArrival: null,
      PortAirportofLoading: null,
      PlaceofDelivery: null,
      CountryofDestination: null,
      CountryofOriginofGoods: null,
      countries: [],
      Shippingmarks: null,
      selectedCurrency: null,
      currencies: [],
      NetWt: null,
      GrWt: null,
      QuantityUnitofQty: null,
      UnitPrice: null,
      Buyernotconsignee: null,
    };
  },

  computed: {
    partialShipmentName() {
      if (this.partialShipment) {
        return this.partialShipment.name;
      } else {
        return null;
      }
    },
    TransshipmentName() {
      if (this.Transshipment) {
        return this.Transshipment.name;
      } else {
        return null;
      }
    },
    selectedCurrencyName() {
      if (this.selectedCurrency) {
        return this.selectedCurrency.symbol;
      } else {
        return null;
      }
    },
    selectedTypeDeliveryName() {
      if (this.selectedTypeDelivery) {
        return this.selectedTypeDelivery.name;
      } else {
        return null;
      }
    },
    CountryofDestinationName() {
      if (this.CountryofDestination) {
        return this.CountryofDestination.name;
      } else {
        return null;
      }
    },
    CountryofOriginofGoodsName() {
      if (this.CountryofOriginofGoods) {
        return this.CountryofOriginofGoods.name;
      } else {
        return null;
      }
    },
    thirdPartyIdentificationCode() {
      if (this.thirdParty) {
        return this.thirdParty.identificationCode;
      } else {
        return null;
      }
    },
    thirdPartyFullName() {
      if (this.thirdParty) {
        return this.thirdParty.fullName;
      } else {
        return null;
      }
    },

    thirdPartyId() {
      if (this.thirdParty) {
        return this.thirdParty.id;
      } else {
        return null;
      }
    },
    secondaryThirdPartyFullName() {
      if (this.secondaryThirdParty) {
        return this.secondaryThirdParty.fullName;
      } else {
        return null;
      }
    },
    secondaryThirdPartyId() {
      if (this.secondaryThirdParty) {
        return this.secondaryThirdParty.id;
      } else {
        return null;
      }
    },
  },
  methods: {
    logSelectedType() {
      console.log('selectedType2', this.selectedType.name);
      this.type = this.selectedType.name;
      console.log('this.type', this.type);
      return this.type;
    },
    clearSecondaryThirdParty() {
      this.secondaryThirdParty = null;
    },
    async getCountries() {
      let response = unifiedResponseHandler(
        await CountryService.getCountries()
      );
      if (response.success) {
        this.countries = response.data;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async getCurrencies() {
      let response = unifiedResponseHandler(
        await currencyService.getCurrencies()
      );
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  async mounted() {
    emitter.on('new_third_party', (value) => {
      if (
        (value.thirdPartyType == 'SECONDARY_THIRDPARTY') &
        this.secondaryThirdPartyListModal
      ) {
        this.secondaryThirdParty = value;
        this.secondaryThirdPartyListModal = false;
      }
      if (this.thirdPartyListModal) {
        this.thirdParty = value;
        this.thirdPartyListModal = false;
      }
      console.log('th', this.thirdParty);
      console.log('p', this.secondaryThirdParty);
    });
    await this.getCountries();
    await this.getCurrencies();
  },
  watch: {
    selectedType: 'logSelectedType', // Use the method as the watch handler
  },
};
</script>
<style>
.Commerce-grid-container {
  font-size: 12px;
}
.Commerce-grid-container h6 {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Commerce-grid-col-1 {
  display: grid;
  grid-template-columns: 1fr;
}
.Commerce-grid-col-2to1 {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Two equal-width columns */
}
.Commerce-grid-col-2to2 {
  display: grid;
  grid-template-columns: 2fr 2fr; /* Two equal-width columns */
}
.Commerce-grid-container span {
  border: 1px solid #ccc;
  display: block;
  width: 100%;
  text-align: left;
  padding: 5px 10px;
}
</style>
