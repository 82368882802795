<template>
  <v-sheet max-width="1200" min-width="750" style="padding: 20px">
    <div class="border-1">
      <h4 class="border-h4">Group Deal Summary</h4>
      <div class="grid-card-2" ref="pdfContent">
        <div
          class="summary-row"
          v-for="(value, label) in summaryData"
          :key="label"
        >
          <span style="flex: 1">{{ label }}:</span>
          <span style="flex: 1; text-align: left">{{ value }}</span>
        </div>
      </div>
      <v-row justify="end">
        <v-btn class="mr-4 top-20" color="button_color" @click="generatePDF"
          >PDF</v-btn
        >
      </v-row>
    </div>
  </v-sheet>

  <Error_Modal
    :error="error"
    :message="message"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import html2pdf from 'html2pdf.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import { formatNumber } from '@/utils/helpers.js';
import getStatusDisplay from '@/utils/NewFunctions/getStatusDisplay.js';

export default {
  name: 'Group_Deal_Modal_V01',
  components: {
    Error_Modal,
  },
  data() {
    return {
      message: null,
      error: false,
    };
  },
  methods: {
    formatNumber,
    getStatusDisplay,
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: 'groupdealSummary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf(element, options);
    },
  },
  computed: {
    ...mapGetters(['chosen_group_deal', 'page']),
    summaryData() {
      if (!this.chosen_group_deal) return {};
      return {
        Event: this.chosen_group_deal.id,
        Status: this.chosen_group_deal.status
          ? this.getStatusDisplay(this.chosen_group_deal.status)
          : '-',
        'Customer Name': this.chosen_group_deal.customer.fullName,
        'Receive Amount':
          formatNumber(this.chosen_group_deal.receiveAmount) +
          ' ' +
          this.chosen_group_deal.receiveCurrency.symbol,
        'Primary Send Amount':
          formatNumber(this.chosen_group_deal.primarySendAmount) +
          ' ' +
          this.chosen_group_deal.sendCurrency.symbol,
        'Secondary Send Amount': this.chosen_group_deal.secondarySendAmount
          ? formatNumber(this.chosen_group_deal.secondarySendAmount) +
            ' ' +
            this.chosen_group_deal.sendCurrency.symbol
          : '-',
        'Our Receiving': this.chosen_group_deal.ourReceivingAccountCofferName,
        'Customer Sending':
          this.chosen_group_deal.customerSendingAccountCofferName,
        'Our Primary Sending':
          this.chosen_group_deal.ourPrimarySendingAccountCofferName,
        'Our Secondary Sending': this.chosen_group_deal
          .ourSecondarySendingAccountCofferName
          ? this.chosen_group_deal.ourSecondarySendingAccountCofferName
          : '-',
        'Customer Primary Receiving':
          this.chosen_group_deal.customerPrimaryReceivingAccountCofferName,
        'Customer Secondary Receiving': this.chosen_group_deal
          .customerSecondaryReceivingAccountCofferName
          ? this.chosen_group_deal.customerSecondaryReceivingAccountCofferName
          : '-',
        'Trustee TET Incoming':
          this.chosen_group_deal.trusteeTETIncomingAccountCofferName,
        'Trustee TET Outgoing':
          this.chosen_group_deal.trusteeTETOutgoingAccountCofferName,
        'TET Bought Amount':
          formatNumber(this.chosen_group_deal.tetBoughtAmount) + ' TET',
        'TET Sold Amount':
          formatNumber(this.chosen_group_deal.tetSoldAmount) + ' TET',
        'TET Buy Rate': this.chosen_group_deal.tetBuyRate,
        'TET Sell Rate': this.chosen_group_deal.tetSellRate,
        'Total Send Amount':
          formatNumber(this.chosen_group_deal.totalSendAmount) +
          ' ' +
          this.chosen_group_deal.sendCurrency.symbol,
        'TET Into Our Wallet': formatNumber(
          this.chosen_group_deal.tetIntoOurWallet
        ),
        'Create Date': new Date(this.chosen_group_deal.createDate)
          .toISOString()
          .split('T')[0],
        'Modified Date': new Date(this.chosen_group_deal.modifiedDate)
          .toISOString()
          .split('T')[0],
      };
    },
  },
};
</script>

<style>
.bold {
  font-weight: 600;
}
</style>
