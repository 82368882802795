<script>
import { setPage } from '@/utils/helpers';

export default {
  name: 'actions-bar',
  props: {
    currentStatus: String,
    error: Boolean,
    step: Number,
    waitForResponse: Boolean,
  },
  methods: {
    async emitCancelTransferSignal() {
      this.$emit('cancelRequest');
    },
    async doRegistrationOrReconciliationOrSave() {
      if (this.currentStatus === 'NOT INITIATED')
        this.$emit('register', { showSuccess: true });
      else if (this.currentStatus === 'ACCOUNTS_UPDATED')
        this.$emit('reconcile');
      else this.$emit('register', { showSuccess: true });
    },
    async handleButton(exit = false) {
      switch (this.step) {
        case 1:
          this.$emit('register', { showSuccess: true });
          break;
        case 2:
          this.$emit('register', {
            showSuccess: false,
            generateTransaction: true,
          });
          break;
        case 4:
          this.$emit('generateTransaction');
          break;
        case 5:
          this.$emit('firstPartyConfirmed');
          break;
        case 6:
          this.$emit('requestForCompletion');
          break;
      }
      if (exit) {
        setTimeout(() => {
          if (exit) {
            setPage('internal report');
          }
        }, 1000);
      }
    },
  },
  computed: {
    registerOrSaveOrReconcile() {
      return this.currentStatus === 'NOT INITIATED'
        ? 'REGISTER'
        : this.currentStatus === 'ACCOUNTS_UPDATED'
          ? 'RECONCILE'
          : 'SAVE';
    },
  },
};
</script>

<template>
  <v-row
    class="ma-4"
    style="
      width: 95%;
      display: grid;
      grid-template: auto auto / auto auto;
      grid-gap: 10px;
      justify-content: end;
      align-items: end;
    "
  >
    <v-btn
      :disabled="
        (currentStatus !== 'NOT INITIATED' &&
          currentStatus !== 'ACCOUNTS_UPDATED' &&
          currentStatus !== 'INITIATED') ||
        waitForResponse
      "
      elevation="4"
      color="button_color"
      width="150"
      height="70"
      @click="doRegistrationOrReconciliationOrSave"
    >
      {{ registerOrSaveOrReconcile }}
    </v-btn>
    <v-btn
      :disabled="
        currentStatus === 'NOT INITIATED' ||
        currentStatus === 'COMPLETED' ||
        currentStatus === 'WAITING_FOR_APPROVAL' ||
        currentStatus === 'ACCOUNTS_UPDATED' ||
        waitForResponse
      "
      elevation="4"
      width="150"
      height="70"
      color="button_color"
      @click="handleButton()"
    >
      Save And Next
    </v-btn>
    <v-btn
      elevation="4"
      color="button_color"
      :disabled="waitForResponse"
      width="150"
      height="70"
      @click="$emit('exit')"
    >
      Exit
    </v-btn>
    <v-btn
      :disabled="currentStatus !== 'INITIATED'"
      v-if="currentStatus !== 'ACCOUNTS_UPDATED'"
      elevation="4"
      width="150"
      height="70"
      color="red"
      variant="tonal"
      @click="emitCancelTransferSignal"
    >
      Cancel Request
    </v-btn>
    <v-btn
      v-if="currentStatus === 'ACCOUNTS_UPDATED'"
      :disabled="waitForResponse"
      elevation="4"
      width="150"
      height="70"
      color="error"
      variant="tonal"
      @click="$emit('delete')"
    >
      Delete Request
    </v-btn>
  </v-row>
</template>

<style scoped></style>
