<template>
  <div class="paading_info">
    <v-container class="info-box" v-if="!broken_page" fluid>
      <h3>Welcome to the Third Parties Page!</h3>
      <p style="margin-left: 15px">
        This page allows you to manage third party (customers, trustees, and
        payers/payees) information easily. Here's what you can do:
      </p>
      <ul>
        <li>
          <h6>Search & Add Third Party:</h6>
          <p>
            Search for specific third party by entering search criteria in the
            "Search" field and pressing Enter. Click on the plus icon (+) to
            create a new third party record.
          </p>
        </li>
        <li>
          <h6>Edit Third Party Information:</h6>
          <p>
            By clicking the edit icon, you can edit or add third party account
            details, attach files, and enter third party contact or introducer
            information. You can also perform these tasks when adding a new
            third party.
          </p>
        </li>
        <li>
          <h6>Export Third Party Data:</h6>
          <p>
            Download third-party data (including information about customers,
            trustees, and payers/payees) in Excel format by clicking the export
            icon.
          </p>
        </li>
        <li>
          <h6>Delete Third Party:</h6>
          <p>
            You can delete a third party by clicking the "Delete" button. Please
            be cautious as this action is irreversible.
          </p>
        </li>
      </ul>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <v-dialog v-model="success" width="auto">
      <v-alert type="success">success</v-alert>
    </v-dialog>

    <v-dialog v-model="error" width="auto">
      <v-alert type="error">error</v-alert>
    </v-dialog>
  </div>
</template>

<script>
import Broken_Page_V01 from '../Broken_Page_V01.vue';

export default {
  name: 'Q-ThirdParty-Modal',
  components: {
    Broken_Page_V01,
  },
};
</script>
