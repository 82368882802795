<template>
  <v-container
    fluid
    style="
      background-color: white;
      width: 300px;
      min-height: 250px;
      height: auto;
      border-radius: 5px;
    "
  >
    <v-form ref="add_account_form" :disabled="loading">
      <v-row>
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-text-field
            v-if="isCostCenterOrIncomeCenter"
            class="mt-4"
            label="Account Coffer Name"
            v-model="accountCofferName"
            :rules="requiredRule()"
          >
          </v-text-field>
          <v-autocomplete
            label="Select Currency"
            v-model="selectedCurrency"
            :items="currencies"
            item-title="name"
            item-value="name"
            filled
            return-object
            :rules="requiredRule()"
          >
          </v-autocomplete>

          <v-text-field
            class="mt-4"
            readonly
            label="Account Type"
            v-model="accountTypeName"
            @keydown="handleKeyDown"
            :rules="requiredRule()"
            variant="outlined"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn class="mb-5" color="button_color" @click="addAccount">
          Add {{ this.thirdPartyType }} Account
        </v-btn>
      </v-row>
    </v-form>

    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    >
    </Success_Modal>

    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    >
    </Error_Modal>

    <Account_Type_List_Modal_V01
      v-if="this.selectedCurrency != null"
      :display="false"
      :currency-symbol="selectedCurrency.symbol"
    >
    </Account_Type_List_Modal_V01>
  </v-container>
</template>

<script>
import { requiredRule } from '@/utils/rules';
import {
  formatNumber,
  handleKeyDown,
  unifiedResponseHandler,
} from '@/utils/helpers';
import accountService from '@/services/account.service';
import { mapGetters } from 'vuex';
import CurrencyService from '@/services/currency.service';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Account_Type_List_Modal_V01 from '@/components/Modals/Account_Type_List_Modal_V01.vue';
import account_typeService from '@/services/account_type.service.js';
import emitter from '@/plugins/mitt.js';

export default {
  name: 'SimpleNewAccountFormModal',
  components: { Error_Modal, Success_Modal, Account_Type_List_Modal_V01 },
  props: ['chosen_third_party'],
  data() {
    return {
      selectedCurrency: null,
      currencies: [],
      message: null,
      error: false,
      success: false,
      accountType: null,
      loading: false,
      accountCofferName: null,
    };
  },
  methods: {
    formatNumber,
    handleKeyDown,
    requiredRule() {
      return requiredRule;
    },
    async addAccount() {
      let { valid } = await this.$refs.add_account_form.validate();
      if (!valid) return;

      let form = {
        thirdPartyId: this.chosen_third_party.id,
        cofferName: this.accountCofferName,
        currencyId: this.selectedCurrency.id,
        accountTypeId: this.accountTypeId,
      };

      let response = unifiedResponseHandler(
        await accountService.addAccount(form)
      );
      this.message = response.message;

      if (response.success) {
        this.success = true;
        emitter.emit('successAccountOperation', response.data);
      } else {
        this.error = true;
      }
    },
    async getAccountTypesByDescription(description) {
      let updatedDescription = description;
      if (updatedDescription === 'SAFE_BOX') updatedDescription = 'safeBox';
      let response = unifiedResponseHandler(
        await account_typeService.findAccountTypeByDescription(
          updatedDescription
        )
      );
      if (response.success) {
        this.accountType = response.data;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async getCurrencies() {
      let response = unifiedResponseHandler(
        await CurrencyService.getCurrencies()
      );
      if (response.success) {
        this.currencies = response.data;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },
  async mounted() {
    await this.getCurrencies();
    await this.getAccountTypesByDescription(
      this.chosen_third_party.thirdPartyType
    );
  },
  computed: {
    ...mapGetters(['chosen_third_party']),
    accountTypeName() {
      if (this.accountType) {
        return 'CASH ' + this.accountType.generalLedger.code;
      }
      return null;
    },
    accountTypeId() {
      if (this.accountType) {
        return this.accountType.id;
      }
      return null;
    },
    thirdPartyType() {
      switch (this.chosen_third_party.thirdPartyType) {
        case 'CUSTOMER':
          return 'Customer';
        case 'COST_CENTER':
          return 'Cost Center';
        case 'INCOME_CENTER':
          return 'Income Center';
        case 'SAFE_BOX':
          return 'Safe Box';
        default:
          return null;
      }
    },
    isCostCenterOrIncomeCenter() {
      if (!this.chosen_third_party) {
        return false;
      } else {
        return (
          this.chosen_third_party.thirdPartyType === 'INCOME_CENTER' ||
          this.chosen_third_party.thirdPartyType === 'COST_CENTER'
        );
      }
    },
  },
};
</script>
