<template>
  <div class="bg-login">
    <v-sheet
      elevation="24"
      width="380"
      :height="sheetWidth"
      class="mx-auto"
      style="margin-top: 100px; border-radius: 5px"
    >
      <login-form
        v-if="this.state === 'login-form'"
        @loginSuccess="handleLoginSuccess"
        @forgetPasswordClicked="handleForgetPasswordClicked"
      />

      <verify-otp
        v-if="this.state === 'verify-otp'"
        :email="email"
        :password="password"
        @optVerified="handleVerifiedOtp"
      />

      <verify-email-form
        v-if="this.state === 'verify-email'"
        @otpSent="goToChangePasswordFormEmail"
      />

      <change-password-form
        v-if="this.state === 'change-password-form'"
        :userEmail="email"
        @passwordChanged="handlePasswordChanged"
      />
    </v-sheet>
  </div>
</template>
<script>
import { requiredRule } from '@/utils/rules.js';
import VerifyOtp from '@/components/Login/verify-otp.vue';
import VerifyEmailForm from '@/components/Login/verify-email-form.vue';
import ChangePasswordForm from '@/components/Login/change-password-form.vue';
import LoginForm from '@/components/Login/login-form.vue';

export default {
  name: 'login_v01',
  components: { LoginForm, ChangePasswordForm, VerifyEmailForm, VerifyOtp },
  data() {
    return {
      state: 'login-form',
      email: null,
      password: null,
      showPassword: false,
      message: null,
      passwordChange: false,
      sheetWidth: 350,
    };
  },
  methods: {
    requiredRule() {
      return requiredRule;
    },
    handleVerifiedOtp() {
      this.$router.push('/dashboard_v01');
    },
    handleLoginSuccess(args) {
      this.email = args.email;
      this.password = args.password;
      this.sheetWidth = 250;
      this.state = 'verify-otp';
    },
    goToChangePasswordFormEmail(args) {
      this.passwordChange = true;
      this.email = args;
      this.sheetWidth = 420;
      this.state = 'change-password-form';
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async handlePasswordChanged() {
      await this.sleep(3000);
      this.sheetWidth = 350;
      this.state = 'login-form';
    },

    handleForgetPasswordClicked() {
      this.sheetWidth = 280;
      this.state = 'verify-email';
    },
  },
};
</script>
<style scoped>
.bg-login {
  position: absolute;
  background-image: url('../assets/img/green.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
