<template>
  <v-container fluid>
    <v-form ref="edit_product_form" class="mt-4">
      <v-row>
        <v-col v-if="is_editing_product" md="2">
          <v-text-field
            v-model="product.id"
            label="ID"
            readonly
            variant="outlined"
            :rules="requiredRule()"
          ></v-text-field>
        </v-col>

        <v-col :md="is_editing_product ? 5 : 6">
          <v-text-field
            v-model="product.productCode"
            :rules="requiredRule()"
            clearable
            label="Product Code"
          >
          </v-text-field>
        </v-col>

        <v-col :md="is_editing_product ? 5 : 6">
          <v-text-field
            v-model="product.hscode"
            clearable
            label="HS code"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3">
          <v-text-field
            v-model="product.name"
            :rules="requiredRule()"
            clearable
            label="Product Name"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model="quantityInput"
            v-maska="moneyOption"
            clearable
            label="Quantity"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model="costPriceInput"
            clearable
            label="Cost Price"
            v-maska="moneyOption"
          >
          </v-text-field>
        </v-col>

        <v-col md="3">
          <v-text-field
            v-model="sellPriceInput"
            clearable
            label="Sell Price"
            v-maska="moneyOption"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="20">
          <v-text-field
            v-model="product.description"
            clearable
            label="Description"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn class="mr-4" color="button_color" @click="addOrUpdateProduct">
            {{ this.buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { extractNumber, unifiedResponseHandler } from '@/utils/helpers.js';
import { MoneyOption } from '@/utils/maska-options.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import { mapGetters } from 'vuex';
import { requiredRule } from '@/utils/rules.js';
import productService from '@/services/product.service.js';

export default {
  components: { Success_Modal, Error_Modal },
  props: ['productId'],

  data() {
    return {
      message: '',
      success: false,
      error: false,
      sellPriceInput: null,
      costPriceInput: null,
      quantityInput: null,
      product: {
        id: null,
        name: null,
        hscode: null,
        productCode: null,
        quantity: null,
        costPrice: null,
        sellPrice: null,
        description: null,
      },
    };
  },
  methods: {
    requiredRule() {
      return requiredRule;
    },
    addOrUpdateProduct() {
      this.product.sellPrice = extractNumber(this.sellPriceInput);
      this.product.costPrice = extractNumber(this.costPriceInput);
      this.product.quantity = extractNumber(this.quantityInput);
      if (this.is_editing_product) {
        this.editProduct();
      } else {
        this.addProduct();
      }
    },
    async editProduct() {
      if (await this.$refs.edit_product_form.validate()) {
        this.loading = true;
        let response = unifiedResponseHandler(
          await productService.editProduct(this.product)
        );
        if (response.success) {
          this.message = 'Product has been edited successfully';
          this.loading = false;
          this.success = true;
        } else {
          this.message = response.message;
          this.loading = false;
          this.error = true;
        }
      }
    },
    async addProduct() {
      if (await this.$refs.edit_product_form.validate()) {
        if (this.product.name !== null)
          this.product.name = this.product.name.trim();
        let response = unifiedResponseHandler(
          await productService.addProduct(this.product)
        );
        if (response.success) {
          this.message = 'Product has been added successfully';
          this.product = response.data;
          this.$store.dispatch('setIsEditingProduct', true);
          this.success = true;
        } else {
          this.message = response.message;
          this.error = true;
        }
      }
    },
  },
  computed: {
    moneyOption() {
      return MoneyOption(null);
    },
    ...mapGetters(['chosen_product', 'is_editing_product']),
    buttonText() {
      return this.is_editing_product ? 'Update Product' : 'Create Product';
    },
  },
  async mounted() {
    if (this.is_editing_product) {
      this.product = this.chosen_product;
      this.costPriceInput = this.product.costPrice;
      this.sellPriceInput = this.product.sellPrice;
      this.quantityInput = this.product.quantity;
    }
  },
};
</script>

<style scoped></style>
