<template>
  <div>
    <v-container fluid>
      <v-tabs
        fixed-tabs
        bg-color=""
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        class="mb-5"
      >
        <v-tab @click="step = 'info'"> third party info</v-tab>
        <v-tab v-if="is_editing_third_party" @click="step = 'contacts'">
          {{ $t('contacts') }}</v-tab
        >
        <v-tab v-if="is_editing_third_party" @click="step = 'files'">
          linked files</v-tab
        >
        <v-tab v-if="is_editing_third_party" @click="step = 'account'">
          accounts
        </v-tab>
      </v-tabs>

      <ThirdPartyInfoForm v-if="step === 'info'" @addThirdParty="addThirdParty">
      </ThirdPartyInfoForm>

      <ThirdPartyContactsForm v-if="step === 'contacts'">
      </ThirdPartyContactsForm>

      <ThirdPartyFiles v-if="step === 'files'"> </ThirdPartyFiles>

      <AccountsTable v-if="step === 'account'"></AccountsTable>
    </v-container>

    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>

    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>

    <v-dialog v-model="individualListModal" width="auto">
      <Individual_List_Modal_V01></Individual_List_Modal_V01>
    </v-dialog>
    <v-dialog v-model="accountTypeListModal" width="auto">
      <Account_Type_List_Modal_V01
        :thirdPartyType="this.chosen_third_party.thirdPartyType"
      ></Account_Type_List_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import ThirdPartyService from '@/services/third_party.service';
import Account_Type_List_Modal_V01 from '../../Modals/Account_Type_List_Modal_V01.vue';
import { handleKeyDown, unifiedResponseHandler } from '@/utils/helpers';
import {
  emailNotRequiredRule,
  emailRule,
  lengthRule,
  numericRule,
  requiredRule,
} from '@/utils/rules.js';
import Individual_List_Modal_V01 from '../../Modals/Individual_List_Modal_V01.vue';
import { mapGetters } from 'vuex';
import '@vuepic/vue-datepicker/dist/main.css';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import ThirdPartyInfoForm from '@/components/HomeManagement/ThirdParty/third-party-info-form.vue';
import ThirdPartyContactsForm from '@/components/HomeManagement/ThirdParty/third-party-contacts-form.vue';
import ThirdPartyFiles from '@/components/HomeManagement/ThirdParty/third-party-files.vue';
import AccountsTable from '@/components/HomeManagement/ThirdParty/accounts-list.vue';

export default {
  name: 'new_third_party_v01',
  components: {
    AccountsTable,
    ThirdPartyFiles,
    ThirdPartyContactsForm,
    ThirdPartyInfoForm,
    Error_Modal,
    Success_Modal,
    Individual_List_Modal_V01,
    Account_Type_List_Modal_V01,
  },
  data() {
    return {
      Id: null,
      selectedType: null,
      currencies: [],
      step: 'info',
      success: false,
      error: false,
      emailNotRequiredRule: emailNotRequiredRule,
      message: null,
      emailRule: emailRule,
      requiredRule: requiredRule,
      individualListModal: false,
      accountTypeListModal: false,
      thirdPartyTypes: [
        { name: 'CUSTOMER', id: 0 },
        { name: 'TRUSTEE', id: 1 },
        { name: 'PAYER/PAYEE', id: 2 },
      ],
      lengthRule: lengthRule,
      numericRule: numericRule,
    };
  },
  methods: {
    handleKeyDown(event) {
      handleKeyDown(event);
    },

    async addThirdParty(form) {
      let response = null;
      if (!this.is_editing_third_party) {
        response = unifiedResponseHandler(
          await ThirdPartyService.addThirdParty(form)
        );
      } else {
        response = unifiedResponseHandler(
          await ThirdPartyService.editThirdParty(form)
        );
      }
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.$store.dispatch('setChosenThirdParty', response.data);
        this.$store.dispatch('setIsEditingThirdParty', true);
        this.selectedType = response.data.thirdPartyType;
      } else {
        this.error = true;
      }
    },
  },
  async mounted() {
    if (this.is_editing_third_party) {
      this.selectedType = this.chosen_third_party.thirdPartyType;
    }
    console.log(this.selectedType);
  },

  watch: {
    step() {
      this.message = '';
    },

    error(newValue) {
      if (!newValue) {
        this.message = '';
      }
    },
    success(newValue) {
      if (!newValue) {
        this.message = '';
      }
    },
  },

  computed: {
    ...mapGetters(['chosen_third_party', 'is_editing_third_party']),
  },
};
</script>
