import axios from 'axios';
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';
import FileSaver from 'file-saver';

class TransactionService {
  async getTransactions(page, size, searchField, numericalSearchField) {
    try {
      const response = await axios.get(BASE_URL + 'transaction/getAll', {
        params: {
          page: page,
          size: size,
          accountCofferName: searchField,
          numericalSearchField: numericalSearchField,
        },
        headers: authHeader(),
      });
      return { status: response.status, data: response.data, message: null };
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getTransactionsForEvent(eventId) {
    try {
      return await axios.get(BASE_URL + 'transaction/findByEventId', {
        params: {
          eventId: eventId,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getTransactionsEntries(
    page,
    size,
    thirdPartyType,
    accountCofferName,
    numericalSearchField
  ) {
    try {
      const response = await axios.get(BASE_URL + 'transaction/getAll', {
        params: {
          page: page,
          size: size,
          thirdPartyType: thirdPartyType,
          accountCofferName: accountCofferName,
          numericalSearchField: numericalSearchField,
        },
        headers: authHeader(),
      });
      return { status: response.status, data: response.data, message: null };
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getSOAReport(startTime, endTime, currencyId, thirdPartyId) {
    let startT = encodeURIComponent(startTime);
    let endT = encodeURIComponent(endTime);
    try {
      const response = await axios.get(BASE_URL + 'transaction/getSOAReport', {
        params: {
          startTime: startT,
          endTime: endT,
          currencyId: currencyId,
          thirdPartyId: thirdPartyId,
        },
        headers: authHeader(),
        responseType: 'blob',
      });
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      FileSaver.saveAs(blob, 'transaction_report.xlsx');
      return {};
    } catch (error) {
      console.error('Error fetching transactions:', error);
      return { status: error.response.status, data: error.response.data };
    }
  }

  async downloadSOAReport(url, params) {
    try {
      const response = await axios.get(BASE_URL + url, {
        params: params,
        headers: authHeader(),
        responseType: 'blob',
      });
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      FileSaver.saveAs(blob, 'transaction_report.xlsx');
      return {};
    } catch (error) {
      console.error('Error fetching transactions:', error);
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getCommissionReport(startTime, endTime, thirdPartyId, reportType) {
    try {
      const reportTitle =
        reportType === '6203'
          ? 'Trustee 6203'
          : reportType === '4018'
            ? 'Customer 4018'
            : 'Total 6203-4018';
      const nameOfFile = reportTitle + '.xlsx';
      const response = await axios.get(BASE_URL + 'reports/commissionReport', {
        params: {
          startTime: startTime,
          endTime: endTime,
          thirdPartyId: thirdPartyId,
        },
        headers: authHeader(),
        responseType: 'blob',
      });
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      FileSaver.saveAs(blob, nameOfFile);
      return {};
    } catch (error) {
      console.error('Error fetching commission report:', error);
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getTransactionTypes() {
    try {
      return await axios.get(BASE_URL + 'transaction/transactionTypes', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getTransactionTypeConfigs() {
    try {
      return await axios.get(BASE_URL + 'transaction/transactionTypeConfigs', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async addTransactionType(form) {
    try {
      return await axios.post(
        BASE_URL + 'transaction/addTransactionType',
        form,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async addTransactionTypeConfig(form) {
    try {
      return await axios.post(
        BASE_URL + 'transaction/addTransactionTypeConfig',
        form,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async editTransactionType(new_transaction_type) {
    try {
      return await axios.post(
        BASE_URL + 'transaction/transactionType/edit',
        new_transaction_type,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async editTransactionTypeConfig(new_transaction_type_config) {
    try {
      return await axios.post(
        BASE_URL + 'transaction/transactionTypeConfig/edit',
        new_transaction_type_config,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteTransactionType(id) {
    try {
      return await axios.delete(
        BASE_URL + 'transaction/transactionType/delete',
        {
          headers: authHeader(),
          params: {
            transactionTypeId: id,
          },
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteTransactionTypeConfig(id) {
    try {
      return await axios.delete(
        BASE_URL + 'transaction/transactionTypeConfig/delete',
        {
          headers: authHeader(),
          params: {
            transactionTypeConfigId: id,
          },
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async filterTableByEventId(id) {
    try {
      return await axios.get(BASE_URL + 'transaction/findByEventId', {
        params: {
          eventId: id,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new TransactionService();
