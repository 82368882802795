<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="152.000000pt"
    height="148.000000pt"
    viewBox="0 0 152.000000 148.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <metadata>
      Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g
      transform="translate(0.000000,148.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M150 1385 l0 -95 28 0 27 0 -3 -100 -4 -100 131 0 131 0 0 -95 0 -94
-50 -47 -50 -47 0 -181 0 -181 60 -168 c45 -127 60 -182 60 -223 l0 -54 180 0
180 0 0 189 0 190 -35 77 c-19 42 -35 78 -35 80 0 2 65 4 145 4 l145 0 0 275
0 275 131 0 131 0 -4 100 -3 100 28 0 27 0 0 95 0 95 -610 0 -610 0 0 -95z
m1170 8 l0 -53 -560 0 -560 0 0 53 0 52 560 0 560 0 0 -52z m-50 -178 l0 -75
-105 0 -105 0 0 25 c0 21 5 25 28 25 22 0 27 4 27 25 l0 25 -355 0 -355 0 0
-25 c0 -21 5 -25 28 -25 22 0 27 -4 27 -25 l0 -25 -105 0 -105 0 0 75 0 75
510 0 510 0 0 -75z m-710 -200 l0 -175 26 0 26 0 -4 120 c-1 66 -2 120 0 120
2 0 20 -12 39 -27 32 -25 45 -28 113 -28 68 0 81 3 113 28 19 15 37 27 39 27
2 0 1 -54 0 -120 l-4 -120 26 0 26 0 0 175 0 175 28 0 27 0 0 -305 0 -305
-127 0 -128 0 0 65 c0 83 -25 115 -89 115 -22 0 -43 -4 -46 -10 -12 -20 -25
-9 -19 15 5 22 3 25 -20 25 -26 0 -26 -1 -26 -75 0 -43 4 -75 10 -75 6 0 10
-13 10 -30 0 -28 -2 -30 -37 -30 l-38 0 0 305 0 305 28 0 27 0 0 -175z m350
164 c0 -22 -33 -66 -67 -89 -68 -47 -173 -23 -215 48 -10 17 -18 35 -18 41 0
7 48 11 150 11 102 0 150 -4 150 -11z m-390 -639 l60 0 0 -55 c0 -52 1 -55 25
-55 l25 0 0 134 c0 144 5 158 50 146 24 -5 25 -11 31 -90 5 -69 12 -100 42
-164 36 -77 37 -81 37 -202 l0 -124 -133 0 -134 0 -61 173 -62 172 0 149 c0
150 0 150 28 179 l27 28 3 -145 3 -146 59 0z m270 -485 l0 -25 -130 0 -130 0
0 25 0 25 130 0 130 0 0 -25z"
      />
      <path
        d="M300 1215 c0 -21 5 -25 28 -25 22 0 27 4 27 25 0 21 -5 25 -27 25
-23 0 -28 -4 -28 -25z"
      />
      <path
        d="M1165 1215 c0 -21 5 -25 28 -25 22 0 27 4 27 25 0 21 -5 25 -27 25
-23 0 -28 -4 -28 -25z"
      />
      <path
        d="M722 953 c-7 -3 -19 -18 -28 -34 -12 -25 -13 -34 -2 -57 7 -15 26
-33 41 -40 35 -17 76 1 95 40 11 23 10 32 -2 56 -20 39 -61 53 -104 35z m56
-45 c16 -16 15 -35 -3 -42 -20 -7 -45 3 -45 18 0 13 20 36 30 36 3 0 11 -5 18
-12z"
      />
      <path
        d="M910 734 l0 -57 -50 6 c-48 6 -50 5 -50 -18 0 -24 2 -25 75 -25 l75
0 0 75 c0 73 -1 75 -25 75 -24 0 -25 -3 -25 -56z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'safeBoxWithdraw',
};
</script>
