<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h5>{{ formatHeader(this.page) }}</h5>
      <v-form class="mt-11" ref="third_crypto_form">
        <h6 class="ml-2 mb-6">Customer Deal</h6>
        <v-row id="customer-deal">
          <v-col md="3" sm="12" xs="12">
            <v-tab
              @click="thirdPartyListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              label="Customer Name"
              v-model="thirdPartyFullName"
              @keyup.backspace="clearThirdParty"
              @keydown="handleKeyDown"
              :rules="requiredRule"
            ></v-text-field>

            <v-text-field
              v-maska="exchangeRateOption"
              variant="outlined"
              :readonly="true"
              class="mt-25px"
              label="Conversion Rate"
              v-model="conversionRate"
              :disabled="!conversionRate"
            ></v-text-field>
            <v-tab
              @click="
                ourReceivingAccountModal = true;
                currencyIdPropOfListOfAccountsModal = receiveCurrencyId;
              "
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              class="custom-text-field"
              label="Our Receiving Acc"
              v-model="ourReceivingAccountFullName"
              :rules="requiredRule"
            ></v-text-field>
            <v-tab
              @click="
                customerPrimaryReceivingAccountModal = true;
                currencyIdPropOfListOfAccountsModal = sentCurrencyId;
              "
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              class="custom-text-field"
              label="Customer Primary Receiving Acc"
              v-model="customerPrimaryReceivingAccountFullName"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col md="3" sm="12" xs="12" class="mt-25px">
            <v-autocomplete
              label="Receive Currency"
              v-model="receiveCurrency"
              :items="currencies"
              item-title="name"
              item-value="name"
              filled
              return-object
              :rules="requiredRule"
              @keydown.backspace="
                this.receiveCurrency = null;
                console.log('Hello');
              "
            ></v-autocomplete>
            <v-autocomplete
              label="Sent Currency"
              v-model="sentCurrency"
              :items="currencies"
              item-title="name"
              item-value="name"
              filled
              return-object
              :rules="requiredRule"
              class="mt-25px"
            ></v-autocomplete>
            <v-tab
              @click="
                customerSendingAccountModal = true;
                currencyIdPropOfListOfAccountsModal = receiveCurrencyId;
              "
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              class="custom-text-field"
              label="Customer Sending Acc"
              v-model="customerSendingAccountCofferName"
              :rules="requiredRule"
            ></v-text-field>
            <v-tab
              @click="
                customerSecondaryReceivingAccountModal = true;
                currencyIdPropOfListOfAccountsModal = sentCurrencyId;
              "
              prepend-icon="mdi-magnify"
              color="button_color"
              :disabled="!secondarySentAmount || secondarySentAmount === ''"
              >select
            </v-tab>
            <v-text-field
              class="custom-text-field"
              label="Customer Secondary Receiving Acc"
              v-model="customerSecondaryReceivingAccountFullName"
              :disabled="!secondarySentAmount || secondarySentAmount === ''"
            ></v-text-field>
          </v-col>
          <v-col md="3" sm="12" xs="12" class="mt-25px">
            <v-text-field
              v-maska="moneyOption"
              label="Receive Amount"
              v-model="receiveAmount"
              :rules="requiredRule"
            ></v-text-field>

            <v-text-field
              v-maska="moneyOption2"
              label="Primary Sent Amount"
              v-model="primarySentAmount"
              :rules="requiredRule"
              class="mt-25px"
            >
            </v-text-field>
            <v-tab
              @click="
                ourPrimaryOutgoingAccountModal = true;
                currencyIdPropOfListOfAccountsModal = sentCurrencyId;
              "
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              class="custom-text-field"
              label="Our Primary Outgoing Acc"
              v-model="ourPrimaryOutgoingAccountCofferName"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col md="3" sm="12" xs="12">
            <label
              v-if="page === 'PastGroupDeal'"
              for="date-picker"
              style="color: black"
              class="mt-25px"
              >Date</label
            >
            <VueDatePicker
              v-if="page === 'PastGroupDeal'"
              auto-apply="true"
              id="date-picker"
              v-model="date"
              :show-timepicker="false"
              @input="validateDate"
            ></VueDatePicker>
            <v-text-field
              :class="
                page === 'PastGroupDeal'
                  ? 'custom-top-margin-40'
                  : 'custom-top-margin'
              "
              v-maska="moneyOption2"
              label="Secondary Sent Amount"
              v-model="secondarySentAmount"
              @keydown="removeSecondaryAccounts"
            >
            </v-text-field>
            <v-tab
              @click="
                ourSecondaryOutgoingAccountModal = true;
                currencyIdPropOfListOfAccountsModal = sentCurrencyId;
              "
              prepend-icon="mdi-magnify"
              color="button_color"
              :disabled="!secondarySentAmount || secondarySentAmount === ''"
              >select
            </v-tab>
            <v-text-field
              class="custom-text-field"
              label="Our Secondary Outgoing Acc"
              v-model="ourSecondaryOutgoingAccountCofferName"
              :rules="
                !(!secondarySentAmount || secondarySentAmount === '')
                  ? requiredRule
                  : []
              "
              :disabled="!secondarySentAmount || secondarySentAmount === ''"
            ></v-text-field>
          </v-col>
        </v-row>
        <p style="border: 0.2px solid #f7f5f5"></p>
        <h6 class="ml-2 mb-6">Conversion</h6>
        <v-row id="conversion">
          <v-col cols="12" md="2" sm="12" xs="12">
            <v-tab
              @click="
                trusteeIncomingAccountModal = true;
                currencyIdPropOfListOfAccountsModal = TETCurrencyId;
                thirdPartyTypePropOfListOfAccountsModal = 'TRUSTEE';
              "
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              class="custom-text-field"
              label="TET Incoming Acc"
              v-model="trusteeTETIncomingAccountName"
              :rules="requiredRule"
            ></v-text-field>
            <v-tab
              @click="
                trusteeOutgoingAccountModal = true;
                currencyIdPropOfListOfAccountsModal = TETCurrencyId;
                thirdPartyTypePropOfListOfAccountsModal = 'TRUSTEE';
              "
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              class="custom-text-field"
              label="TET Outgoing Acc"
              v-model="trusteeTETOutgoingAccountName"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="12" xs="12">
            <v-text-field
              class="mt-25px"
              v-maska="moneyOptionTET"
              label="TET Buy Amount"
              v-model="TETAmount"
              variant="outlined"
              :disabled="!TETAmount"
            ></v-text-field>
            <v-text-field
              class="mt-25px"
              label="TET Sold Amount"
              v-model="TETSoldAmount"
              v-maska="moneyOptionTET"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="12" xs="12">
            <v-text-field
              v-maska="exchangeRateOption"
              class="mt-25px"
              label="TET Buy Rate"
              v-model="TETBuyRate"
              :rules="requiredRule"
            ></v-text-field>
            <v-text-field
              class="mt-25px"
              v-maska="exchangeRateOption"
              label="TET Sell Rate"
              v-model="TETSellRateDisplay"
              variant="outlined"
              :readonly="true"
              :disabled="!TETSellRate"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="12" xs="12">
            <v-text-field
              class="mt-25px"
              :readonly="true"
              label="Receive Currency"
              v-model="getReceiveCurrencySymbol"
              variant="outlined"
              :disabled="!getReceiveCurrencySymbol"
            ></v-text-field>
            <v-text-field
              class="mt-25px"
              :readonly="true"
              label="Sent Currency"
              v-model="getSentCurrencySymbol"
              variant="outlined"
              :disabled="!getSentCurrencySymbol"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="12" xs="12">
            <v-text-field
              class="mt-25px"
              :readonly="true"
              label="Receive Amount"
              v-model="receiveAmount"
              variant="outlined"
              :disabled="!receiveAmount"
            >
            </v-text-field>
            <v-text-field
              v-maska="moneyOption2"
              class="mt-25px"
              :readonly="true"
              label="Total Sent Amount"
              v-model="totalSentAmount"
              variant="outlined"
              :disabled="!totalSentAmount"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-card
          id="AED-to-ITM-stats"
          class="custom-border"
          variant="outlined"
          :disabled="whichDealStat !== 0"
        >
          <h6 class="m-4">Deal Stats: (AED to ITM)</h6>
          <v-row class="ml-1">
            <v-col cols="12" md="2" sm="12" xs="12">
              <v-text-field
                v-maska="exchangeRateOption"
                :readonly="true"
                label="TET Buy Rate"
                v-model="TETBuyRate"
                variant="outlined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="12" xs="12">
              <v-text-field
                v-maska="exchangeRateOption"
                variant="outlined"
                :readonly="true"
                label="Sell Rate"
                v-model="sellRateAEDToITM"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="12" xs="12">
              <v-text-field
                :readonly="true"
                label="Into our Wallet"
                v-model="IntoOurWallet"
                variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          id="ITM-to-AED-stats"
          class="custom-border mt-4"
          variant="outlined"
          :disabled="whichDealStat !== 1"
        >
          <h6 class="m-4">Deal Stats: (ITM to AED)</h6>
          <v-row class="ml-1">
            <v-col cols="12" md="2" sm="12" xs="12">
              <v-text-field
                :readonly="true"
                label="Buy Rate"
                v-model="buyRateITMToAED"
                variant="outlined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="12" xs="12">
              <v-text-field
                :readonly="true"
                label="Sell Rate"
                v-model="TETSellRate"
                variant="outlined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="12" xs="12">
              <v-text-field
                label="Into our Wallet"
                v-model="IntoOurWallet"
                variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-row class="ma-4" justify="end">
          <v-btn class="mr-1" color="button_color" @click="registerGroupDeal">
            register
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
  </div>
  <v-dialog v-model="thirdPartyListModal" width="auto">
    <third_party_list_modal
      third-party-type="CUSTOMER"
    ></third_party_list_modal>
  </v-dialog>
  <v-dialog
    v-model="openListOfAccountsModal"
    width="auto"
    @click:outside="
      thirdPartyTypePropOfListOfAccountsModal = null;
      crushModal();
    "
  >
    <list_of_accounts_modal_v01
      :third-party-type="thirdPartyTypePropOfListOfAccountsModal"
      :currency-id="currencyIdPropOfListOfAccountsModal"
    >
    </list_of_accounts_modal_v01>
  </v-dialog>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import list_of_accounts_modal_v01 from '../../Modals/List_Of_Accounts_Modal_V01.vue';
import Broken_Page_V01 from '../../Broken_Page_V01.vue';
import third_party_list_modal from '../../Modals/Third_Party_List_Modal_V01.vue';
import emitter from '@/plugins/mitt';
import { ExchangeRateOption, MoneyOption } from '@/utils/maska-options.js';
import currencyService from '@/services/currency.service';
import {
  extractNumber,
  formatCurrencyInfo,
  formatNumberInternal,
  formatNumber,
  handleKeyDown,
  unifiedResponseHandler,
} from '@/utils/helpers';
import { requiredRule } from '@/utils/rules';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import GroupDealService from '@/services/groupDeal.service';
import { setPage } from '@/utils/helpers';

export default {
  name: 'group_deal_v01',
  props: {
    page: String,
  },
  components: {
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    third_party_list_modal,
    list_of_accounts_modal_v01,
  },
  data() {
    return {
      message: null,
      broken_page: false,
      success: false,
      error: false,
      thirdPartyListModal: false,
      thirdParty: null,
      receiveAmount: null,
      currencies: [],
      receiveCurrency: null,
      sentCurrency: null,
      exchangeRateOption: ExchangeRateOption,
      requiredRule: requiredRule,
      exchangeRate: null,
      primarySentAmount: null,
      secondarySentAmount: null,
      ourReceivingAccount: null,
      ourReceivingAccountModal: false,
      customerSendingAccountModal: false,
      ourPrimaryOutgoingAccountModal: false,
      ourSecondaryOutgoingAccountModal: false,
      customerSecondaryReceivingAccountModal: false,
      customerPrimaryReceivingAccountModal: false,
      trusteeOutgoingAccountModal: false,
      trusteeIncomingAccountModal: false,
      ourPrimaryOutgoingAccount: null,
      ourSecondaryOutgoingAccount: null,
      customerSendingAccount: null,
      customerSecondaryReceivingAccount: null,
      customerPrimaryReceivingAccount: null,
      TETSoldAmount: null,
      TETBuyRate: null,
      TETSellRateDisplay: null,
      TETAmount: null,
      trusteeTETIncomingAccount: null,
      trusteeTETOutgoingAccount: null,
      thirdPartyTypePropOfListOfAccountsModal: null,
      currencyIdPropOfListOfAccountsModal: null,
      date: null,
    };
  },
  methods: {
    handleKeyDown,
    formatHeader(header) {
      if (header === 'GroupDeal') {
        return 'Group Deal';
      } else if (header === 'PastGroupDeal') {
        return 'Past Group Deal';
      } else {
        return null;
      }
    },
    async getCurrencies() {
      let response = unifiedResponseHandler(
        await currencyService.getCurrencies()
      );
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    clearThirdParty() {
      this.thirdParty = null;
    },
    async registerGroupDeal() {
      let { valid } = await this.$refs.third_crypto_form.validate();
      if (this.page === 'GroupDeal') {
        if (valid) {
          let form = {
            customerId: this.thirdPartyId,
            conversionRate: this.conversionRate,
            receiveCurrencyId: this.receiveCurrencyId,
            sendCurrencyId: this.sentCurrencyId,
            receiveAmount: extractNumber(this.receiveAmount),
            primarySendAmount: extractNumber(this.primarySentAmount),
            secondarySendAmount: extractNumber(this.secondarySentAmount),
            ourReceivingAccountId: this.ourReceivingAccountId,
            customerSendingAccountId: this.customerSendingAccount.id,
            ourPrimarySendingAccountId: this.ourPrimaryOutgoingAccountId,
            ourSecondarySendingAccountId: this.ourSecondaryOutgoingAccountId,
            primarySendingCustomerAccountId: this.customerSendingAccountId,
            customerSecondaryReceivingAccountId:
              this.customerSecondaryReceivingAccountId,
            customerPrimaryReceivingAccountId:
              this.customerPrimaryReceivingAccountId,
            trusteeTETOutgoingAccountId: this.trusteeOutgoingAccountId,
            trusteeTETIncomingAccountId: this.trusteeIncomingAccountId,
            tetSoldAmount: extractNumber(this.TETSoldAmount),
            tetBuyRate: extractNumber(this.TETBuyRate),
            createDate: this.date,
          };
          let response = unifiedResponseHandler(
            await GroupDealService.GroupDealForm(form)
          );
          if (response.success) {
            console.log('group deal', form);
            this.success = true;
            this.message = response.message;
            this.clearForm();
            setTimeout(() => {
              setPage('group deal report');
            }, 1000);
          } else {
            this.message = response.message;
            this.error = true;
          }
        }
      } else {
        if (this.date) {
          if (valid) {
            let form = {
              customerId: this.thirdPartyId,
              conversionRate: this.conversionRate,
              receiveCurrencyId: this.receiveCurrencyId,
              sendCurrencyId: this.sentCurrencyId,
              receiveAmount: extractNumber(this.receiveAmount),
              primarySendAmount: extractNumber(this.primarySentAmount),
              secondarySendAmount: extractNumber(this.secondarySentAmount),
              ourReceivingAccountId: this.ourReceivingAccountId,
              customerSendingAccountId: this.customerSendingAccount.id,
              ourPrimarySendingAccountId: this.ourPrimaryOutgoingAccountId,
              ourSecondarySendingAccountId: this.ourSecondaryOutgoingAccountId,
              primarySendingCustomerAccountId: this.customerSendingAccountId,
              customerSecondaryReceivingAccountId:
                this.customerSecondaryReceivingAccountId,
              customerPrimaryReceivingAccountId:
                this.customerPrimaryReceivingAccountId,
              trusteeTETOutgoingAccountId: this.trusteeOutgoingAccountId,
              trusteeTETIncomingAccountId: this.trusteeIncomingAccountId,
              tetSoldAmount: extractNumber(this.TETSoldAmount),
              tetBuyRate: extractNumber(this.TETBuyRate),
              createDate: this.date,
            };
            let response = unifiedResponseHandler(
              await GroupDealService.GroupDealForm(form)
            );
            if (response.success) {
              console.log('group deal', form);
              this.success = true;
              this.message = response.message;
              this.clearForm();
              setTimeout(() => {
                setPage('group deal report');
              }, 1000);
            } else {
              this.message = response.message;
              this.error = true;
            }
          }
        } else {
          this.error = true;
          this.message = 'Date is required';
          console.log('Date is required');
        }
      }
    },
    clearForm() {
      this.thirdParty = null;
      this.conversionRate = null;
      this.receiveCurrency = null;
      this.sentCurrency = null;
      this.receiveAmount = null;
      this.primarySentAmount = null;
      this.ourReceivingAccount = null;
      this.ourPrimaryOutgoingAccount = null;
      this.customerSendingAccount = null;
      this.customerSecondaryReceivingAccount = null;
      this.customerPrimaryReceivingAccount = null;
      this.trusteeTETIncomingAccount = null;
      this.trusteeTETOutgoingAccount = null;
      this.TETSoldAmount = null;
      this.TETBuyRate = null;
    },
    removeSecondaryAccounts(event) {
      if (event.key === 'Backspace') {
        this.customerSecondaryReceivingAccount = null;
        this.ourSecondaryOutgoingAccount = null;
        this.secondarySentAmount = null;
      }
    },
    crushModal() {
      this.ourReceivingAccountModal = false;
      this.customerSendingAccountModal = false;
      this.ourPrimaryOutgoingAccountModal = false;
      this.ourSecondaryOutgoingAccountModal = false;
      this.customerSecondaryReceivingAccountModal = false;
      this.customerPrimaryReceivingAccountModal = false;
      this.trusteeIncomingAccountModal = false;
      this.trusteeOutgoingAccountModal = false;
    },
  },
  computed: {
    openListOfAccountsModal() {
      return (
        this.ourReceivingAccountModal ||
        this.customerSendingAccountModal ||
        this.ourPrimaryOutgoingAccountModal ||
        this.ourSecondaryOutgoingAccountModal ||
        this.customerSecondaryReceivingAccountModal ||
        this.customerPrimaryReceivingAccountModal ||
        this.trusteeIncomingAccountModal ||
        this.trusteeOutgoingAccountModal
      );
    },
    TETCurrencyId() {
      const tetCurrency = this.currencies.find(
        (currency) => currency.symbol === 'TET'
      );
      return tetCurrency ? tetCurrency.id : null;
    },
    IntoOurWallet() {
      if (this.TETAmount && this.TETSoldAmount) {
        return formatNumberInternal(
          extractNumber(this.TETAmount) - extractNumber(this.TETSoldAmount)
        );
      } else {
        return null;
      }
    },
    trusteeTETOutgoingAccountName() {
      return this.trusteeTETOutgoingAccount
        ? this.trusteeTETOutgoingAccount.cofferName
        : null;
    },
    trusteeTETIncomingAccountName() {
      return this.trusteeTETIncomingAccount
        ? this.trusteeTETIncomingAccount.cofferName
        : null;
    },
    trusteeOutgoingAccountId() {
      return this.trusteeTETOutgoingAccount
        ? this.trusteeTETOutgoingAccount.id
        : null;
    },
    trusteeIncomingAccountId() {
      return this.trusteeTETIncomingAccount
        ? this.trusteeTETIncomingAccount.id
        : null;
    },
    customerPrimaryReceivingAccountFullName() {
      return this.customerPrimaryReceivingAccount
        ? this.customerPrimaryReceivingAccount.cofferName
        : null;
    },
    customerPrimaryReceivingAccountId() {
      return this.customerPrimaryReceivingAccount
        ? this.customerPrimaryReceivingAccount.id
        : null;
    },
    customerSecondaryReceivingAccountFullName() {
      return this.customerSecondaryReceivingAccount
        ? this.customerSecondaryReceivingAccount.cofferName
        : null;
    },
    customerSecondaryReceivingAccountId() {
      return this.customerSecondaryReceivingAccount
        ? this.customerSecondaryReceivingAccount.id
        : null;
    },
    ourReceivingAccountFullName() {
      return this.ourReceivingAccount
        ? this.ourReceivingAccount.cofferName
        : null;
    },
    ourReceivingAccountId() {
      return this.ourReceivingAccount ? this.ourReceivingAccount.id : null;
    },
    customerSendingAccountId() {
      return this.customerSendingAccount
        ? this.customerSendingAccount.id
        : null;
    },
    customerSendingAccountCofferName() {
      return this.customerSendingAccount
        ? this.customerSendingAccount.cofferName
        : null;
    },
    ourPrimaryOutgoingAccountCofferName() {
      return this.ourPrimaryOutgoingAccount
        ? this.ourPrimaryOutgoingAccount.cofferName
        : null;
    },
    ourPrimaryOutgoingAccountId() {
      return this.ourPrimaryOutgoingAccount
        ? this.ourPrimaryOutgoingAccount.id
        : null;
    },
    ourSecondaryOutgoingAccountCofferName() {
      return this.ourSecondaryOutgoingAccount
        ? this.ourSecondaryOutgoingAccount.cofferName
        : null;
    },
    ourSecondaryOutgoingAccountId() {
      return this.ourSecondaryOutgoingAccount
        ? this.ourSecondaryOutgoingAccount.id
        : null;
    },
    thirdPartyFullName() {
      return this.thirdParty ? this.thirdParty.fullName : null;
    },
    thirdPartyId() {
      return this.thirdParty ? this.thirdParty.id : null;
    },
    moneyOption() {
      return this.receiveCurrency
        ? MoneyOption(this.receiveCurrency.symbol)
        : MoneyOption(null);
    },
    moneyOption2() {
      return this.sentCurrency
        ? MoneyOption(this.sentCurrency.symbol)
        : MoneyOption(null);
    },
    moneyOptionTET() {
      return MoneyOption('TET');
    },
    sentCurrencyId() {
      return this.sentCurrency ? this.sentCurrency.id : null;
    },
    receiveCurrencyId() {
      return this.receiveCurrency ? this.receiveCurrency.id : null;
    },
    getSentCurrencySymbol() {
      return this.sentCurrency ? this.sentCurrency.symbol : null;
    },
    getReceiveCurrencySymbol() {
      return this.receiveCurrency ? this.receiveCurrency.symbol : null;
    },
    conversionRate() {
      let receiveAmount =
        this.receiveAmount === null || this.receiveAmount === ''
          ? null
          : parseInt(extractNumber(this.receiveAmount));
      let totalSentAmount = this.totalSentAmount;
      if (receiveAmount && totalSentAmount) {
        return receiveAmount > totalSentAmount
          ? receiveAmount / totalSentAmount
          : totalSentAmount / receiveAmount;
      }
      return null;
    },
    whichDealStat() {
      if (this.receiveCurrency && this.sentCurrency) {
        if (
          this.receiveCurrency.symbol === 'AED' &&
          this.sentCurrency.symbol === 'ITM'
        ) {
          return 0;
        } else if (
          this.receiveCurrency.symbol === 'ITM' &&
          this.sentCurrency.symbol === 'AED'
        ) {
          return 1;
        } else return -1;
      }
      return -1;
    },
    sellRateAEDToITM() {
      if (this.TETSellRate && this.conversionRate)
        return extractNumber(this.TETSellRate) / this.conversionRate;
      return null;
    },
    buyRateITMToAED() {
      if (this.primarySentAmount && this.TETAmount)
        return (
          extractNumber(this.primarySentAmount) / extractNumber(this.TETAmount)
        );
      return null;
    },
    TETSellRate() {
      const primarySentAmount =
        this.primarySentAmount !== null && this.primarySentAmount !== ''
          ? parseInt(extractNumber(this.primarySentAmount))
          : 0;
      const secondarySentAmount =
        this.secondarySentAmount !== null && this.secondarySentAmount !== ''
          ? parseInt(extractNumber(this.secondarySentAmount))
          : 0;
      const tetSoldAmount = parseInt(extractNumber(this.TETSoldAmount));
      return (
        (primarySentAmount + secondarySentAmount) /
        tetSoldAmount
      ).toFixed(4);
    },
    totalSentAmount() {
      const primarySentAmount =
        this.primarySentAmount !== null && this.primarySentAmount !== ''
          ? parseInt(extractNumber(this.primarySentAmount))
          : 0;
      const secondarySentAmount =
        this.secondarySentAmount !== null && this.secondarySentAmount !== ''
          ? parseInt(extractNumber(this.secondarySentAmount))
          : 0;
      return primarySentAmount + secondarySentAmount === 0
        ? null
        : (primarySentAmount + secondarySentAmount).toFixed(4);
    },
  },
  async mounted() {
    emitter.on('new_third_party', (value) => {
      if (this.thirdPartyListModal) {
        this.thirdParty = value;
        this.thirdPartyListModal = false;
      }
    });
    emitter.on('chosenAccount', (value) => {
      this.thirdPartyTypePropOfListOfAccountsModal = null;
      switch (true) {
        case this.ourReceivingAccountModal:
          this.ourReceivingAccount = value;
          this.ourReceivingAccountModal = false;
          break;
        case this.ourPrimaryOutgoingAccountModal:
          this.ourPrimaryOutgoingAccount = value;
          this.ourPrimaryOutgoingAccountModal = false;
          break;
        case this.ourSecondaryOutgoingAccountModal:
          this.ourSecondaryOutgoingAccount = value;
          this.ourSecondaryOutgoingAccountModal = false;
          break;
        case this.customerSendingAccountModal:
          this.customerSendingAccount = value;
          this.customerSendingAccountModal = false;
          break;
        case this.customerPrimaryReceivingAccountModal:
          this.customerPrimaryReceivingAccount = value;
          this.customerPrimaryReceivingAccountModal = false;
          break;
        case this.customerSecondaryReceivingAccountModal:
          this.customerSecondaryReceivingAccount = value;
          this.customerSecondaryReceivingAccountModal = false;
          break;
        case this.trusteeOutgoingAccountModal:
          this.trusteeTETOutgoingAccount = value;
          this.trusteeOutgoingAccountModal = false;
          break;
        case this.trusteeIncomingAccountModal:
          this.trusteeTETIncomingAccount = value;
          this.trusteeIncomingAccountModal = false;
          break;
        default:
          break;
      }
    });
    await this.getCurrencies();
  },
  watch: {
    receiveAmount(newVal) {
      if (
        this.receiveAmount &&
        this.TETBuyRate != null &&
        this.TETBuyRate !== 0
      ) {
        this.TETAmount = extractNumber(newVal) / extractNumber(this.TETBuyRate);
      }
    },
    TETBuyRate(newVal) {
      if (
        this.receiveAmount &&
        this.TETBuyRate != null &&
        this.TETBuyRate !== 0
      ) {
        this.TETAmount =
          extractNumber(this.receiveAmount) / extractNumber(newVal);
      }
    },
    primarySentAmount(newVal) {
      if (
        this.primarySentAmount &&
        this.TETSoldAmount != null &&
        this.TETSoldAmount !== 0
      ) {
        this.TETSellRate =
          extractNumber(newVal) / extractNumber(this.TETSoldAmount);
        this.TETSellRateDisplay = formatNumber(this.TETSellRate);
      }
    },
    TETSoldAmount(newVal) {
      if (
        this.TETSoldAmount &&
        this.primarySentAmount != null &&
        this.primarySentAmount !== 0
      ) {
        this.TETSellRate =
          extractNumber(this.primarySentAmount) / extractNumber(newVal);
        this.TETSellRateDisplay = formatNumber(this.TETSellRate);
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep(
  .custom-text-field .v-label.v-field-label:not(.v-field-label--floating)
) {
  font-size: calc(12px + 0.2vw); /* Adjust the font size as needed */
}

.custom-bottom-margin {
  margin-bottom: 20px;
}

.custom-border {
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}

.custom-top-margin {
  margin-top: 128px;
}
.custom-top-margin-40 {
  margin-top: 40px;
}
</style>
