<template>
  <v-card>
    <v-form ref="edit_task_group_form">
      <v-card-text>
        <v-row dense>
          <v-col v-if="isEditingTaskGroup" md="2">
            <v-text-field v-model="id" label="ID" readonly variant="outlined">
            </v-text-field>
          </v-col>
          <v-col :md="isEditingTaskGroup ? 10 : 12">
            <v-text-field
              v-model="title"
              :rules="requiredRule()"
              label="Title"
              color="primaryColor1"
            >
            </v-text-field>
          </v-col>
          <v-col md="12">
            <v-text-field
              v-model="description"
              clearable
              label="Description"
              color="primaryColor1"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="this.disableButton"
        :variant="isEditingTaskGroup ? 'outlined' : 'flat'"
        :rules="requiredRule()"
        class="mr-4"
        color="button_color"
        @click="addOrUpdateTaskGroup"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="closeEntireModal()"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { unifiedResponseHandler } from '@/utils/helpers.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import { requiredRule } from '@/utils/rules.js';
import taskGroupService from '@/services/taskGroup.service.js';

export default {
  name: 'createTaskGroupModal',
  components: { Success_Modal, Error_Modal },
  props: ['taskGroupToEdit'],

  data() {
    return {
      message: '',
      id: null,
      isEditingTaskGroup: false,
      taskGroup: null,
      success: false,
      error: false,
      title: null,
      description: null,
      disableButton: false,
      selectedTaskGroup: null,
    };
  },

  computed: {
    buttonText() {
      return this.isEditingTaskGroup
        ? 'Update Task Group'
        : 'Create Task Group';
    },
  },

  methods: {
    requiredRule() {
      return requiredRule;
    },
    closeEntireModal() {
      this.$emit('closeTaskGroupModal');
    },
    async addOrUpdateTaskGroup() {
      if (this.title === null) {
        this.displayError('Please fill in all required fields');
        return;
      }
      this.isEditingTaskGroup
        ? await this.editTaskGroup()
        : await this.addTaskGroup();
    },

    displayError(message) {
      this.message = message;
      this.error = true;
    },

    buildTaskGroupForm() {
      return {
        id: this.id,
        title: this.title,
        description: this.description,
      };
    },

    async editTaskGroup() {
      if (await this.$refs.edit_task_group_form.validate()) {
        const form = this.buildTaskGroupForm();
        this.disableButton = true;
        const response = unifiedResponseHandler(
          await taskGroupService.editTaskGroup(form)
        );
        response.success
          ? this.handleSuccess('Task Group has been edited successfully')
          : this.displayError(response.message);
        this.disableButton = false;
      }
    },

    async addTaskGroup() {
      if (await this.$refs.edit_task_group_form.validate()) {
        const form = this.buildTaskGroupForm();
        this.disableButton = true;
        const response = unifiedResponseHandler(
          await taskGroupService.addTaskGroup(form)
        );
        if (response.success) {
          this.handleSuccess(
            'Task Group has been added successfully',
            response.data
          );
        } else {
          this.displayError(response.message);
        }
        this.disableButton = false;
      }
    },

    setTaskGroupToEditVariables(newTaskGroup) {
      if (!newTaskGroup) return;
      this.isEditingTaskGroup = true;
      this.taskGroup = newTaskGroup;
      this.id = newTaskGroup.id;
      this.title = newTaskGroup.title;
      this.description = newTaskGroup.description;
    },

    handleSuccess(message, data = null) {
      this.message = message;
      this.success = true;
      if (data) this.setTaskGroupToEditVariables(data);
      this.$emit('successfulTaskGroupOperation');
    },
  },

  async mounted() {
    this.setTaskGroupToEditVariables(this.taskGroupToEdit);
  },
};
</script>

<style scoped></style>
