<template>
  <v-card>
    <v-form ref="new_general_ledger_form">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="12" sm="8" xs="12">
            <v-autocomplete
              v-model="parentAccount"
              label="Parent Account"
              item-title="label"
              item-value="id"
              return-object
              :items="generalLedgers"
              :custom-filter="customFilter"
            >
              <template #selection="{ item }">
                <span>
                  {{ addParentLabelToOneWordLedgers(item) }}
                </span>
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :subtitle="item.raw.code"
                  :title="item.raw.label"
                ></v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col>
            <div class="d-flex justify-space-between">
              <v-text-field
                v-model="parentLedgerCode"
                readonly
                label="Parent Code"
              >
              </v-text-field>
              <v-text-field
                v-model="code"
                :rules="isTwoDigitNumber"
                label="Suffix"
                class="ml-3 mr-3"
                style="flex-basis: 10%"
                :maxlength="2"
              ></v-text-field>
              <v-text-field
                v-model="newLedgerCode"
                variant="outlined"
                readonly
                label="New Ledger Code"
              >
              </v-text-field>
            </div>
            <v-text-field
              v-model="label"
              label="Label"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="flat" color="button_color" @click="addGeneralLedger">
        Add General Ledger
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import general_ledgerService from '@/services/general_ledger.service';
import { unifiedResponseHandler } from '@/utils/helpers';
import {
  requiredRule,
  positiveIntegerRule,
  isTwoDigitNumber,
} from '@/utils/rules.js';

export default {
  name: 'newGeneralLedgerModal',

  data() {
    return {
      message: null,
      requiredRule: requiredRule,
      code: null,
      label: null,
      parentAccount: null,
      accountGroup: null,
      positiveIntegerRule: positiveIntegerRule,
      generalLedgers: [],
      isTwoDigitNumber: isTwoDigitNumber,
      newCodeError: null,
    };
  },
  methods: {
    customFilter(itemTitle, queryText, item) {
      const code = item.raw.code.toLowerCase();
      const label = item.raw.label.toLowerCase();
      const searchText = queryText.toLowerCase();

      return code.includes(searchText) || label.includes(searchText);
    },
    async addGeneralLedger() {
      const { valid } = await this.$refs.new_general_ledger_form.validate();
      if (valid) {
        let form = {
          code: this.newLedgerCode,
          label: this.label,
          parentLedgerId: this.parentAccount.id,
          accountGroup: this.parentAccount?.accountGroup,
        };
        console.log(form);
        let response = unifiedResponseHandler(
          await general_ledgerService.addGeneralLedger(form)
        );
        this.message = response.message;
        if (response.success) {
          this.$emit('ledgerAdded', this.message);
        } else {
          this.$emit('ledgerError', this.message);
        }
      }
    },
    async getGeneralLedgers() {
      const response = unifiedResponseHandler(
        await general_ledgerService.getGeneralLedgers()
      );
      if (response.success) {
        this.generalLedgers = response.data.sort((a, b) =>
          a.code.localeCompare(b.code)
        );
        console.log(this.generalLedgers);
      } else {
        this.broken_page = true;
      }
    },
    addParentLabelToOneWordLedgers(item) {
      if (item.title.split(' ').length === 1)
        return item.raw.parentLedger
          ? item.title + ' (' + item.raw.parentLedger.label + ') '
          : item.title;
      else return item.title;
    },
  },
  async mounted() {
    await this.getGeneralLedgers();
  },
  computed: {
    newLedgerCode() {
      return this.parentAccount && this.code
        ? this.parentAccount.code + this.code
        : null;
    },
    parentLedgerCode() {
      return this.parentAccount ? this.parentAccount.code : null;
    },
  },
};
</script>
