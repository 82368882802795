<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium mb-2">Tasks</h3>

    <v-row>
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field placeholder="Search" v-model="searchValue">
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>
      <v-checkbox
        class="ml-5"
        v-model="createdByMe"
        label="Created By Me"
        @change="getTasks"
        color="button_color"
      ></v-checkbox>
      <v-checkbox
        class="ml-5"
        v-model="assignedToMe"
        label="Assigned To Me"
        @change="getTasks"
        color="button_color"
      ></v-checkbox>
      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="addTask"
          icon="mdi-plus"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="updatedTasks"
            ><v-icon>mdi-export</v-icon></download-excel
          >
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="updatedTasks"
      :search-field="searchField"
      :search-value="searchValue"
      :loading="loading"
      border-cell
      class="custom-data-table"
      body-text-direction="center"
      header-text-direction="center"
      :body-row-class-name="bodyRowClassNameFunction"
      no-hover
    >
      <template #item-edit="item">
        <v-icon @click="ShowTaskDetails(item)">mdi-pencil</v-icon>
      </template>
      <template #item-done="item">
        <v-icon
          v-model="item.done"
          :color="primaryColor1()"
          :icon="
            item.done ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
          "
          @click="changeReminderStatus(item)"
          hide-details
        />
      </template>
    </EasyDataTable>
  </v-container>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="closeModals()"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
  <v-dialog v-model="createTaskModal" max-width="800">
    <create-task-modal
      @successfulTaskOperation="this.getTasks()"
      @closeTaskModal="this.createTaskModal = false"
      :taskToEdit="selectedTask"
    ></create-task-modal>
  </v-dialog>
  <v-dialog v-model="taskDetailsModal" width="auto">
    <task-details-modal
      @editTask="handleEditTaskSignal"
      @deleteTask="handleDeleteTaskSignal"
      :task="selectedTask"
    ></task-details-modal>
  </v-dialog>
</template>

<script>
import { unifiedResponseHandler } from '@/utils/helpers';
import Broken_Page_V01 from '../../Broken_Page_V01.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import { useTheme } from 'vuetify';
import taskService from '@/services/task.service.js';
import CreateTaskModal from '@/components/HomeManagement/MyDashboard/create-task-modal.vue';
import TaskDetailsModal from '@/components/HomeManagement/MyDashboard/task-details-modal.vue';

export default {
  name: 'task_V01',
  components: {
    TaskDetailsModal,
    CreateTaskModal,
    Success_Modal,
    Error_Modal,
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: 'Reminder Type', value: 'reminderType' },
        { text: 'Importance', value: 'importanceLevel' },
        { text: 'Group', value: 'taskGroup' },
        { text: 'Created By', value: 'ownerName' },
        { text: 'Assignee', value: 'assigneeName' },
        { text: 'Done', value: 'done' },
        { text: 'Edit/Details', value: 'edit' },
      ],
      tasks: [],
      createdByMe: false,
      assignedToMe: false,
      searchField: '',
      searchValue: '',
      broken_page: false,
      createTaskModal: false,
      taskDetailsModal: false,
      loading: false,
      success: false,
      error: false,
      message: null,
      selectedTask: null,
    };
  },
  methods: {
    closeModals() {
      this.success = false;
      this.taskDetailsModal = false;
      this.createTaskModal = false;
    },
    lowercaseSecondToEnd(str) {
      if (str.length < 2) return str;
      return str[0] + str.slice(1).toLowerCase();
    },
    async getTasks() {
      this.loading = true;
      let response = unifiedResponseHandler(
        await taskService.getAllTasks(
          this.createdByMe.toString(),
          this.assignedToMe.toString()
        )
      );
      if (response.success) {
        this.tasks = response.data;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    bodyRowClassNameFunction(item) {
      if (item.done) return '';
      return 'task-row';
    },
    findOriginalTask(item) {
      let originalObj = null;
      for (let i = 0; i < this.tasks.length; i++)
        if (this.tasks.at(i).id === item.id) {
          originalObj = this.tasks.at(i);
          break;
        }
      return originalObj;
    },

    ShowTaskDetails(item) {
      this.selectedTask = this.findOriginalTask(item);
      this.taskDetailsModal = true;
    },

    handleDeleteTaskSignal(data) {
      this.deleteTask(data);
    },

    handleEditTaskSignal(data) {
      this.taskDetailsModal = false;
      this.editTask(data);
    },
    editTask(item) {
      this.selectedTask = this.findOriginalTask(item);
      this.createTaskModal = true;
    },
    addTask() {
      this.selectedTask = null;
      this.createTaskModal = true;
    },
    primaryColor1() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor1;
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async changeReminderStatus(item) {
      this.loading = true;
      let response = unifiedResponseHandler(
        await taskService.updateTaskStatus(item.id, !item.done)
      );
      if (response.success) {
        this.message = response.data;
        this.success = true;
        await this.sleep(250);
        this.tasks.forEach(
          (reminder) =>
            reminder.id === item.id && (reminder.done = !reminder.done)
        );
        this.loading = false;
      } else {
        this.error = true;
        this.message = response.message || 'An error occurred';
      }
    },

    async deleteTask(item) {
      let response = unifiedResponseHandler(await taskService.deleteTask(item));
      if (response.success) {
        this.message = response.data;
        this.success = true;
        await this.getTasks();
      } else {
        this.error = true;
        this.message = response.message || 'An error occurred';
      }
    },
  },
  computed: {
    updatedTasks() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.tasks
        .sort((a, b) => {
          if (a.done && !b.done) return 1;
          if (!a.done && b.done) return -1;
          return 0;
        })
        .map((task) => ({
          id: task.id,
          title: task.title,
          reminderType: task.reminderType
            ? this.lowercaseSecondToEnd(task.reminderType)
            : '-',
          taskGroup: task.taskGroup.title,
          importanceLevel: this.lowercaseSecondToEnd(task.importanceLevel),
          createDate: task.createDate,
          ownerName: task.owner.firstName,
          assigneeName: task.assignee.firstName,
          assigneeId: task.assignee.id,
          alarmDate: task.alarmDate,
          description: task.description,
          done: task.done,
        }));
    },
  },
  async mounted() {
    await this.getTasks();
  },
};
</script>

<style>
.task-row {
  --easy-table-body-row-background-color: rgba(
    var(--v-theme-primaryColor2),
    0.4
  );
}
</style>
