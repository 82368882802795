import axios from 'axios';
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class ThirdPartyGroupService {
  async getThirdPartyGroups() {
    try {
      return await axios.get(BASE_URL + 'thirdPartyGroup/getAll', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getThirdPartyGroupById(id) {
    try {
      return await axios.get(BASE_URL + 'thirdPartyGroup?groupId=' + id, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async addThirdPartyGroup(third_party_group) {
    try {
      return await axios.post(BASE_URL + 'thirdPartyGroup', third_party_group, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async editThirdPartyGroup(third_party_group) {
    try {
      return await axios.post(
        BASE_URL + 'thirdPartyGroup/edit',
        third_party_group,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async removeThirdPartyGroup(id) {
    try {
      return await axios.delete(BASE_URL + 'thirdPartyGroup?groupId=' + id, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getAllThirdPartiesInGroup(id) {
    try {
      return await axios.get(
        BASE_URL + 'thirdPartyGroup/thirdParty?groupId=' + id,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async removeThirdPartyFromGroup(groupId, thirdPartyId) {
    try {
      return await axios.delete(
        BASE_URL +
          'thirdPartyGroup/thirdParty?groupId=' +
          groupId +
          '&thirdPartyId=' +
          thirdPartyId,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async addThirdPartyToGroup(groupId, thirdPartyId) {
    try {
      return await axios.post(
        BASE_URL +
          'thirdPartyGroup/thirdParty?groupId=' +
          groupId +
          '&thirdPartyId=' +
          thirdPartyId,
        {},
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getPermittedUsers(groupId) {
    try {
      return await axios.get(
        BASE_URL + 'thirdPartyGroup/users?groupId=' + groupId,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new ThirdPartyGroupService();
