<template>
  <v-sheet max-width="800" min-width="600" style="padding: 20px">
    <div class="border-1">
      <h4 class="border-h4">Internal Transfer Summary</h4>
      <div class="grid-card-2" ref="pdfContent">
        <div
          class="summary-row"
          v-for="(value, label) in summaryData"
          :key="label"
        >
          <span style="flex: 1">{{ label }}:</span>
          <span style="flex: 1; text-align: left">{{ value }}</span>
        </div>
      </div>
      <v-row justify="end">
        <v-btn class="mr-4 top-20" color="button_color" @click="generatePDF">
          PDF
        </v-btn>
        <div
          class="d-flex top-20"
          v-if="chosen_internal_transfer.status === 'WAITING_FOR_APPROVAL'"
        >
          <v-btn
            color="button_color"
            style="margin-right: 10px"
            @click="approveRequest"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color="red-lighten-1" variant="tonal" @click="cancelRequest">
            Reject
          </v-btn>
        </div>
      </v-row>
    </div>
  </v-sheet>

  <Error_Modal
    :error="error"
    :message="message"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { formatNumber, unifiedResponseHandler } from '@/utils/helpers.js';
import internal_transferService from '@/services/internal_transfer.service.js';
import { mapGetters } from 'vuex';
import emitter from '@/plugins/mitt.js';
import html2pdf from 'html2pdf.js';
import getStatusDisplay from '@/utils/NewFunctions/getStatusDisplay.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';

export default {
  name: 'request_modal_v01',
  components: {
    Error_Modal,
  },
  data() {
    return {
      message: null,
      error: false,
    };
  },
  methods: {
    getStatusDisplay,
    formatNumber,
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: 'internalTransferSummary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf(element, options);
    },
    async cancelRequest() {
      let response = unifiedResponseHandler(
        await internal_transferService.changeRequestStatusToCanceled(
          this.chosen_internal_transfer.id
        )
      );
      this.message = response.message;
      if (response.success) {
        emitter.emit('internal_transfer_modal_close', this.message);
      } else {
        this.error = true;
      }
    },
    async approveRequest() {
      let response = unifiedResponseHandler(
        await internal_transferService.changeRequestStatusToApproved(
          this.chosen_internal_transfer.id
        )
      );
      this.message = response.message;
      if (response.success) {
        emitter.emit('internal_transfer_modal_close', this.message);
      } else {
        this.error = true;
      }
    },
  },
  computed: {
    ...mapGetters(['chosen_internal_transfer', 'page']),
    summaryData() {
      if (!this.chosen_internal_transfer) return {}; // Handle undefined chosen_internal_transfer

      return {
        Event: this.chosen_internal_transfer.id,
        'Reference No': this.chosen_internal_transfer.referenceNo,
        From: this.chosen_internal_transfer.sourceAccount.cofferName,
        Debit: `${this.formatNumber(this.chosen_internal_transfer.amount)} ${this.chosen_internal_transfer.sourceAccount.currency.symbol}`,
        'Exchange rate': this.chosen_internal_transfer.exchangeRate,
        'Live XE Rate': this.chosen_internal_transfer.liveXERate,
        'Payer/Payee': this.chosen_internal_transfer.payerPayeeFullName,
        To: this.chosen_internal_transfer.targetAccount.cofferName,
        Credit: `${this.formatNumber(this.chosen_internal_transfer.targetAmount)} ${this.chosen_internal_transfer.targetAccount.currency.symbol}`,
        Status: this.getStatusDisplay(this.chosen_internal_transfer.status),
        'Source Com Amt':
          this.chosen_internal_transfer.withdrawalCommissionAmount || 0,
        'Source Com %':
          this.chosen_internal_transfer.withdrawalCommissionPercent || 0,
        'Target Com Amt':
          this.chosen_internal_transfer.depositCommissionAmount || 0,
        'Target Com %':
          this.chosen_internal_transfer.depositCommissionPercent || 0,
        Note: this.chosen_internal_transfer.description,
        'Creating date': this.chosen_internal_transfer.createdDate,
        'Modified on': this.chosen_internal_transfer.modifiedDate,
      };
    },
  },
};
</script>
