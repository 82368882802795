<template>
  <v-form fast-fail @submit.prevent ref="login_form" class="pa-4">
    <v-text-field
      v-model="email"
      label="Email"
      :rules="emailRule"
      @input="clearErrorMessage"
      @focus="clearErrorMessage"
    ></v-text-field>

    <v-text-field
      :type="showPassword ? 'text' : 'password'"
      v-model="password"
      label="Password"
      :rules="requiredRule()"
      class="password-field"
      @input="clearErrorMessage"
      @focus="clearErrorMessage"
    >
      <v-icon @click="togglePasswordVisibility" class="v-icon" small
        >{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
      </v-icon>
    </v-text-field>
    <a class="clickable-text" @click="handleForgetPasswordClick()">
      Forget password?
    </a>
    <br />
    <ball-animation v-if="showBallAnimation" />
    <v-btn
      block
      color="button_color"
      type="submit"
      class="mt-8"
      @click="signIn()"
    >
      Login
    </v-btn>
    <p
      style="
        color: red;
        margin-top: 10px;
        margin-left: 5px;
        font-weight: 500;
        text-align: center;
      "
    >
      {{ message }}
    </p>
  </v-form>
</template>
<script>
import { emailRule, requiredRule } from '@/utils/rules.js';
import { unifiedResponseHandler } from '@/utils/helpers.js';
import authService from '@/services/auth.service.js';
import BallAnimation from '@/components/Login/ball-animation.vue';

export default {
  name: 'login-form',
  components: { BallAnimation },
  data() {
    return {
      email: null,
      password: null,
      showPassword: false,
      emailRule: emailRule,
      message: null,
      showBallAnimation: false,
    };
  },
  methods: {
    requiredRule() {
      return requiredRule;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    clearErrorMessage() {
      this.otpError = false;
      this.message = null;
    },
    async signIn() {
      let form = {
        email: this.email,
        password: this.password,
        otp: null,
      };
      const { valid } = await this.$refs.login_form.validate();
      if (valid) {
        try {
          this.showBallAnimation = true;
          const response = unifiedResponseHandler(
            await authService.signIn(form)
          );
          this.showBallAnimation = false;
          if (response.success) {
            this.$emit('loginSuccess', {
              email: this.email,
              password: this.password,
            });
          } else {
            this.message = response.message;
          }
        } catch (error) {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      }
    },
    handleForgetPasswordClick() {
      this.$emit('forgetPasswordClicked');
    },
  },
};
</script>
<style scoped>
.password-field {
  position: relative;
}

.v-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the right distance as needed */
  transform: translateY(-50%);
  cursor: pointer;
}

input {
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.clickable-text {
  cursor: pointer;
  text-decoration: none;
  margin-top: -10px;
  display: block;
  text-align: right;
}
</style>
