export const requiredRule = [(v) => !!v || 'This field is required'];
export const requiredRuleNumber = [
  (v) => !!v || 'This field is required',
  (v) => !isNaN(parseInt(v)) || 'This field must be a number',
];

export const symbolValidationRule = [
  (v) => !!v || 'This field is required',
  (v) => (v && v.length === 3) || 'Symbols must have 3 characters',
];

export const isTwoDigitNumber = [
  (v) => !!v || 'This field is required',
  (v) => /^\d{2}$/.test(v) || 'Must be a two-digit number',
];
export const phoneRule = [
  (v) => !!v || 'This field is required',
  (v) => !v || /^\+?[1-9][0-9]{7,14}$/.test(v) || 'Phone number must be valid',
];

export const phoneNullAllowedRule = [
  (v) => !v || /^\+?[1-9][0-9]{7,14}$/.test(v) || 'Phone number must be valid',
];

export const cellPhoneRule = [
  (v) => !!v || 'This field is required',
  (v) => !v || /^(09)([0-9]{9})$/.test(v) || 'Cell number must be valid',
];
export const emailRule = [
  (v) => !!v || 'This field is required',
  (v) =>
    !v ||
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
    'E-mail must be valid',
];

export const emailNotRequiredRule = [
  (v) =>
    !v ||
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
    'E-mail must be valid',
];

export const numericRule = [
  (v) =>
    v === '' ||
    v === null ||
    !isNaN(parseInt(v)) ||
    'This field has to be numeric',
];

export const phoneAndCellRule = [
  (v) => {
    if (!v) return true; // Allow empty values
    const numericValue = parseInt(v);
    if (!isNaN(numericValue) && v.length >= 1 && v.length <= 15) {
      return true;
    }
    return 'This field has to be numeric and between 1 and 15 characters';
  },
];

export const phoneCellRequired = [
  (v) => {
    if (!v) {
      return 'This field is required';
    }
    const numericValue = parseInt(v);
    if (!isNaN(numericValue) && v.length >= 1 && v.length <= 15) {
      return true;
    }
    return 'numerics between 1 and 15 characters allowed ';
  },
];

export const phoneCellNotRequired = [
  (v) => {
    if (v === null || v === undefined || v === '') {
      return true;
    }
    const numericValue = parseInt(v);
    if (!isNaN(numericValue) && v.length >= 1 && v.length <= 15) {
      return true;
    }
    return 'numerics between 1 and 15 characters allowed ';
  },
];

export const positiveIntegerRule = [
  (v) => {
    if (v === '' || v === null) {
      return true; // Allow empty values
    }

    const intValue = parseInt(v);
    if (!isNaN(intValue) && intValue >= 0 && intValue.toString() === v) {
      return true; // Check if it's a positive integer
    }

    return 'This field has to be a positive integer';
  },
];

export const numericRequiredRule = [
  // (v) => !isNaN(parseInt(v)) || "This field has to be numeric",
  (v) => {
    // Regular expression to match numbers with up to four decimal places
    const numericRegex = /^\d+(\.\d{1,2})?$/;
    return (
      numericRegex.test(v) ||
      'This field has to be a numeric value with up to two decimal places'
    );
  },
];

export const numericFiveDigitRequiredRule = [
  (v) => {
    // Regular expression to match numbers with up to four decimal places
    const numericRegex = /^\d+(\.\d{1,5})?$/;
    return (
      numericRegex.test(v) ||
      'This field has to be a numeric value with up to four decimal places'
    );
  },
];

export const lengthRule = [
  (v) => !!v || 'This field is required',
  (v) => (v && v.length >= 3) || 'This field length has to be greater than 3',
];

export const multipleFileRequiredRule = [
  (v) => (!!v && v.length > 0) || 'File is required',
];

export const sizeRule = [
  (v) => v.size <= 5 * 1024 * 1024 || 'File size should be less than 5MB',
  //1024 * 1024 = 1mb
];
