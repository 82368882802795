<template>
  <v-sheet width="1000">
    <div style="padding: 20px">
      <div class="border-1">
        <h4 class="border-h4">Manual Transaction Summary</h4>
        <div
          class="grid-card-2 mb-4"
          ref="pdfContent"
          style="text-align: left; direction: ltr"
        >
          <p>Event:</p>
          <p class="m-l-35">{{ chosen_manual_transaction.id }}</p>
          <p>Past Event:</p>
          <p class="m-l-35">
            {{ chosen_manual_transaction?.eventId || ' ' }}
          </p>
          <p>Modified Date:</p>
          <p class="m-l-35">{{ chosen_manual_transaction.createDate }}</p>

          <p>Customer Acc:</p>
          <p class="m-l-35">
            {{ chosen_manual_transaction.customerAccount?.cofferName || ' ' }}
          </p>
          <p>Trustee Acc:</p>
          <p class="m-l-35">
            {{ chosen_manual_transaction.trusteeAccount?.cofferName || ' ' }}
          </p>
          <p>Manual Transaction Type:</p>
          <p class="m-l-35">
            {{
              formatManualTransactionType(
                chosen_manual_transaction.manualTransactionType
              )
            }}
          </p>
          <p>Note:</p>
          <p class="m-l-35">
            {{ chosen_manual_transaction.description }}
          </p>
        </div>
        <table class="table-style">
          <thead>
            <tr>
              <th>T. Credit Amt:</th>
              <th>T. Credit Com Amt:</th>
              <th>T. Debit Amt:</th>
              <th>T. Debit Com Amt:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                {{
                  chosen_manual_transaction.trusteeAccount
                    ? formatNumber(
                        chosen_manual_transaction.trusteeCreditAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.trusteeAccount.currency.symbol
                    : ''
                }}
              </td>
              <td class="text-center">
                {{
                  chosen_manual_transaction.trusteeAccount
                    ? formatNumber(
                        chosen_manual_transaction.trusteeCreditCommissionAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.trusteeAccount.currency.symbol
                    : ''
                }}
              </td>
              <td class="text-center">
                {{
                  chosen_manual_transaction.trusteeAccount
                    ? formatNumber(
                        chosen_manual_transaction.trusteeDebitAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.trusteeAccount.currency.symbol
                    : ''
                }}
              </td>
              <td class="text-center">
                {{
                  chosen_manual_transaction.trusteeAccount
                    ? formatNumber(
                        chosen_manual_transaction.trusteeDebitCommissionAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.trusteeAccount.currency.symbol
                    : ''
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table-style">
          <thead>
            <tr>
              <th>C. Credit Amt:</th>
              <th>C. Credit Com Amt:</th>
              <th>C. Debit Amt:</th>
              <th>C. Debit Com Amt:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                {{
                  chosen_manual_transaction.customerAccount
                    ? formatNumber(
                        chosen_manual_transaction.customerCreditAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.customerAccount.currency.symbol
                    : ' '
                }}
              </td>
              <td class="text-center">
                {{
                  chosen_manual_transaction.customerAccount
                    ? formatNumber(
                        chosen_manual_transaction.customerCreditCommissionAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.customerAccount.currency.symbol
                    : ' '
                }}
              </td>
              <td class="text-center">
                {{
                  chosen_manual_transaction.customerAccount
                    ? formatNumber(
                        chosen_manual_transaction.customerDebitAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.customerAccount.currency.symbol
                    : ' '
                }}
              </td>
              <td class="text-center">
                {{
                  chosen_manual_transaction.customerAccount
                    ? formatNumber(
                        chosen_manual_transaction.customerDebitCommissionAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.customerAccount.currency.symbol
                    : ' '
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table-style">
          <thead>
            <tr>
              <th>S. Credit Amt:</th>
              <th>S. Credit Com Amt:</th>
              <th>S. Debit Amt:</th>
              <th>S. Debit Com Amt:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                {{
                  chosen_manual_transaction.safeboxAccount
                    ? formatNumber(
                        chosen_manual_transaction.safeboxCreditAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.safeboxAccount.currency.symbol
                    : ' '
                }}
              </td>
              <td class="text-center">
                {{
                  chosen_manual_transaction.safeboxAccount
                    ? formatNumber(
                        chosen_manual_transaction.safeboxCreditCommissionAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.safeboxAccount.currency.symbol
                    : ' '
                }}
              </td>
              <td class="text-center">
                {{
                  chosen_manual_transaction.safeboxAccount
                    ? formatNumber(
                        chosen_manual_transaction.safeboxDebitAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.safeboxAccount.currency.symbol
                    : ' '
                }}
              </td>
              <td class="text-center">
                {{
                  chosen_manual_transaction.safeboxAccount
                    ? formatNumber(
                        chosen_manual_transaction.safeboxDebitCommissionAmount
                      ) +
                      ' ' +
                      chosen_manual_transaction.safeboxAccount.currency.symbol
                    : ' '
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <v-row justify="end">
          <v-btn class="mr-4 mt-8" color="button_color" @click="generatePDF"
            >PDF</v-btn
          >
        </v-row>
      </div>
    </div>
  </v-sheet>

  <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>
      </div>
      <div class="error-right">
        <span @click="error = false" style="text-align: right">✖</span>
        <h4>Error</h4>
        <p class="error-message">{{ message }}</p>
        <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import html2pdf from 'html2pdf.js';
import { formatNumber } from '@/utils/helpers.js';

export default {
  name: 'request_modal_v01',

  data() {
    return {
      request: null,
      currency: null,
      customer: null,
      amount: null,
      createDate: null,
      modifiedDate: null,
      status: null,
      requestType: null,
      trusteeCommissionAmount: null,
      trusteeCommissionPercent: null,
      ellingtonCommissionPercent: null,
      ellingtonCommissionAmount: null,
      message: null,
      error: false,
    };
  },
  methods: {
    formatNumber,
    formatManualTransactionType(manualTransactionType) {
      if (manualTransactionType === 'MONEY_INTERNAL_CORRECTION') {
        return 'Money/Internal';
      } else if (manualTransactionType === 'BILL_PAYMENT_CORRECTION') {
        return 'Bill Payment';
      } else {
        return 'No Event';
      }
    },
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: 'cryptoSummary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf(element, options);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(['chosen_manual_transaction', 'page']),
  },
};
</script>
<style>
.table-style {
  width: 100%;
  gap: 10px;
  margin: 10px;
}
</style>
