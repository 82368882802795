export const ExchangeRateOption = {
  preProcess: (val) => {
    if (val === '0' || val === '.') return val === '.' ? '0.' : '0';
    val = val.replace(/[^0-9.]/g, '');
    val = val.replace(/^0+(?!\.)/, '');
    return val || '';
  },
  postProcess: (val) => {
    if (!val) return '';
    const [integerPart, decimalPart] = String(val).split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (decimalPart === undefined) return formattedInteger;
    const formattedDecimal = decimalPart.slice(0, 9);
    return `${formattedInteger}.${formattedDecimal}`;
  },
};

export const MoneyOption = (symbol, precision = 2) => {
  return {
    symbol: symbol,
    preProcess: (val) => {
      val = val.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
      if (val.startsWith('0') && !val.startsWith('0.') && val !== '0') {
        val = val.replace(/^0+/, '0');
      }
      return val;
    },
    postProcess: (val) => {
      if (!val) return '';
      const parts = val.toString().split('.');
      if (parts[1]) {
        parts[1] = parts[1].slice(0, precision);
      }
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      let output = parts.join('.');
      if (symbol) {
        output = output + ' ' + symbol;
      }
      return output;
    },
  };
};

export const PercentageOption = {
  preProcess: (val) => {
    if (val === '0') {
      return '0';
    } else if (val === '.') {
      return '0.';
    } else {
      val = val.replace(/[^0-9.]/g, '');
      val = val.replace(/^0+(?!\.)/, '');
      return val;
    }
  },
  postProcess: (val) => {
    if (!val) return '';
    const parts = val.toString().split('.');
    if (parts[1]) parts[1] = parts[1].slice(0, 2);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let output = parts.join('.');
    if (parts.length > 2) {
      return output.slice(0, output.length - 1);
    }
    return output + '%';
  },
};
