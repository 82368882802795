<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium mb-2">Group Deal Report</h3>
    <v-row class="mb-2">
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field
            placeholder="Search"
            v-model="searchValue"
            @keyup.enter="searchForItem(searchValue)"
          >
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="QGroupDealModal = true"
          icon="mdi-help"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="gruopDetails">
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="tableItems"
      v-model:serverOptions="serverOptions"
      :server-items-length="totalItems"
      :loading="loading"
      body-text-direction="center"
      header-text-direction="center"
      class="custom-data-table"
      border-cell
      :body-row-class-name="bodyRowClassNameFunction"
      no-hover
    >
      <template #pagination="{}">
        <v-btn
          density="compact"
          class="ma-4"
          :disabled="serverOptions.page === 1"
          @click="goToPrevPage"
        >
          prev page
        </v-btn>
        <v-btn
          density="compact"
          class="ma-4"
          :disabled="serverOptions.page === numberOfPages"
          @click="goToNextPage"
          >next page
        </v-btn>
      </template>
      <template #item-id="item">
        <v-icon @click="gotToRequest(item)"
          ><span class="modal-icon">{{ item.id }}</span></v-icon
        >
      </template>
      <template #item-receiveAmount="{ receiveAmount }">
        <div class="currency-amount">
          <span class="currency">{{
            splitCurrencyValue(receiveAmount).currency
          }}</span>
          <span class="text-right">{{
            splitCurrencyValue(receiveAmount).value
          }}</span>
        </div>
      </template>
      <template #item-totalSendAmount="{ totalSendAmount }">
        <div class="currency-amount">
          <span class="currency">{{
            splitCurrencyValue(totalSendAmount).currency
          }}</span>
          <span class="text-right">{{
            splitCurrencyValue(totalSendAmount).value
          }}</span>
        </div>
      </template>
      <template #item-update="item">
        <Toggle_Confirmation
          @acceptTransfer="acceptTransfer(item)"
          @denyTransfer="denyTransfer(item)"
          :item="item"
          :action="action"
          :editable="false"
        >
        </Toggle_Confirmation>
      </template>
    </EasyDataTable>
  </v-container>

  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>

  <v-dialog v-model="groupDealModal" width="auto">
    <Group_Deal_Modal_V01></Group_Deal_Modal_V01>
  </v-dialog>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>

  <v-dialog v-model="QGroupDealModal" width="auto">
    <Q_GroupDeal_Modal_V01></Q_GroupDeal_Modal_V01>
  </v-dialog>
</template>
<script>
import Broken_Page_V01 from '../../Broken_Page_V01.vue';
import GroupDealService from '@/services/groupDeal.service';
import {
  pageResponseHandler,
  unifiedResponseHandler,
  formatNumber,
} from '@/utils/helpers';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import { PAGE_SIZE } from '@/config/constants';
import getStatusDisplay from '@/utils/NewFunctions/getStatusDisplay';
import Toggle_Confirmation from '@/components/MoneyTransfer/Toggle_Confirmation.vue';
import Group_Deal_Modal_V01 from '../../Modals/transfer/Group_Deal_Modal_V01.vue';
import emitter from '@/plugins/mitt';
import { mapGetters } from 'vuex';

export default {
  name: 'Group_deal_list_v01',
  components: {
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    Toggle_Confirmation,
    Group_Deal_Modal_V01,
  },
  data() {
    return {
      headers: [
        { text: 'Event', value: 'id', width: 30 },
        { text: 'Customer', value: 'customer' },
        { text: 'Receive Amt', value: 'receiveAmount' },
        { text: 'Total Send Amt', value: 'totalSendAmount' },
        { text: 'TET Into Our Wallet', value: 'tetIntoOurWallet' },
        { text: 'Create Date', value: 'createDate' },
        { text: 'Modfied Date', value: 'modfiedDate' },
        { text: 'Status', value: 'status' },
        { text: 'Update', value: 'update' },
      ],
      message: null,
      broken_page: false,
      success: false,
      error: false,
      action: false,
      groupItems: [],
      tableItems: [],
      totalItems: 0,
      accountCofferName: null,
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
      loading: false,
      statusCriteria: 'ALL',
      showStatusFilter: false,
      groupDealModal: false,
      QGroupDealModal: false,
      showConfirmationModal: false,
      searchField: '',
      searchValue: '',
      currentPage: 0,
      pageSize: PAGE_SIZE,
    };
  },
  methods: {
    bodyRowClassNameFunction(item) {
      switch (item.status) {
        case 'Completed':
          return 'row-completed';
        case 'Canceled':
          return 'row-canceled';
        case 'Initiated':
          return 'row-process';
        case 'Admin Approved':
        case 'Waiting for Approved':
        case 'Waiting for Approval':
        case 'Commission Add':
        case 'Receive Confirmed':
        case 'Trustee Selected':
        case 'Trustee Confirmed':
        case 'Transaction Requested':
          return 'row-other-status';
        case 'Approved':
          return 'row-approved';
        default:
          return '';
      }
    },
    splitCurrencyValue(currencyString) {
      const parts = currencyString.split(' ');
      return {
        currency: parts[0],
        value: parts[1],
      };
    },
    goToPrevPage() {
      this.serverOptions.page = this.serverOptions.page - 1;
    },
    goToNextPage() {
      this.serverOptions.page = this.serverOptions.page + 1;
    },
    searchForItem(item) {
      this.accountCofferName = item;
      if (this.serverOptions.page === 1) {
        this.getGroupDeal();
      } else {
        this.serverOptions.page = 1;
      }
    },
    async getGroupDeal() {
      let response;
      this.loading = true;
      response = pageResponseHandler(
        await GroupDealService.getGroupDeal(
          this.serverOptions.page - 1,
          this.serverOptions.rowsPerPage,
          this.accountCofferName
        )
      );
      this.showConfirmationModal = true;
      if (response.success) {
        console.log('response', response.data);
        this.totalItems = response.totalItems;
        this.numberOfPages = response.numberOfPages;
        this.groupItems = response.data;
        this.tableItems = this.groupDealDetails;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    async gotToRequest(item) {
      let response = unifiedResponseHandler(
        await GroupDealService.getGroupDealById(item.id)
      );
      if (response.success) {
        let requestToSet = response.data;
        this.$store.dispatch('setChosenGroupDeal', requestToSet);
        this.groupDealModal = true;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async acceptTransfer(item) {
      this.action = true;
      console.log('acceptTransfer', item.id);
      let response = unifiedResponseHandler(
        await GroupDealService.requestForAccountUpdate(item.id)
      );
      this.action = false;
      if (response.success) {
        item.status = 'Acc Updated';
        let index = this.tableItems.findIndex(
          (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async denyTransfer(item) {
      this.action = true;
      let response = unifiedResponseHandler(
        await GroupDealService.cancelGroupDeal(item.id)
      );
      this.action = false;
      if (response.success) {
        item.status = 'Canceled';
        let index = this.tableItems.findIndex(
          (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },
  async mounted() {
    emitter.on('group_deal_modal_close', (value) => {
      this.groupDealrModal = false;
      this.message = value;
      this.success = true;
      this.getGroupDeal();
    });
    await this.getGroupDeal();
  },
  computed: {
    ...mapGetters(['page']),

    groupDealDetails() {
      return this.groupItems.map((item) => {
        let transactionStatus = getStatusDisplay(item.status);
        return {
          id: item.id,
          customer: item.customer.fullName,
          receiveAmount:
            formatNumber(item.receiveAmount) +
            ' ' +
            item.receiveCurrency.symbol,
          totalSendAmount:
            formatNumber(item.totalSendAmount) + ' ' + item.sendCurrency.symbol,
          tetIntoOurWallet: formatNumber(item.tetIntoOurWallet),
          createDate: new Date(item.createDate).toISOString().split('T')[0],
          modfiedDate: new Date(item.modfiedDate).toISOString().split('T')[0],
          status: transactionStatus,
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
  },
  watch: {
    searchValue(newVal) {
      if (newVal === '') {
        clearTimeout(this.debounceTimer);
        this.serverOptions.page = 1;
        this.debounceTimer = setTimeout(() => {
          this.getRequests();
        }, 500);
      } else {
        clearTimeout(this.debounceTimer);
        this.accountCofferName = newVal;
        this.debounceTimer = setTimeout(() => {
          this.getGroupDeal();
        }, 500);
      }
    },
    serverOptions: {
      handler() {
        if (this.searchValue !== null && this.searchValue !== '')
          this.searchForItem(this.searchValue);
        else this.getGroupDeal();
      },
      deep: true,
    },
  },
};
</script>
