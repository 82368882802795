<template>
  <v-form fast-fail @submit.prevent ref="login_form" class="pa-4">
    <div style="display: flex; align-items: center; justify-content: center">
      <h2
        class="font-weight-medium"
        style="text-align: center; margin-right: 5px"
      >
        {{
          resendOtpButton
            ? 'Your OTP has been Expired'
            : 'Please Check Your Email'
        }}
      </h2>
    </div>
    <v-otp-input
      v-if="!resendOtpButton"
      :disabled="showBallAnimation"
      :error="otpError"
      v-model="otp"
      @change="clearErrorMessage"
      :autofocus="true"
    ></v-otp-input>
    <ball-animation v-if="showBallAnimation" />
    <v-btn
      v-if="resendOtpButton"
      block
      color="button_color"
      type="submit"
      class="mt-8"
      @click="resendOtp()"
    >
      Resend OTP
    </v-btn>

    <div
      v-if="showTimer"
      class="timer mt-7"
      style="color: var(--main-color); text-align: center"
    >
      Time remaining: {{ formatTime(timer) }}
    </div>
    <p
      style="
        color: red;
        margin-top: 10px;
        margin-left: 5px;
        font-weight: 500;
        text-align: center;
      "
    >
      {{ message }}
    </p>
  </v-form>
</template>
<script>
import { emailRule, requiredRule } from '@/utils/rules.js';
import AuthService from '@/services/auth.service.js';
import { unifiedResponseHandler } from '@/utils/helpers.js';
import BallAnimation from '@/components/Login/ball-animation.vue';
import authService from '@/services/auth.service.js';

export default {
  name: 'verify-otp',
  components: { BallAnimation },
  props: ['email', 'password'],
  data() {
    return {
      showPassword: false,
      otp: '',
      otpError: false,
      emailRule: emailRule,
      showOTPInput: false,
      message: null,
      showBallAnimation: false,
      timer: 240,
      intervalId: null,
      showTimer: false,
      resendOtpButton: false,
    };
  },
  methods: {
    requiredRule() {
      return requiredRule;
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.intervalId);
          this.showTimer = false;
          this.message = '';
          this.resendOtpButton = true;
        }
      }, 1000);
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    },
    clearErrorMessage() {
      this.otpError = false;
      this.message = null;
    },
    async verifyOTP() {
      let form = {
        email: this.email,
        password: this.password,
        otp: this.otp,
      };
      this.showBallAnimation = true;
      const { valid } = await this.$refs.login_form.validate();
      if (valid) {
        let response = unifiedResponseHandler(
          await AuthService.verifyOTP(form)
        );
        if (response.success) {
          this.$emit('optVerified');
        } else {
          this.message = response.message;
        }
      }
      this.showBallAnimation = false;
    },
    async resendOtp() {
      let form = {
        email: this.email,
        password: this.password,
        otp: null,
      };
      const { valid } = await this.$refs.login_form.validate();
      if (valid) {
        try {
          this.showBallAnimation = true;
          const response = unifiedResponseHandler(
            await authService.signIn(form)
          );
          this.showBallAnimation = false;
          if (response.success) {
            this.resendOtpButton = false;
            this.timer = 240;
            this.otp = '';
            this.showTimer = true;
            this.startTimer();
          } else {
            this.message = response.message;
          }
        } catch (error) {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      }
    },
  },
  mounted() {
    this.startTimer();
    this.showTimer = true;
  },
  watch: {
    otp(newVal) {
      if (newVal.length === 6) this.verifyOTP();
    },
  },
};
</script>
<style scoped></style>
