import axios from 'axios';
import { BASE_URL } from '../config/endpoints.js';

class AuthService {
  async signIn(signInForm) {
    try {
      return await axios.post(BASE_URL + 'auth/signin', signInForm);
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async verifyOTP(otpVerificationForm) {
    try {
      const response = await axios.post(
        BASE_URL + 'auth/verifyotp',
        otpVerificationForm
      );
      if (response.status === 200 || response.data.code === 200) {
        localStorage.setItem('auth/user', JSON.stringify(response.data));
        return { status: 200, data: response.data.data };
      } else {
        return { status: 500, data: 'Incorrect OTP, please try again' };
      }
    } catch (error) {
      return {
        status: error.response.status,
        data: 'Incorrect OTP, please try again',
      };
    }
  }

  logout() {
    localStorage.removeItem('auth/user');
  }

  async sendOTP(email) {
    try {
      return await axios.post(BASE_URL + 'auth/sendotp?email=' + email);
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async changePasswordViaOTP(chang_password_form) {
    try {
      return await axios.post(
        BASE_URL + 'auth/changePasswordViaOTP',
        chang_password_form
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new AuthService();
