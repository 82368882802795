import axios from 'axios';
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class taskGroupService {
  async addTaskGroup(taskGroup) {
    try {
      return await axios.post(BASE_URL + 'taskGroup', taskGroup, {
        headers: authHeader(),
      });
    } catch (error) {
      console.log(error);
      return { status: error.response.status, data: error.response.data };
    }
  }

  async editTaskGroup(taskGroup) {
    try {
      return await axios.post(BASE_URL + 'taskGroup/edit', taskGroup, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteTaskGroup(taskGroupId) {
    try {
      return await axios.delete(BASE_URL + 'taskGroup?id=' + taskGroupId, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getAllTaskGroups() {
    try {
      return await axios.get(BASE_URL + 'taskGroup', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new taskGroupService();
