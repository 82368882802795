<template>
  <v-sheet max-width="800" min-width="600" style="padding: 20px">
    <div class="border-1">
      <h4 class="border-h4">{{ formatRequestType(requestType) }}</h4>
      <div class="grid-card-2" ref="pdfContent">
        <div
          class="summary-row"
          v-for="(value, label) in summaryData"
          :key="label"
        >
          <span style="flex: 1">{{ label }}:</span>
          <span style="flex: 1; text-align: left">{{ value }}</span>
        </div>
      </div>
      <v-row justify="end" align="center">
        <v-btn class="mr-4" color="button_color" @click="generatePDF"
          >PDF
        </v-btn>
        <v-btn
          color="button_color"
          style="margin-right: 10px"
          @click="emitConfirm"
          >{{ 'confirm' }}
        </v-btn>
        <v-btn color="button_color" @click="emitClose">Cancel </v-btn>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import { formatNumber } from '@/utils/helpers';
import html2pdf from 'html2pdf.js';

export default {
  name: 'pastMoneyTransferConfirmationModal',
  props: ['requestType', 'registerForm'],
  methods: {
    formatNumber,
    formatRequestType(requestType) {
      let transactionTypeDisplay = '';
      if (requestType === 'CASH_RECEIVE') {
        transactionTypeDisplay = 'Past Cash Receive';
      } else if (requestType === 'CASH_SEND') {
        transactionTypeDisplay = 'Past Cash Send';
      } else if (requestType === 'BANK_RECEIVE') {
        transactionTypeDisplay = 'Past Bank Receive';
      } else if (requestType === 'BANK_SEND') {
        transactionTypeDisplay = 'Past Bank Send';
      }
      return transactionTypeDisplay;
    },
    emitClose() {
      this.$emit('close');
    },
    emitConfirm() {
      this.$emit('confirm');
    },
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: 'pastMoneyTransferSummary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
      html2pdf(element, options);
    },
  },
  computed: {
    summaryData() {
      if (!this.registerForm) return {};

      return {
        Date: this.registerForm.date,
        Amount: this.customerActualAmount,
        Description: this.registerForm.description,
        Customer: this.registerForm.customerAccountCofferName,
        'Reference No.': this.registerForm.referenceNo,
        'Payer/Payee Account': this.registerForm.secondaryThirdPartyCofferName,
        'Trustee Account': this.registerForm.tAccountCofferName,
        'Trustee Com Amt': this.trusteeCommissionAmount,
        'Trustee Com %': this.trusteeCommissionPercent,
        'Customer Com Amt': this.ellingtonCommissionAmount,
        'Customer Com %': this.ellingtonCommissionPercent,
      };
    },
    customerActualAmount() {
      return this.registerForm.customerId
        ? formatNumber(this.registerForm.amount) +
            ' ' +
            this.registerForm.currency.symbol
        : null;
    },
    trusteeCommissionAmount() {
      return this.registerForm.trusteeCommissionAmount
        ? formatNumber(this.registerForm.trusteeCommissionAmount) +
            ' ' +
            this.registerForm.currency.symbol
        : null;
    },
    ellingtonCommissionAmount() {
      return this.registerForm.ellingtonCommissionAmount
        ? formatNumber(this.registerForm.ellingtonCommissionAmount) +
            ' ' +
            this.registerForm.currency.symbol
        : null;
    },
    trusteeCommissionPercent() {
      return this.registerForm.trusteeCommissionPercent
        ? formatNumber(this.registerForm.trusteeCommissionPercent) + ' %'
        : null;
    },
    ellingtonCommissionPercent() {
      return this.registerForm.ellingtonCommissionPercent
        ? formatNumber(this.registerForm.ellingtonCommissionPercent) + ' %'
        : null;
    },
  },
};
</script>

<style scoped>

</style>
