<template>
  <v-form ref="cash_receive_request_form" style="margin-top: -50px">
    <v-row id="money-transfer-form" class="mt-4">
      <v-col cols="12" md="3" sm="3" xs="12">
        <v-tab
          :disabled="
            !(
              currentStatus === 'NOT INITIATED' || currentStatus === 'INITIATED'
            ) && !isPastForm
          "
          @click="
            accountListModal = true;
            listOfAccountsModalThirdPartyTypeProp = 'CUSTOMER';
          "
          prepend-icon="mdi-magnify"
          color="button_color"
          >select
        </v-tab>
        <v-text-field
          label="Customer Account"
          v-model="customerAccountCofferName"
          @keyup.backspace="clearCustomer"
          @keydown="handleKeyDown"
          :disabled="
            !(
              currentStatus === 'NOT INITIATED' || currentStatus === 'INITIATED'
            ) && !isPastForm
          "
          :rules="requiredRule"
        ></v-text-field>
        <v-text-field
          class="mt-6"
          v-maska="moneyOption"
          label="Amount"
          v-model="formData.amount"
          :disabled="
            !(
              currentStatus === 'NOT INITIATED' ||
              currentStatus === 'INITIATED' ||
              currentStatus === 'ACCOUNTS_UPDATED'
            ) && !isPastForm
          "
        ></v-text-field>
        <v-tab
          :disabled="
            !(
              currentStatus === 'NOT INITIATED' || currentStatus === 'INITIATED'
            ) && !isPastForm
          "
          @click="
            accountListModal = true;
            listOfAccountsModalThirdPartyTypeProp = 'TRUSTEE';
          "
          prepend-icon="mdi-magnify"
          color="button_color"
          >select
        </v-tab>
        <v-text-field
          :disabled="
            !(
              currentStatus === 'NOT INITIATED' || currentStatus === 'INITIATED'
            ) && !isPastForm
          "
          label="Trustee Account"
          v-model="tAccountCofferName"
          @keyup.backspace="clearTAccount"
          @keydown="handleKeyDown"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="12">
        <v-text-field
          readonly
          label="Customer Balance"
          v-model="customerAccountBalance"
          variant="outlined"
          :disabled="!formData.customerAccount"
          class="mt-6"
        >
        </v-text-field>
        <v-tab
          :disabled="
            !(
              currentStatus === 'NOT INITIATED' || currentStatus === 'INITIATED'
            ) && !isPastForm
          "
          @click="
            accountListModal = true;
            listOfAccountsModalThirdPartyTypeProp = 'SECONDARY_THIRDPARTY';
          "
          prepend-icon="mdi-magnify"
          color="button_color"
          >select
        </v-tab>

        <v-text-field
          label="Payer/Payee Account"
          v-model="secondaryThirdPartyAccountCofferName"
          @keyup.backspace="clearSecondaryThirdPartyAccount"
          @keydown="handleKeyDown"
          :disabled="
            !(
              currentStatus === 'NOT INITIATED' || currentStatus === 'INITIATED'
            ) && !isPastForm
          "
        ></v-text-field>
        <v-text-field
          class="mt-6"
          readonly
          :disabled="!formData.tAccount"
          label="Trustee Balance"
          v-model="tAccountActualAmountCurrencySymbol"
          variant="outlined"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="12">
        <v-text-field
          class="mt-6"
          label="Reference No."
          v-model="formData.referenceNo"
          :disabled="
            !(
              currentStatus === 'NOT INITIATED' ||
              currentStatus === 'INITIATED' ||
              currentStatus === 'ACCOUNTS_UPDATED'
            ) && !isPastForm
          "
        ></v-text-field>
        <v-text-field
          class="mt-6"
          v-maska="moneyOption"
          :disabled="disableCommissionField && !isPastForm"
          label="Customer Com Amt"
          v-model="formData.ellingtonCommissionAmount"
        ></v-text-field>
        <v-text-field
          class="mt-6"
          :disabled="disableCommissionField && !isPastForm"
          v-maska="moneyOption"
          label="Trustee Com Amt"
          v-model="formData.trusteeCommissionAmount"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="12" class="mt-6">
        <div
          :style="isPastForm ? '' : 'visibility: hidden'"
          style="margin-top: -6px; padding-bottom: 0"
        >
          <label for="date-picker" style="color: black"> Date </label>
          <VueDatePicker
            auto-apply="true"
            id="date-picker"
            v-model="formData.date"
            :show-timepicker="false"
          ></VueDatePicker>
        </div>

        <v-text-field
          style="margin-top: 46px !important"
          v-maska="percentageOption"
          :disabled="disableCommissionField && !isPastForm"
          label="Customer Com %"
          v-model="formData.ellingtonCommissionPercent"
        ></v-text-field>
        <v-text-field
          class="mt-6"
          v-maska="percentageOption"
          :disabled="disableCommissionField && !isPastForm"
          label="Trustee Com %"
          v-model="formData.trusteeCommissionPercent"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-card
      v-if="formData.customerAccount"
      class="my-6 mr-3 ml-3 custom-border"
      variant="outlined"
      title="Customer Account Info"
    >
      <v-table class="m-2">
        <thead>
          <tr>
            <th class="text-left">Actual Balance</th>
            <th class="text-left">In Transit Credit</th>
            <th class="text-left">In Transit Debit</th>
            <th class="text-left">Blocked Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ formatNumber(formData.customerAccount.actualAmount) }}</td>
            <td>
              {{ formatNumber(formData.customerAccount.inTransitCreditAmount) }}
            </td>
            <td>
              {{ formatNumber(formData.customerAccount.inTransitDebitAmount) }}
            </td>
            <td>{{ formatNumber(formData.customerAccount.blockedAmount) }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </v-form>
  <v-row>
    <v-col md="3">
      <FilePicker
        :disabled="
          !(
            currentStatus === 'NOT INITIATED' || currentStatus === 'INITIATED'
          ) && !isPastForm
        "
        @fileSelected="handleFileSelected"
        @fileError="handleFileError"
      ></FilePicker>
    </v-col>
    <v-col md="9">
      <v-text-field
        :disabled="
          !(
            currentStatus === 'NOT INITIATED' ||
            currentStatus === 'INITIATED' ||
            currentStatus === 'ACCOUNTS_UPDATED'
          ) && !isPastForm
        "
        v-model="formData.description"
        label="Description"
        @input="checkDescriptionLength"
      >
      </v-text-field>
    </v-col>
  </v-row>
  <v-dialog v-model="accountListModal" width="auto">
    <list_of_accounts_modal_v01
      :third-party-type="listOfAccountsModalThirdPartyTypeProp"
    >
    </list_of_accounts_modal_v01>
  </v-dialog>
</template>
<script>
import CurrencyService from '@/services/currency.service';
import {
  extractNumber,
  formatCurrencyInfo,
  formatNumber,
  handleKeyDown,
  unifiedResponseHandler,
} from '@/utils/helpers';
import { numericRequiredRule, numericRule, requiredRule } from '@/utils/rules';
import list_of_accounts_modal_v01 from '../../Modals/List_Of_Accounts_Modal_V01.vue';
import emitter from '@/plugins/mitt';
import { MoneyOption, PercentageOption } from '@/utils/maska-options.js';
import 'jspdf-autotable';
import { useTheme } from 'vuetify';
import FilePicker from '@/components/Utils/FilePicker.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'moneyTransferForm',
  components: {
    FilePicker,
    list_of_accounts_modal_v01,
  },
  props: {
    currentStatus: String,
    requestType: String,
    currencyOption: String,
    isPastForm: Boolean,
    isEditingRequest: Boolean,
  },
  data() {
    return {
      formData: {
        date: null,
        referenceNo: null,
        description: null,
        requestId: null,
        customerAccount: null,
        amount: null,
        tAccount: null,
        secondaryThirdPartyAccount: null,
        trusteeCommissionAmount: null,
        trusteeCommissionPercent: null,
        ellingtonCommissionAmount: null,
        ellingtonCommissionPercent: null,
      },
      screenWidth: window.innerWidth,
      percentageOption: PercentageOption,
      loading: false,
      currencies: [],
      error: false,
      success: false,
      message: null,
      requiredRule: requiredRule,
      numericRule: numericRule,
      numericRequiredRule: numericRequiredRule,
      accountListModal: false,
      listOfAccountsModalThirdPartyTypeProp: null,
      waitForResponse: false,
    };
  },
  methods: {
    handleFileSelected() {
      this.$emit('fileSelected');
    },
    handleFileError() {
      this.$emit('fileError');
    },
    checkDescriptionLength() {
      if (this.formData.description.length > 255) {
        this.formData.description = this.formData.description.slice(0, 255);
        this.error = true;
        this.message = 'Description is too long (max 255 characters). :)';
      }
    },
    formatNumber,
    clearTAccount() {
      this.formData.tAccount = null;
    },
    clearCustomer() {
      this.formData.customerAccount = null;
    },
    clearSecondaryThirdPartyAccount() {
      this.formData.secondaryThirdPartyAccount = null;
    },

    async getCurrencies() {
      let response = unifiedResponseHandler(
        await CurrencyService.getCurrencies()
      );
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    returnFormData() {
      let moneyTransferRegistrationForm = {
        currencyId: this.selectedCurrency ? this.selectedCurrency.id : null,
        customerId: this.customerAccountThirdPartyId,
        referenceNo: this.formData.referenceNo,
        secondaryThirdPartyAccountId: this.secondaryThirdPartyAccountId,
        amount: extractNumber(this.formData.amount),
        requestType: this.requestType,
        description: this.formData.description,
        trusteeAccountId: this.tAccountId,
        currency: this.selectedCurrency,
        trusteeCommissionAmount: extractNumber(
          this.formData.trusteeCommissionAmount
        ),
        trusteeCommissionPercent: extractNumber(
          this.formData.trusteeCommissionPercent
        ),
        ellingtonCommissionPercent: extractNumber(
          this.formData.ellingtonCommissionPercent
        ),
        ellingtonCommissionAmount: extractNumber(
          this.formData.ellingtonCommissionAmount
        ),
        id: this.formData.requestId,
        date: this.formData.date,
        customerAccountCofferName: this.customerAccountCofferName,
        tAccountCofferName: this.tAccountCofferName,
        secondaryThirdPartyCofferName:
          this.secondaryThirdPartyAccountCofferName,
      };
      let moneyTransferReconciliationForm = {
        eventId: this.formData.requestId,
        amount: extractNumber(this.formData.amount),
        trusteeCommissionAmount: extractNumber(
          this.formData.trusteeCommissionAmount
        ),
        trusteeCommissionPercent: extractNumber(
          this.formData.trusteeCommissionPercent
        ),
        ellingtonCommissionPercent: extractNumber(
          this.formData.ellingtonCommissionPercent
        ),
        ellingtonCommissionAmount: extractNumber(
          this.formData.ellingtonCommissionAmount
        ),
        description: this.formData.description,
        referenceNo: this.formData.referenceNo,
      };
      this.$emit('returnFormData', {
        moneyTransferRegistrationForm: moneyTransferRegistrationForm,
        moneyTransferReconciliationForm: moneyTransferReconciliationForm,
      });
      console.log(moneyTransferRegistrationForm);
    },
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearFormFields() {
      this.formData = {
        date: null,
        referenceNo: null,
        description: null,
        requestId: null,
        customerAccount: null,
        amount: null,
        tAccount: null,
        secondaryThirdPartyAccount: null,
        trusteeCommissionAmount: null,
        trusteeCommissionPercent: null,
        ellingtonCommissionAmount: null,
        ellingtonCommissionPercent: null,
      };
      emitter.emit('clearFile');
    },
  },
  async mounted() {
    emitter.on('chosenAccount', (value) => {
      this.accountListModal = false;
      if (value.thirdParty.thirdPartyType === 'CUSTOMER') {
        this.formData.customerAccount = value;
      }
      if (value.thirdParty.thirdPartyType === 'TRUSTEE') {
        this.formData.tAccount = value;
      }
      if (value.thirdParty.thirdPartyType === 'SECONDARY_THIRDPARTY') {
        this.formData.secondaryThirdPartyAccount = value;
      }
    });

    emitter.on('clearFormFields', () => {
      this.clearFormFields();
    });

    emitter.on('close_t_c_account', () => {
      this.accountListModal = false;
    });

    if (this.isEditingRequest) {
      this.formData.description =
        this.chosen_request.description !== null
          ? this.chosen_request.description.trimStart()
          : null;
      this.formData.requestId = this.chosen_request.id;
      this.formData.customerAccount = this.chosen_request.customerAccount;
      this.formData.secondaryThirdPartyAccount =
        this.chosen_request.secondaryThirdPartyAccount;
      this.formData.amount = this.chosen_request.amount;
      this.formData.referenceNo = this.chosen_request.referenceNo;
      this.formData.tAccount = this.chosen_request.trusteeAccount;
      this.formData.trusteeCommissionAmount =
        this.chosen_request.trusteeCommissionAmount;
      this.formData.trusteeCommissionPercent =
        this.chosen_request.trusteeCommissionPercent;
      this.formData.ellingtonCommissionPercent =
        this.chosen_request.ellingtonCommissionPercent;
      this.formData.ellingtonCommissionAmount =
        this.chosen_request.ellingtonCommissionAmount;
    }
    await this.getCurrencies();
  },

  computed: {
    ...mapGetters(['chosen_request']),
    primaryColor1() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor1;
    },
    primaryColor2() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor2;
    },
    moneyOption() {
      if (this.selectedCurrency) {
        return MoneyOption(this.selectedCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    customerAccountCofferName() {
      return this.formData.customerAccount
        ? this.formData.customerAccount.cofferName
        : null;
    },
    customerAccountBalance() {
      return this.formData.customerAccount
        ? formatNumber(this.formData.customerAccount.actualAmount) +
            ' ' +
            this.formData.customerAccount.currency.symbol
        : null;
    },
    customerAccountThirdPartyId() {
      return this.formData.customerAccount
        ? this.formData.customerAccount.thirdParty.id
        : null;
    },
    selectedCurrency() {
      return this.formData.customerAccount
        ? this.formData.customerAccount.currency
        : this.formData.tAccount
          ? this.formData.tAccount.currency
          : this.formData.secondaryThirdPartyAccount
            ? this.formData.secondaryThirdPartyAccount.currency
            : null;
    },
    tAccountActualAmountCurrencySymbol() {
      return this.formData.tAccount
        ? formatNumber(this.formData.tAccount.actualAmount) +
            ' ' +
            this.formData.tAccount.currency.symbol
        : null;
    },
    secondaryThirdPartyAccountCofferName() {
      return this.formData.secondaryThirdPartyAccount
        ? this.formData.secondaryThirdPartyAccount.cofferName
        : null;
    },
    secondaryThirdPartyAccountId() {
      return this.formData.secondaryThirdPartyAccount
        ? this.formData.secondaryThirdPartyAccount.id
        : null;
    },
    tAccountCofferName() {
      return this.formData.tAccount ? this.formData.tAccount.cofferName : null;
    },
    tAccountId() {
      return this.formData.tAccount ? this.formData.tAccount.id : null;
    },
    disableCommissionField() {
      const status = this.currentStatus;
      return (
        status === 'COMPLETED' ||
        status === 'TRUSTEE_CONFIRMED' ||
        status === 'TRANSACTION_REQUESTED' ||
        status === 'WAITING_FOR_APPROVAL' ||
        status === 'APPROVED'
      );
    },
  },
  watch: {
    formData: {
      handler() {
        this.returnFormData();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-width: 1px;
  border-style: solid;
  border-color: #b0b0b0;
}
</style>
