<template>
  <v-container
    fluid
    style="
      background-color: white;
      min-height: 300px;
      width: 700px;
      border-radius: 5px;
    "
  >
    <v-card-title class="font-weight-bold text-h5" style="text-align: center">
      Task Details
    </v-card-title>

    <v-card-text class="text-body-1">
      <v-row class="mt-2">
        <v-col cols="6">
          <strong class="font-weight-bold">Title:</strong> {{ task.title }}
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Task Group:</strong>
          <v-chip color="blue" dark>
            {{ task.taskGroup.title }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Done:</strong>
          {{ task.done ? 'Yes' : 'No' }}
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Importance:</strong>
          <v-chip :color="getImportanceColor(task.importanceLevel)" dark>
            {{ lowercaseSecondToEnd(task.importanceLevel) }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row v-if="task.reminderType">
        <v-col cols="6">
          <strong class="font-weight-bold">Reminder type:</strong>
          <br />
          {{ lowercaseSecondToEnd(task.reminderType) }}
        </v-col>

        <v-col cols="6">
          <strong class="font-weight-bold">Reminder:</strong>
          <br />
          {{
            task.earlyReminder !== null
              ? lowercaseSecondToEnd(task.earlyReminder)
              : lowercaseSecondToEnd(task.recurrence)
          }}
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Creation Date:</strong>
          <div>{{ formatDate(task.createDate) }}</div>
        </v-col>

        <v-col cols="6" v-if="task.reminderType">
          <strong class="font-weight-bold">Alarm Date:</strong>
          <div>{{ formatDate(task.alarmDate) }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Created By:</strong>
          <br />
          <v-chip :style="{ color: 'blue' }">{{ task.owner.firstName }}</v-chip>
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Assignee:</strong>
          <br />
          <v-chip :style="{ color: 'blue' }">{{
            task.assignee.firstName
          }}</v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <strong class="font-weight-bold">Description:</strong>
          <div>{{ task.description }}</div>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>
      <v-row justify="end">
        <v-btn
          variant="outlined"
          class="mr-1 ma-3"
          color="button_color"
          @click="editTask(task)"
        >
          Edit Task
        </v-btn>
        <v-btn
          :disabled="disableDeleteButton"
          class="mr-9 ma-3"
          color="red"
          @click="deleteTask(task)"
        >
          Delete
        </v-btn>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
export default {
  name: 'TaskDetailsModal',
  props: ['task'],
  data: () => ({
    disableDeleteButton: false,
  }),
  methods: {
    lowercaseSecondToEnd(str) {
      if (str == null) return null;
      if (str.length < 2) return str;
      return str[0] + str.slice(1).toLowerCase();
    },
    getImportanceColor(level) {
      switch (level) {
        case 'LOW':
          return 'blue';
        case 'MEDIUM':
          return 'green';
        case 'HIGH':
          return '#fcc603';
        case 'CRITICAL':
          return 'red';
        default:
          return 'gray';
      }
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return date.toLocaleDateString('en-US', options);
    },
    editTask(task) {
      this.$emit('editTask', task);
    },
    deleteTask(task) {
      this.disableDeleteButton = true;
      this.$emit('deleteTask', task.id);
    },
  },
  mounted() {
    console.log(this.task);
  },
};
</script>
