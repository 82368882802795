<script>
export default {
  name: 'safeBoxDeposit',
};
</script>

<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="199.000000pt"
    height="148.000000pt"
    viewBox="0 0 199.000000 148.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <metadata>
      Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g
      transform="translate(0.000000,148.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M445 1471 c-35 -11 -266 -145 -291 -170 -31 -31 -44 -80 -44 -166 1
-97 11 -134 65 -228 77 -135 125 -168 195 -132 19 10 31 11 35 4 16 -25 57
-30 94 -10 32 16 40 17 56 5 24 -18 56 -18 81 1 10 8 22 15 25 15 6 0 149
-234 149 -244 0 -3 -117 -7 -261 -8 l-261 -3 -115 -200 c-165 -287 -163 -283
-163 -310 0 -37 24 -30 39 10 14 40 23 43 51 15 20 -20 33 -20 894 -20 l873 0
28 21 c29 24 39 19 51 -29 3 -12 12 -22 20 -22 27 0 14 53 -33 134 -25 45 -59
103 -74 131 -15 27 -56 100 -91 160 l-64 110 -130 3 -129 3 -42 77 c-24 42
-79 140 -124 217 -45 77 -92 158 -104 180 -13 22 -59 103 -104 179 -44 77 -82
144 -84 149 -1 5 -30 -7 -62 -27 -33 -20 -64 -36 -70 -36 -22 0 -292 133 -315
155 -31 29 -69 44 -95 36z m64 -90 c23 -25 102 -63 249 -121 17 -7 32 -15 32
-19 0 -12 -86 -55 -107 -53 -12 1 -25 -3 -28 -9 -4 -5 39 -88 94 -183 63 -108
101 -184 101 -201 0 -54 -52 -83 -93 -52 -10 7 -41 53 -70 102 -29 49 -60 91
-70 93 -25 5 -22 -28 7 -71 l25 -36 -25 -16 c-34 -22 -54 -8 -93 64 -30 58
-52 74 -66 51 -4 -6 5 -32 20 -57 24 -45 25 -48 9 -60 -36 -26 -63 -7 -108 77
-13 24 -29 46 -35 48 -23 8 -23 -31 -1 -70 18 -33 20 -42 9 -49 -40 -25 -68
-2 -138 114 l-51 85 0 109 c0 92 3 112 18 126 31 28 257 156 275 157 11 0 31
-13 46 -29z m495 -150 l27 -50 -38 5 c-21 3 -55 13 -76 21 -36 16 -40 15 -83
-5 -58 -28 -60 -28 -32 7 14 18 33 30 48 31 14 0 41 9 60 20 52 29 64 25 94
-29z m-92 -68 c12 -11 34 -17 57 -16 33 2 40 -2 54 -30 9 -18 27 -50 40 -72
13 -22 67 -115 120 -207 53 -93 102 -176 109 -187 9 -15 7 -24 -11 -47 -17
-21 -21 -36 -17 -60 6 -30 4 -33 -86 -87 -92 -55 -93 -55 -112 -35 -13 14 -29
19 -57 17 -39 -3 -40 -2 -79 65 -22 38 -40 70 -40 72 0 2 -16 29 -35 59 -44
68 -28 84 28 31 103 -97 268 -25 267 116 -1 51 -24 98 -65 130 -42 33 -140 33
-185 -1 -18 -12 -36 -19 -40 -15 -21 23 -121 209 -114 211 5 2 36 19 69 38 70
40 72 40 97 18z m133 -277 c86 -36 85 -170 -1 -211 -46 -21 -76 -19 -120 11
-50 34 -54 41 -42 76 6 16 8 42 5 57 -12 60 80 99 158 67z m350 -329 c30 -45
30 -47 8 -47 -10 0 -46 -13 -80 -29 l-61 -29 24 35 c13 20 24 47 24 60 0 13 7
38 16 56 19 35 15 38 69 -46z m-519 -38 c15 -30 15 -32 -2 -24 -11 4 -27 10
-36 12 -14 4 -15 9 -7 24 14 27 26 24 45 -12z m24 -127 c25 -42 46 -79 48 -82
1 -3 -62 -5 -139 -5 -124 0 -143 -2 -153 -18 -8 -12 -8 -22 0 -35 10 -16 39
-17 369 -17 330 0 359 1 369 18 8 12 8 22 0 35 -10 15 -29 17 -148 17 -75 0
-136 2 -136 5 1 3 63 40 139 83 l138 77 134 0 135 0 103 -183 c57 -100 111
-190 119 -199 14 -17 -29 -18 -883 -18 -854 0 -897 1 -883 18 8 9 57 89 108
177 51 88 99 170 106 183 14 22 16 22 272 22 l257 -1 45 -77z m152 -9 c29 -12
43 -13 70 -4 l32 11 -34 -35 c-29 -30 -33 -38 -23 -54 7 -10 9 -22 6 -25 -4
-3 -37 -6 -75 -6 l-69 0 6 34 c5 22 1 44 -9 64 -9 17 -16 33 -16 36 0 9 74 -5
112 -21z"
      />
    </g>
  </svg>
</template>
