import axios from 'axios';
// import { authFormDataHeader } from "./auth-header";
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class FreeCashReport {
  async getCustomerHeader() {
    try {
      return await axios.get(BASE_URL + 'reports/freeCashReport/currencies', {
        params: {
          thirdPartyType: 'CUSTOMER',
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async getTrusteeHeader() {
    try {
      return await axios.get(BASE_URL + 'reports/freeCashReport/currencies', {
        params: {
          thirdPartyType: 'TRUSTEE',
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async getFreeCashReportCustomer() {
    try {
      return await axios.get(BASE_URL + 'reports/freeCashReport', {
        params: {
          thirdPartyType: 'CUSTOMER',
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async getFreeCashReportTrustee() {
    try {
      return await axios.get(BASE_URL + 'reports/freeCashReport', {
        params: {
          thirdPartyType: 'TRUSTEE',
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async getAEDEquivalent(currency, amount) {
    try {
      return await axios.get(BASE_URL + 'exchangeRate/getAEDEquivalent', {
        params: {
          fromCurrencySymbol: currency,
          amount: amount,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async getCurrencyCustomer() {
    try {
      return await axios.get(BASE_URL + 'reports/currencyTotal', {
        params: {
          thirdPartyType: 'CUSTOMER',
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async getCurrencyTrustee() {
    try {
      return await axios.get(BASE_URL + 'reports/currencyTotal', {
        params: {
          thirdPartyType: 'TRUSTEE',
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
  async getServerTime() {
    try {
      return await axios.get(BASE_URL + 'reports/serverTime', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new FreeCashReport();
