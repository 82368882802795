function getAccountGroupDisplay(accountType) {
  switch (accountType) {
    case 'ASSETS':
      return 'Assets';
    case 'LIABILITIES':
      return 'Liabilities';
    case 'EQUITY':
      return 'Equity';
    case 'REVENUE':
      return 'Revenue';
    case 'COST_OF_GOODS_SERVICES_SOLD':
      return 'Cost';
    case 'EXPENSES':
      return 'Expenses';
    case 'NON_FINANCIAL_ACCOUNT':
      return 'Non-Financial';
  }
  return 'Unknown';
}

export default getAccountGroupDisplay;
