import axios from 'axios';
import { authFormDataHeader, authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class internalTransferService {
  async createInternalTransfer(form) {
    try {
      return await axios.post(BASE_URL + 'internaltransfer/create', form, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async changeRequestStatusToWaitingForApprove(id) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/status/requestForApprove',

        { internalTransferId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async changeRequestStatusToApproved(id) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/status/approve',

        { internalTransferId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async generateTransaction(id) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/status/transactionrequest',
        { internalTransferId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      console.log('the exception is here', error);
      return { status: error.response.status, data: error.response.data };
    }
  }

  async firstPartyConfirmed(id) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/status/firstPartyConfirmed',

        { internalTransferId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async requestForCompletion(id) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/status/complete',

        { internalTransferId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getInternalTransfers(page, size) {
    try {
      const response = await axios.get(BASE_URL + 'internaltransfer/getAll', {
        params: {
          page: page,
          size: size,
        },
        headers: authHeader(),
      });
      return { status: response.status, data: response.data, message: null };
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getInternalTransferById(id) {
    try {
      const response = await axios.get(BASE_URL + 'internaltransfer/get', {
        params: {
          internalTransferId: id,
        },
        headers: authHeader(),
      });
      return {
        status: response.status,
        data: response.data,
        message: response.message,
      };
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async pastInternalTransfer(form) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/pastInternalTransfer',
        form,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getAllIncomplete(page, size) {
    try {
      return await axios.get(BASE_URL + 'internaltransfer/getAllIncomplete', {
        params: {
          page: page,
          size: size,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async requestForAccountsUpdate(id) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/status/accountsUpdate',

        { internalTransferId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async changeRequestStatusToCanceled(id) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/status/cancel',

        { internalTransferId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async requestForReconciliation(form) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/doReconciliation',
        form,
        { headers: authHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteById(id) {
    try {
      return await axios.post(
        BASE_URL + 'internaltransfer/delete',
        { internalTransferId: id },
        { headers: authFormDataHeader() }
      );
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async searchForInternalTransfers(
    page,
    size,
    searchField,
    numericalSearchField
  ) {
    try {
      return await axios.get(BASE_URL + 'internaltransfer/search', {
        params: {
          page: page,
          size: size,
          searchField: searchField,
          numericalSearchField: numericalSearchField,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new internalTransferService();
