<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="mb-2 font-weight-medium mb-2">
      {{ formatReportType(thirdPartyType) }} Report
    </h3>
    <v-row>
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field placeholder="Search" v-model="searchValue">
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="QGeneralLedgerModal = true"
          icon="mdi-help"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel
            v-if="thirdPartyType === 'CUSTOMER'"
            :data="customerReportTableItems"
          >
            <v-icon>mdi-export</v-icon>
          </download-excel>
          <download-excel
            v-if="thirdPartyType === 'TRUSTEE'"
            :data="trusteeReportTableItems"
          >
            <v-icon>mdi-export</v-icon>
          </download-excel>
          <download-excel
            v-if="thirdPartyType === 'REPORT'"
            :data="reportDetails"
          >
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
      v-if="thirdPartyType === 'CUSTOMER'"
      :headers="headersCustomer"
      :items="customerReportTableItems"
      :search-field="searchField"
      :search-value="searchValue"
      alternating
      border-cell
      body-text-direction="right"
      header-text-direction="center"
      class="custom-data-table"
    >
      <template #item-name="item">
        <div class="text-left">
          <span>{{ item.name }}</span>
        </div>
      </template>
      <template #item-totalEmirateBalance="{ totalEmirateBalance }">
        <div class="currency-amount">
          <span>{{ splitCurrencyValue(totalEmirateBalance).currency }}</span>
          <span class="text-right">
            {{ splitCurrencyValue(totalEmirateBalance).value }}
          </span>
        </div>
      </template>
      <template v-for="val in slotList" :key="val" #[`item-${val}`]="item">
        <div v-if="item[val]?.split(' ').length > 1" class="currency-amount">
          <span>{{ item[val]?.split(' ')[0] || ' ' }}</span>
          <span class="text-right">
            {{ item[val]?.split(' ')[1] || ' ' }}
          </span>
        </div>
        <div v-else>
          <span class="text-right">{{ item[val] }}</span>
        </div>
      </template>
    </EasyDataTable>
    <EasyDataTable
      v-if="thirdPartyType === 'TRUSTEE'"
      :headers="headersTrustee"
      :items="trusteeReportTableItems"
      :search-field="searchField"
      :search-value="searchValue"
      alternating
      border-cell
      header-text-direction="center"
      body-text-direction="right"
      class="custom-data-table"
    >
      <template #item-name="item">
        <div class="text-left">
          <span>{{ item.name }}</span>
        </div>
      </template>
      <template #item-totalEmirateBalance="{ totalEmirateBalance }">
        <div class="currency-amount">
          <span>{{ splitCurrencyValue(totalEmirateBalance).currency }}</span>
          <span class="text-right">
            {{ splitCurrencyValue(totalEmirateBalance).value }}
          </span>
        </div>
      </template>
      <template v-for="val in slotList" :key="val" #[`item-${val}`]="item">
        <div v-if="item[val]?.split(' ').length > 1" class="currency-amount">
          <span>{{ item[val]?.split(' ')[0] || ' ' }}</span>
          <span class="text-right">
            {{ item[val]?.split(' ')[1] || ' ' }}
          </span>
        </div>
        <div v-else>
          <span class="text-right">{{ item[val] }}</span>
        </div>
      </template>
    </EasyDataTable>
    <EasyDataTable
      v-if="thirdPartyType === 'REPORT'"
      :headers="headersReport"
      :items="reportDetails"
      :search-field="searchField"
      :search-value="searchValue"
      alternating
      border-cell
      body-text-direction="left"
      header-text-direction="center"
      class="custom-data-table"
    >
      <template #item-value="{ value }">
        <div class="currency-amount">
          <span>{{ splitCurrencyValue(value).currency }}</span>
          <span class="text-right">
            {{ splitCurrencyValue(value).value }}
          </span>
        </div>
      </template>
      <template #item-AEDEquivalent="{ AEDEquivalent }">
        <div class="currency-amount">
          <span>{{ splitCurrencyValue(AEDEquivalent).currency }}</span>
          <span class="text-right">
            {{ splitCurrencyValue(AEDEquivalent).value }}
          </span>
        </div>
      </template>
    </EasyDataTable>
  </v-container>

  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { formatNumber, unifiedResponseHandler } from '@/utils/helpers';
import Broken_Page_V01 from '../Broken_Page_V01.vue';
import free_cash_reportService from '@/services/free_cash_report.service';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';

export default {
  name: 'currency_exposure',
  components: {
    Success_Modal,
    Error_Modal,
    Broken_Page_V01,
  },
  props: ['thirdPartyType'],
  data() {
    return {
      reportResult: [],
      headersReport: [
        { text: 'Exposure', value: 'exposure' },
        { text: 'Total', value: 'value' },
        { text: 'Exchange Rate', value: 'exchangeRate' },
        { text: 'AED Equivalent', value: 'AEDEquivalent' },
      ],
      currencyTotals: {},
      message: null,
      broken_page: false,
      success: false,
      error: false,
      customerData: [],
      trusteeData: [],
      searchField: '',
      searchValue: '',
      aedEquivalentRow: {
        name: 'AED Equivalent',
      },
      trusteeTotalCurrency: [],
      customerTotalCurrency: [],
      headersCustomer: [],
      headersTrustee: [],
      totalForCurrencyExposure: null,
      customerReportTableItems: [],
      trusteeReportTableItems: [],
      reportDetails: [],
    };
  },
  methods: {
    formatReportType(thirdPartyType) {
      if (thirdPartyType === 'REPORT') {
        return 'Currency Exposure';
      } else if (thirdPartyType === 'CUSTOMER') {
        return 'Customer';
      } else if (thirdPartyType === 'TRUSTEE') {
        return 'Trustee';
      }
    },
    assignCurrencyBalance(customer, currencySymbol) {
      const balance =
        parseFloat(
          customer.thirdPartyCurrencyCredit.find(
            (item) => item.currencySymbol === currencySymbol
          )?.balance
        ) || 0;
      this.currencyTotals[currencySymbol] += balance;
      return formatNumber(balance);
    },
    async getAEDEquivalent() {
      const currencySymbols = Object.keys(this.currencyTotals);
      let totalAEDEquivalent = 0;
      for (let i = 0; i <= currencySymbols.length - 1; i++) {
        const currencySymbol = currencySymbols[i];
        const total = this.currencyTotals[currencySymbol];
        let response = await free_cash_reportService.getAEDEquivalent(
          currencySymbol,
          total
        );
        if (response && response.data) {
          totalAEDEquivalent += response.data.data;
          this.aedEquivalentRow[currencySymbol] =
            'AED' + ' ' + formatNumber(response.data.data);
          if (this.thirdPartyType === 'CUSTOMER') {
            this.customerTotalCurrency.push({
              currencySymbol,
              equivalent: response.data.data,
            });
          } else if (this.thirdPartyType === 'TRUSTEE') {
            this.trusteeTotalCurrency.push({
              currencySymbol,
              equivalent: response.data.data,
            });
          }
        } else {
          console.error(`Error getting AED equivalent for ${currencySymbol}`);
        }
      }
      this.aedEquivalentRow['totalEmirateBalance'] =
        formatNumber(totalAEDEquivalent) + ' ' + 'AED';
    },
    generateTableDetails(reportData, headers) {
      console.log('Called.');
      const dynamicHeaders = headers.slice(1);
      const formatDetailRow = (item) => {
        const details = { name: item.thirdPartyName };
        dynamicHeaders.forEach((currency) => {
          details[currency.value] = this.assignCurrencyBalance(
            item,
            currency.value
          );
        });
        details.totalEmirateBalance =
          formatNumber(item.totalEmirateBalance) + ' AED';
        return details;
      };

      const formattedData = reportData.map(formatDetailRow);
      const createCurrencyTotalsRow = () => {
        const currencyTotalsRow = { name: 'Currency Totals' };
        dynamicHeaders.forEach((currency) => {
          currencyTotalsRow[currency.value] =
            currency.value +
            ' ' +
            formatNumber(this.currencyTotals[currency.value]);
        });
        currencyTotalsRow.totalEmirateBalance = '';
        return currencyTotalsRow;
      };

      formattedData.push({ name: '', totalEmirateBalance: '' });
      formattedData.push(createCurrencyTotalsRow());
      formattedData.push(this.aedEquivalentRow);

      return formattedData;
    },
    async getHeader() {
      const handleResponse = async (serviceMethod, type) => {
        let response = unifiedResponseHandler(await serviceMethod());
        if (response.success) {
          response.data.forEach((symbol) => {
            this.currencyTotals[symbol] = 0;
          });
          response.data.forEach((symbol) => {
            this.aedEquivalentRow[symbol] = '';
          });
          const dynamicHeaders = response.data.map((symbol) => {
            return { text: symbol, value: symbol };
          });
          const staticItem = { text: type, value: 'name' };
          const staticItem2 = {
            text: 'AED Equivalent',
            value: 'totalEmirateBalance',
          };
          this[`headers${type}`] = [staticItem, ...dynamicHeaders, staticItem2];
        } else {
          this.broken_page = true;
        }
      };

      if (this.thirdPartyType === 'CUSTOMER') {
        await handleResponse(
          free_cash_reportService.getCustomerHeader,
          'Customer'
        );
      } else if (this.thirdPartyType === 'TRUSTEE') {
        await handleResponse(
          free_cash_reportService.getTrusteeHeader,
          'Trustee'
        );
      }
    },
    async getReport() {
      const handleResponse = async (serviceMethod, dataKey) => {
        const response = unifiedResponseHandler(await serviceMethod());
        if (response.success) {
          this[dataKey] = response.data;
        } else {
          this.broken_page = true;
        }
      };

      const calculateReportResults = async () => {
        const totalCustomerReport = unifiedResponseHandler(
          await free_cash_reportService.getCurrencyCustomer()
        );
        const totalTrusteeReport = unifiedResponseHandler(
          await free_cash_reportService.getCurrencyTrustee()
        );

        const customerTotals = {};
        totalCustomerReport.data.forEach((item) => {
          customerTotals[item.currencySymbol] = item.currencyTotal;
        });

        for (const item of totalTrusteeReport.data) {
          const currencySymbol = item.currencySymbol;
          const customerTotal = customerTotals[currencySymbol] || 0;
          const newTotal = item.currencyTotal - customerTotal;

          const response = await free_cash_reportService.getAEDEquivalent(
            currencySymbol,
            newTotal
          );

          if (response && response.data) {
            item.AEDEquivalent = response.data.data;
          } else {
            this.error = true;
          }

          this.reportResult.push({
            currencySymbol,
            currencyTotal: newTotal,
            AEDEquivalent: item.AEDEquivalent,
          });

          this.totalForCurrencyExposure += item.AEDEquivalent;
        }

        this.reportResult.push({
          currencySymbol: 'AED Equivalent',
          currencyTotal: '',
          AEDEquivalent: this.totalForCurrencyExposure,
        });
      };

      try {
        if (this.thirdPartyType === 'CUSTOMER') {
          await handleResponse(
            free_cash_reportService.getFreeCashReportCustomer,
            'customerData'
          );
        } else if (this.thirdPartyType === 'TRUSTEE') {
          await handleResponse(
            free_cash_reportService.getFreeCashReportTrustee,
            'trusteeData'
          );
        } else {
          await calculateReportResults();
        }
      } catch (error) {
        console.error('Error fetching report:', error);
        this.broken_page = true;
      }
    },
    computeReportDetails(reportResult) {
      return reportResult.map((item) => ({
        exposure: item.currencySymbol,
        value:
          item.currencyTotal !== ''
            ? formatNumber(item.currencyTotal) + ' ' + item.currencySymbol
            : '',
        AEDEquivalent: formatNumber(item.AEDEquivalent) + ' ' + 'AED',
        exchangeRate:
          item.currencyTotal !== ''
            ? (item.AEDEquivalent / item.currencyTotal).toFixed(2)
            : null,
      }));
    },
    splitCurrencyValue(currencyString) {
      if (!currencyString) return { currency: '', value: '' };
      const parts = currencyString.split(' ');
      return {
        currency: parts[1],
        value: parts[0],
      };
    },
  },
  async mounted() {
    await this.getHeader();
    await this.getReport();
    switch (this.thirdPartyType) {
      case 'CUSTOMER':
        this.customerReportTableItems = this.generateTableDetails(
          this.customerData,
          this.headersCustomer
        );
        break;
      case 'TRUSTEE':
        this.trusteeReportTableItems = this.generateTableDetails(
          this.trusteeData,
          this.headersTrustee
        );
        break;
      case 'REPORT':
        this.reportDetails = this.computeReportDetails(this.reportResult);
        break;
    }
    await this.getAEDEquivalent();
  },
  computed: {
    slotList() {
      return Object.keys(this.currencyTotals).filter(
        (key) => key !== 'totalEmirateBalance'
      );
    },
  },
};
</script>
