<template>
  <v-container v-if="!broken_page && !isEditing" fluid>
    <h3 class="font-weight-medium mb-2">Products</h3>
    <v-row>
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field placeholder="Search" v-model="searchValue">
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="newProduct"
          icon="mdi-plus"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="exportedData">
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
      :items="products"
      body-text-direction="center"
      header-text-direction="center"
      alternating
      border-cell
      :sort-by="headers[0].value"
      class="custom-data-table"
      :headers="headers"
      :search-value="searchValue"
      :loading="loading"
    >
      <template #item-delete="item">
        <v-icon @click="deleteProduct(item)">mdi-delete</v-icon>
      </template>
      <template #item-edit="item">
        <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
      </template>
    </EasyDataTable>
  </v-container>
  <div v-if="!broken_page && isEditing">
    <Product_edit> </Product_edit>
  </div>

  <Confirmation_Modal
    :confirmation="confirmation"
    @DoConfirmation="getConfirmation()"
    @closeConfirmationModal="confirmation = false"
  ></Confirmation_Modal>

  <Success_Modal
    v-if="success"
    :message="message"
    :success="success"
    @click="success = false"
  ></Success_Modal>

  <Error_Modal
    v-if="error"
    :error="error"
    :message="message"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { unifiedResponseHandler } from '@/utils/helpers.js';
import Confirmation_Modal from '@/components/Utils/Confirmation_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Product_edit from '@/components/HomeManagement/Setup/Product/product-edit.vue';
import ProductService from '@/services/product.service.js';
import productService from '@/services/product.service.js';

export default {
  components: { Error_Modal, Success_Modal, Product_edit, Confirmation_Modal },
  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Product Code', value: 'productCode' },
        { text: 'HSCode', value: 'hscode' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Cost Price', value: 'costPrice' },
        { text: 'Sell Price', value: 'sellPrice' },
        { text: 'Description', value: 'description' },
        { text: 'Delete', value: 'delete' },
        { text: 'Edit/Details', value: 'edit' },
      ],
      searchValue: '',
      products: [],
      loading: true,
      confirmation: false,
      confirmed: false,
      broken_page: false,
      isEditing: false,
      underEditThirdPartyGroup: null,
      responseResult: 0,
      message: null,
      success: false,
      error: false,
    };
  },
  methods: {
    async getProducts() {
      this.loading = true;
      let response = unifiedResponseHandler(
        await ProductService.getAllProducts()
      );
      if (response.success) {
        this.loading = false;
        this.products = response.data;
      } else {
        this.broken_page = true;
      }
    },

    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },

    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },

    async deleteProduct(thirdPartyGroup) {
      this.confirmation = true;
      await this.waitForConfirmation();
      if (this.confirmed) {
        this.confirmed = false;
        let response = unifiedResponseHandler(
          await productService.removeProduct(thirdPartyGroup.id)
        );
        if (response.success) {
          this.message = 'Product has been deleted successfully';
          this.success = true;
          await this.getProducts();
        } else {
          this.message = response.message;
          this.error = true;
        }
      }
    },

    async newProduct() {
      this.$store.dispatch('setIsEditingProduct', false);
      this.$store.dispatch('setChosenProduct', null);
      this.isEditing = true;
    },
    async goToEdit(item) {
      this.$store.dispatch('setChosenProduct', item);
      this.$store.dispatch('setIsEditingProduct', true);
      this.isEditing = true;
    },
  },
  computed: {
    exportedData() {
      return this.products.map(({ id, name, description }) => ({
        id,
        name,
        description,
      }));
    },
  },
  async mounted() {
    await this.getProducts();
  },
};
</script>

<style scoped></style>
