<template>
  <v-card>
    <v-form ref="edit_general_ledger_form">
      <v-card-text>
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              v-model="code"
              variant="outlined"
              readonly
              label="Code"
            >
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="label"
              label="Label"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="outlined" color="button_color" @click="editGeneralLedger">
        Edit General Ledger
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import general_ledgerService from '@/services/general_ledger.service';
import { unifiedResponseHandler } from '@/utils/helpers';
import { requiredRule } from '@/utils/rules.js';

export default {
  name: 'editGeneralLedgerLabelModal',
  props: ['ledgerToEdit'],
  data() {
    return {
      message: null,
      requiredRule: requiredRule,
      code: null,
      label: null,
    };
  },
  mounted() {
    this.code = this.ledgerToEdit.code;
    this.label = this.ledgerToEdit.label;
  },
  methods: {
    async editGeneralLedger() {
      const { valid } = await this.$refs.edit_general_ledger_form.validate();
      if (valid) {
        let form = {
          code: this.code,
          label: this.label,
        };
        let response = unifiedResponseHandler(
          await general_ledgerService.editGeneralLedger(form)
        );
        this.message = response.message;
        if (response.success) {
          this.$emit('ledgerUpdated', form);
        } else {
          this.$emit('ledgerError', this.message);
        }
      }
    },
  },
};
</script>
