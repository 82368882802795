<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import {
  buildThirdPartyObject,
  combineCodeAndNumber,
  extractCodeAndPhone,
  extractNumber,
  findCountryByCode,
  handleKeyDown,
  unifiedResponseHandler,
} from '@/utils/helpers';
import CountryService from '@/services/country.service';
import {
  emailNotRequiredRule,
  phoneCellNotRequired,
  requiredRule,
} from '@/utils/rules';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import { mapGetters } from 'vuex';
import Individual_List_Modal_V01 from '@/components/Modals/Individual_List_Modal_V01.vue';
import CountryFlag from 'vue-country-flag-next';
// import RolePickerModal from "@/components/Utils/Modals/rolePickerModal.vue";

export default {
  name: 'ThirdPartyInfoForm',
  components: {
    // RolePickerModal,
    Individual_List_Modal_V01,
    Error_Modal,
    Success_Modal,
    VueDatePicker,
    CountryFlag,
  },
  props: {},
  data() {
    return {
      isFormDisabled: true,
      thirdPartyTypes: [
        { name: 'Customer', id: 0 },
        { name: 'Trustee', id: 1 },
        { name: 'Payer/Payee', id: 2 },
        { name: 'Safe Box', id: 3 },
        { name: 'Income Center', id: 4 },
        { name: 'Cost Center', id: 5 },
      ],
      thirdPartyInfo: {
        fullName: null,
        creditLimit: null,
        selectedType: null,
        customerClubJoinDate: null,
        url: null,
        identificationCode: null,
        selectedCountry: null,
        email: null,
        officeAddress: null,
        internationalCodePhoneNumber: null,
        phoneNumber: null,
        introducer: null,
        activity: null,
        visibleTo: null,
      },
      countries: [],
      message: null,
      error: false,
      success: false,
      individualListModal: false,
    };
  },
  computed: {
    ...mapGetters(['chosen_third_party', 'is_editing_third_party']),
    introducerFullName() {
      if (this.thirdPartyInfo.introducer) {
        return (
          this.thirdPartyInfo.introducer.firstName +
          ' ' +
          this.thirdPartyInfo.introducer.lastName
        );
      } else {
        return null;
      }
    },
    introducerId() {
      if (this.thirdPartyInfo.introducer) {
        return this.thirdPartyInfo.introducer.id;
      } else {
        return null;
      }
    },
  },
  methods: {
    individualChosen(item) {
      this.thirdPartyInfo.introducer = item;
      this.individualListModal = false;
    },
    emailNotRequiredRule() {
      return emailNotRequiredRule;
    },
    handleKeyDown,
    clearIntroducer() {
      console.log('clean');
      this.thirdPartyInfo.introducer = null;
    },
    phoneCellNotRequired() {
      return phoneCellNotRequired;
    },
    requiredRule() {
      return requiredRule;
    },
    handleDelete() {
      const currentCreditLimit = this.thirdPartyInfo.creditLimit || '';
      const parts = currentCreditLimit.split(' ');
      if (parts.length === 2 && parts[0].length > 0) {
        const updatedNumericPart = parts[0].slice(0, -1);
        this.thirdPartyInfo.creditLimit = updatedNumericPart + ' ' + parts[1];
      }
    },
    async getCountries() {
      let response = unifiedResponseHandler(
        await CountryService.getCountries()
      );
      if (response.success) {
        this.countries = response.data;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async addThirdParty() {
      console.log(this.$refs.new_third_party_form);
      const { valid } = await this.$refs.new_third_party_form.validate();
      if (valid) {
        let form = {
          Id: this.Id,
          fullName: this.thirdPartyInfo.fullName,
          identificationCode: this.thirdPartyInfo.identificationCode,
          thirdPartyType: this.thirdPartyInfo.selectedType.id,
          url: this.thirdPartyInfo.url,
          countryId: this.thirdPartyInfo.selectedCountry.id,
          activity: this.thirdPartyInfo.activity,
          introducerId: this.introducerId,
          email: this.thirdPartyInfo.email,
          officeAddress: this.thirdPartyInfo.officeAddress,
          phoneNumber: this.phoneNumberWithCode(),
          creditLimit: extractNumber(this.thirdPartyInfo.creditLimit),
          note: this.thirdPartyInfo.note,
          customerClubJoinDate: this.thirdPartyInfo.customerClubJoinDate,
        };
        this.$emit('addThirdParty', form);
      }
    },
    phoneNumberWithCode() {
      return combineCodeAndNumber(
        this.thirdPartyInfo.internationalCodePhoneNumber,
        this.thirdPartyInfo.phoneNumber
      );
    },
  },
  async mounted() {
    await this.getCountries();
    if (this.is_editing_third_party) {
      this.Id = this.chosen_third_party.id;
      this.thirdPartyInfo.fullName = this.chosen_third_party.fullName;
      this.thirdPartyInfo.identificationCode =
        this.chosen_third_party.identificationCode;
      this.thirdPartyInfo.activity = this.chosen_third_party.activity;
      this.thirdPartyInfo.email = this.chosen_third_party.email;
      this.thirdPartyInfo.officeAddress = this.chosen_third_party.officeAddress;
      this.thirdPartyInfo.note = this.chosen_third_party.note;
      this.thirdPartyInfo.phoneNumber = this.chosen_third_party.phoneNumber;
      if (this.chosen_third_party.phoneNumber) {
        this.thirdPartyInfo.phoneNumber = extractCodeAndPhone(
          this.chosen_third_party.phoneNumber
        ).phoneNumber;
        this.thirdPartyInfo.internationalCodePhoneNumber = findCountryByCode(
          this.countries,
          extractCodeAndPhone(this.chosen_third_party.phoneNumber).countryCode
        );
      }
      this.thirdPartyInfo.customerClubJoinDate =
        this.chosen_third_party.customerClubJoinDate;
      this.thirdPartyInfo.creditLimit = this.chosen_third_party.creditLimit;
      if (this.chosen_third_party.introducedBy) {
        this.thirdPartyInfo.introducer = this.chosen_third_party.introducedBy;
      }
      if (this.chosen_third_party.country) {
        this.thirdPartyInfo.selectedCountry = this.chosen_third_party.country;
      }
      this.thirdPartyInfo.selectedType = buildThirdPartyObject(
        this.chosen_third_party.thirdPartyType
      );
    }
    this.isFormDisabled = false;
  },
};
</script>

<template>
  <v-form ref="new_third_party_form" class="mt-4" :disabled="isFormDisabled">
    <v-row>
      <v-col md="6" sm="12" xm="12">
        <v-text-field
          v-model="thirdPartyInfo.fullName"
          :rules="requiredRule()"
          clearable
          label="Full Name"
          required
        ></v-text-field>

        <v-text-field
          v-model="thirdPartyInfo.creditLimit"
          v-maska="moneyOption"
          label="Credit Limit"
          @keydown.delete="handleDelete"
        ></v-text-field>

        <v-autocomplete
          v-model="thirdPartyInfo.selectedType"
          :items="thirdPartyTypes"
          :rules="requiredRule()"
          filled
          item-title="name"
          item-value="id"
          label="Select Type"
          return-object
        >
        </v-autocomplete>
        <v-text-field
          v-model="thirdPartyInfo.url"
          label="URL"
          required
        ></v-text-field>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <VueDatePicker
            placeholder="Select Club Join Date"
            class="element-width"
            v-model="thirdPartyInfo.customerClubJoinDate"
            auto-apply="true"
            model-type="yyyy-MM-dd"
            :enable-time-picker="false"
          ></VueDatePicker>
        </div>

        <v-textarea
          v-model="thirdPartyInfo.note"
          class="mt-4"
          label="Note"
        ></v-textarea>
      </v-col>
      <v-col md="6" sm="12" xm="12">
        <v-text-field
          v-model="thirdPartyInfo.activity"
          label="activity"
        ></v-text-field>

        <v-text-field
          v-model="thirdPartyInfo.identificationCode"
          label="Identification Code"
        ></v-text-field>
        <v-autocomplete
          v-model="thirdPartyInfo.selectedCountry"
          :items="countries"
          filled
          item-title="name"
          item-value="id"
          label="Select Country"
          return-object
          :rules="requiredRule()"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props">
              <template v-slot:append>
                <country-flag :country="item.raw.code"></country-flag>
              </template>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-tab
          color="button_color"
          prepend-icon="mdi-magnify"
          @click="this.individualListModal = true"
          >select
        </v-tab>

        <v-text-field
          v-model="introducerFullName"
          label="Introducer"
          @keydown="handleKeyDown"
          @keyup.backspace="clearIntroducer"
        ></v-text-field>
        <v-text-field
          v-model="thirdPartyInfo.email"
          :rules="emailNotRequiredRule()"
          label="Email"
        ></v-text-field>
        <v-text-field
          v-model="thirdPartyInfo.officeAddress"
          label="Office Address"
        ></v-text-field>

        <v-col cols="3" md="3" sm="3" style="display: inline-block" xs="3">
          <v-autocomplete
            v-model="thirdPartyInfo.internationalCodePhoneNumber"
            :items="countries"
            filled
            item-title="name"
            item-value="internationalCode"
            label="Intl. Code"
            return-object
          >
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props">
                <template v-slot:append>
                  (+{{ item.raw.internationalCode }})
                  <country-flag
                    :country="item.raw.code"
                    style="margin-left: 5px"
                  ></country-flag>
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="9" md="9" sm="9" style="display: inline-block" xs="9">
          <v-text-field
            v-model="thirdPartyInfo.phoneNumber"
            :rules="phoneCellNotRequired()"
            label="Phone Number"
          ></v-text-field>
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mt-4 mb-4" justify="end">
      <v-btn
        v-if="!is_editing_third_party"
        class="mr-4"
        color="button_color"
        @click="addThirdParty"
        >Create Third Party
      </v-btn>
      <v-btn v-else class="mr-4" color="button_color" @click="addThirdParty"
        >Edit Third Party
      </v-btn>
    </v-row>
  </v-form>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
    :error="error"
    :message="message"
    @closeErrorModal="error = false"
  ></Error_Modal>
  <v-dialog v-model="individualListModal" width="auto">
    <Individual_List_Modal_V01
      @individualChosen="individualChosen"
    ></Individual_List_Modal_V01>
  </v-dialog>
</template>

<style scoped>
.element-width {
  flex: 0 0 48%; /* flex-grow: 0, flex-shrink: 0, flex-basis: 50% */
}
</style>
