import axios from 'axios';
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class accountTypeService {
  async getAccountTypes() {
    try {
      return await axios.get(BASE_URL + 'accountType/getAll', {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async findAccountTypeByDescription(description) {
    try {
      return await axios.get(BASE_URL + 'accountType/search', {
        headers: authHeader(),
        params: {
          description: description,
        },
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async editAccountType(add_account_type) {
    console.log('add account form', add_account_type);
    try {
      return await axios.post(BASE_URL + 'accountType/edit', add_account_type, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async deleteAccountType(id) {
    try {
      let response = await axios.delete(BASE_URL + 'accountType/delete', {
        headers: authHeader(),
        params: {
          accountTypeId: id,
        },
      });

      return response;
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async addAccountType(add_account_type) {
    try {
      return await axios.post(BASE_URL + 'accountType/add', add_account_type, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new accountTypeService();
