<script>
import { requiredRule } from '@/utils/rules.js';
import { unifiedResponseHandler } from '@/utils/helpers.js';
import BallAnimation from '@/components/Login/ball-animation.vue';
import AuthService from '@/services/auth.service.js';

export default {
  name: 'change-password-form',
  components: { BallAnimation },
  props: ['userEmail'],
  data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
      showPassword: false,
      showConfirmPassword: false,
      showBallAnimation: false,
      otp: null,
      errorMessage: null,
      successMessage: null,
    };
  },
  methods: {
    requiredRule() {
      return requiredRule;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    clearErrorMessage() {
      this.errorMessage = null;
    },
    async updatePassword() {
      if (await this.$refs.change_password_form.validate()) {
        if (
          this.password !== this.confirmPassword ||
          this.password === null ||
          this.confirmPassword === null
        ) {
          this.errorMessage = 'Passwords do not match!';
          return;
        }
        if (this.otp.length !== 6) {
          this.errorMessage = 'Invalid OTP!';
          return;
        }
        this.showBallAnimation = true;
        let form = {
          email: this.userEmail,
          oldPass: null,
          newPass: this.confirmPassword,
          otp: this.otp,
        };
        this.showBallAnimation = true;
        let response = unifiedResponseHandler(
          await AuthService.changePasswordViaOTP(form)
        );
        this.showBallAnimation = false;
        if (response.success) {
          this.successMessage = response.message;
          this.$emit('passwordChanged');
        } else {
          this.errorMessage = response.errorMessage;
        }
      }
    },
  },
};
</script>

<template>
  <v-form fast-fail @submit.prevent ref="change_password_form" class="pa-4">
    <h2
      class="font-weight-medium"
      style="text-align: center; margin-right: 5px"
    >
      Enter your New Password
    </h2>

    <v-text-field
      v-model="otp"
      type="otp"
      label="Enter OTP"
      :rules="requiredRule()"
      @input="clearErrorMessage"
      @focus="clearErrorMessage"
      style="margin-top: 16px"
    />

    <v-text-field
      :type="showPassword ? 'text' : 'password'"
      v-model="password"
      label="New Password"
      :rules="requiredRule()"
      class="password-field"
      @input="clearErrorMessage"
      @focus="clearErrorMessage"
    >
      <v-icon @click="togglePasswordVisibility" class="v-icon" small
        >{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
      </v-icon>
    </v-text-field>

    <v-text-field
      :type="showConfirmPassword ? 'text' : 'password'"
      v-model="confirmPassword"
      label="Confirm Password"
      :rules="requiredRule()"
      class="password-field"
      @input="clearErrorMessage"
      @focus="clearErrorMessage"
    >
      <v-icon @click="togglePasswordVisibility" class="v-icon" small
        >{{ showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off' }}
      </v-icon>
    </v-text-field>

    <ball-animation v-if="showBallAnimation" />
    <v-btn
      block
      color="button_color"
      type="submit"
      class="mt-8"
      @click="updatePassword()"
    >
      Change Password
    </v-btn>
    <p
      v-if="errorMessage != null"
      style="
        color: red;
        margin-top: 10px;
        margin-left: 5px;
        font-weight: 500;
        text-align: center;
      "
    >
      {{ errorMessage }}
    </p>

    <p
      v-if="successMessage != null"
      style="
        color: #08ad08;
        margin-top: 10px;
        margin-left: 5px;
        font-weight: 500;
        text-align: center;
      "
    >
      {{ successMessage }}
    </p>
  </v-form>
</template>

<style scoped>
.password-field {
  position: relative;
}

.v-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
