<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium mb-2">Transaction Types</h3>
      <v-row>
        <v-col cols="12" md="4" sm="8">
          <div class="d-flex">
            <v-text-field placeholder="Search" v-model="searchValue">
              <template v-slot:prepend-inner>
                <v-icon
                  icon="mdi-magnify"
                  variant="tonal"
                  color="button_color"
                  class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            color="button_color"
            @click="newTransactionTypeModal = true"
            icon="mdi-plus"
            class="ml-2"
          >
          </v-btn>
          <v-btn
            color="button_color"
            @click="QTransactionTypeModal = true"
            icon="mdi-help"
            class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="transactionTypesDetails"
              ><v-icon>mdi-export</v-icon></download-excel
            >
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
        style=""
        :headers="headers"
        :items="transactionTypesDetails"
        :search-field="searchField"
        :search-value="searchValue"
        body-text-direction="center"
        header-text-direction="center"
        alternating
        border-cell
        class="custom-data-table"
        :loading="loading"
      >
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="deleteTransactionType(item)">mdi-delete</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="newTransactionTypeModal" width="auto">
      <New_Transaction_Type_Modal_V01></New_Transaction_Type_Modal_V01>
    </v-dialog>
    <v-dialog v-model="QTransactionTypeModal" width="auto">
      <Q_TransactionType_Modal_V01></Q_TransactionType_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import Q_TransactionType_Modal_V01 from '@/components/ModalQ/Q_TransactionType_Modal_V01.vue';
import { unifiedResponseHandler } from '@/utils/helpers';
import transactionService from '@/services/transaction.service';
import New_Transaction_Type_Modal_V01 from '../../Modals/New_Transaction_Type_Modal_V01.vue';
import Broken_Page_V01 from '../../Broken_Page_V01.vue';
import emitter from '@/plugins/mitt';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
export default {
  name: 'transaction_type_list_v01',
  components: {
    Error_Modal,
    Success_Modal,
    New_Transaction_Type_Modal_V01,
    Broken_Page_V01,
    Q_TransactionType_Modal_V01,
  },
  data() {
    return {
      headers: [
        { text: 'Description', value: 'description' },
        { text: 'Ledger Direction', value: 'ledgerDirection' },
        { text: 'Account Direction', value: 'accountDirection' },
        { text: 'Ledger Code', value: 'ledgerCode' },
        { text: 'Edit/Details', value: 'edit' },
        { text: 'Delete', value: 'delete' },
      ],
      searchField: '',
      searchValue: '',
      message: null,
      broken_page: false,
      success: false,
      error: false,
      QTransactionTypeModal: false,
      transactionTypes: [],
      newTransactionTypeModal: false,
      loading: false,
    };
  },

  methods: {
    goToEdit(new_transaction_type) {
      this.$store.dispatch('setChosenTransactionType', new_transaction_type);
      this.$store.dispatch('setIsEditingTransactionType', true);
      this.newTransactionTypeModal = true;
    },

    async deleteTransactionType(item) {
      let response = unifiedResponseHandler(
        await transactionService.deleteTransactionType(item.id)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        await this.getTransactionTypes();
      } else {
        this.error = true;
      }
    },

    async getTransactionTypes() {
      this.loading = true;
      let response = unifiedResponseHandler(
        await transactionService.getTransactionTypes()
      );
      if (response.success) {
        this.loading = false;
        console.log('backend response', response.data);
        this.transactionTypes = response.data.sort((a, b) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return 0;
        });
      } else {
        this.broken_page = true;
      }
    },
  },

  async mounted() {
    await this.getTransactionTypes();
    emitter.on('new_transaction_type', (value) => {
      this.newTransactionTypeModal = false;
      this.message = value.message;
      this.success = true;
      this.getTransactionTypes();
    });
  },
  computed: {
    transactionTypesDetails() {
      return this.transactionTypes.map((transactionType) => ({
        id: transactionType.id,
        description: transactionType.description,
        ledgerDirection: transactionType.ledgerDirection,
        ledgerCode: transactionType.generalLedgerPrefix,
        accountDirection: transactionType.accountDirection,
      }));
    },
  },
};
</script>
