<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card
          class="mx-auto mt-10"
          max-width="344"
          color="side_color"
          @click="setPage('bills_history')"
        >
          <v-card-text>
            <div>{{ $t('Accounting') }}</div>
            <p class="text-h5 text--primary">{{ $t('Bills History') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('bills_history')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          class="mx-auto mt-8"
          max-width="344"
          color="side_color"
          @click="setPage('bill_payment')"
        >
          <v-card-text>
            <div>{{ $t('Accounting') }}</div>
            <p class="text-h5 text--primary">{{ $t('Bill Payment') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('bill_payment')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          class="mx-auto mt-8"
          max-width="344"
          color="side_color"
          @click="setPage('manual_transaction')"
        >
          <v-card-text>
            <div>{{ $t('Accounting') }}</div>
            <p class="text-h5 text--primary">{{ $t('Manual Transaction') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('manual_transaction')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <div
          style="
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 2rem;
          "
        >
          <h1 class="font-weight-medium">Accounting</h1>
          <v-img
            :width="600"
            aspect-ratio="1"
            cover
            :src="getImageUrl('Calculator-g', 'svg')"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <v-card
          class="mx-auto mt-10"
          max-width="344"
          color="side_color"
          @click="setPage('customer_SOA')"
        >
          <v-card-text color="grey-lighten-4">
            <div>{{ $t('Accounting') }}</div>
            <p class="text-h5 text--primary">{{ $t('Customer SOA') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('customer_SOA')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          color="side_color mt-8"
          class="mx-auto"
          max-width="344"
          @click="setPage('trustee_SOA')"
        >
          <v-card-text>
            <div>{{ $t('Accounting') }}</div>
            <p class="text-h5 text--primary">{{ $t('Trustee SOA') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('trustee_SOA')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          class="mx-auto mt-8"
          max-width="344"
          color="side_color"
          @click="setPage('journals')"
        >
          <v-card-text>
            <div>{{ $t('Accounting') }}</div>
            <p class="text-h5 text--primary">{{ $t('Journals') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('journals')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { setPage } from '@/utils/helpers';
import { getImageUrl } from '@/utils/NewFunctions/utils.js';

export default {
  name: 'accounting_home_page_v01',
  methods: {
    getImageUrl,
    setPage(page) {
      setPage(page);
    },
  },
  data() {},
};
</script>
