<template>
  <v-container fluid>
    <h3 class="mb-2 font-weight-medium">
      {{ internalTransferTypeFormat(internalTransferType) }}
    </h3>
    <br />
    <internal-transfer-form
      :is-past-form="true"
      current-status="NOT INITIATED"
      :internal-transfer-type="this.internalTransferType"
      :is_editing_internal_transfer="false"
      :chosen_internal_transfer="null"
      @returnFormData="captureForms"
    >
    </internal-transfer-form>

    <v-row justify="end">
      <div class="d-flex top-20">
        <v-btn class="ml-4" color="button_color" @click="showReport = true">
          Add Transaction
        </v-btn>
        <v-btn class="ml-4" color="button_color" @click="clearFormFields()">
          Cancel
        </v-btn>
      </div>
    </v-row>
    <v-dialog v-model="showReport" max-width="720">
      <past-internal-transfer-confirmation-modal
        :internal-transfer-type="this.internalTransferType"
        :register-form="this.registerForm"
        @close="showReport = false"
        @confirm="addRequest()"
      ></past-internal-transfer-confirmation-modal>
    </v-dialog>
  </v-container>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { formatNumberInternal, unifiedResponseHandler } from '@/utils/helpers';

import { mapGetters } from 'vuex';
import internal_transferService from '@/services/internal_transfer.service';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import InternalTransferForm from '@/components/MoneyTransfer/transfer-forms/InternalTransfer/internal-transfer-form.vue';
import emitter from '@/plugins/mitt.js';
import PastInternalTransferConfirmationModal from '@/components/PastOperation/pastInternalTransferConfirmationModal.vue';

export default {
  name: 'p_internal_transfer_v01',
  props: ['internalTransferType'],
  components: {
    PastInternalTransferConfirmationModal,
    InternalTransferForm,
    Error_Modal,
    Success_Modal,
  },

  data() {
    return {
      error: false,
      success: false,
      message: null,
      showReport: false,
      registerForm: null,
      reconcileForm: null,
    };
  },
  methods: {
    formatNumberInternal,
    captureForms({
      internalTransferRegistrationForm,
      internalTransferReconciliationForm,
    }) {
      this.registerForm = internalTransferRegistrationForm;
      this.reconcileForm = internalTransferReconciliationForm;
    },
    internalTransferTypeFormat(internalTransferType) {
      let transactionTypeDisplay = '';
      if (internalTransferType === 'TRUSTEE_TO_TRUSTEE') {
        transactionTypeDisplay = 'Past Internal Trustee';
      } else if (internalTransferType === 'CUSTOMER_TO_CUSTOMER') {
        transactionTypeDisplay = 'Past Internal Customer';
      }
      return transactionTypeDisplay;
    },
    clearFormFields() {
      emitter.emit('clearForm');
    },
    async addRequest() {
      let form = this.registerForm;
      if (!form) return;
      this.showReport = false;
      let response = unifiedResponseHandler(
        await internal_transferService.pastInternalTransfer(form)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.clearFormFields();
      } else {
        this.error = true;
      }
    },
  },
  computed: {
    ...mapGetters(['page']),
  },
  beforeUnmount() {
    this.$store.dispatch('setChosenInternalTransfer', null);
    this.$store.dispatch('setIsEditingInternalTransfer', false);
  },
};
</script>
