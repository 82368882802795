import axios from 'axios';
import { authHeader } from './auth-header';
import { BASE_URL } from '@/config/endpoints';

class manualTransferService {
  async addManualTransfer(form) {
    try {
      return await axios.post(BASE_URL + 'manualTransfer/add', form, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getAll(page, size) {
    try {
      return await axios.get(BASE_URL + 'manualTransfer/getAll', {
        params: {
          page: page,
          size: size,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async searchByAccountCofferNameOrThirdPartyName(searchValue, page, size) {
    try {
      return await axios.get(BASE_URL + 'manualTransfer/search', {
        params: {
          searchField: searchValue,
          page: page,
          size: size,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async createManualTransfer(form) {
    try {
      return await axios.post(BASE_URL + 'manualTransaction/create', form, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getAllManualTransaction(page, size) {
    try {
      return await axios.get(BASE_URL + 'manualTransaction/getAll', {
        params: {
          page: page,
          size: size,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async getManualTransactionById(id) {
    try {
      return await axios.get(BASE_URL + 'manualTransaction/get', {
        params: {
          id,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }
}

export default new manualTransferService();
