<template>
  <v-form fast-fail @submit.prevent ref="verify_email_form" class="pa-4">
    <h2
      class="font-weight-medium"
      style="text-align: center; margin-right: 5px"
    >
      Reset Your Password
    </h2>
    <v-text-field
      v-model="email"
      placeholder="Enter Your Email"
      :rules="emailRule"
      @input="clearErrorMessage"
      @focus="clearErrorMessage"
      style="margin-top: 16px"
    />
    <ball-animation v-if="showBallAnimation" />
    <v-btn
      block
      color="button_color"
      type="submit"
      class="mt-8"
      @click="sendOtpRequest()"
    >
      Send OTP
    </v-btn>
    <p
      style="
        color: red;
        margin-top: 10px;
        margin-left: 5px;
        font-weight: 500;
        text-align: center;
      "
    >
      {{ message }}
    </p>
  </v-form>
</template>
<script>
import { emailRule, requiredRule } from '@/utils/rules.js';
import { unifiedResponseHandler } from '@/utils/helpers.js';
import authService from '@/services/auth.service.js';
import BallAnimation from '@/components/Login/ball-animation.vue';

export default {
  name: 'verify-email-form',
  components: { BallAnimation },
  data() {
    return {
      email: null,
      emailRule: emailRule,
      message: null,
      showBallAnimation: false,
    };
  },
  methods: {
    requiredRule() {
      return requiredRule;
    },
    clearErrorMessage() {
      this.otpError = false;
      this.message = null;
    },
    async sendOtpRequest() {
      const { valid } = await this.$refs.verify_email_form.validate();
      if (valid) {
        try {
          this.showBallAnimation = true;
          const response = unifiedResponseHandler(
            await authService.sendOTP(this.email)
          );
          if (response.success) {
            this.$emit('otpSent', this.email);
          } else {
            this.message = response.message;
          }
        } catch (error) {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      }
      this.showBallAnimation = false;
    },
  },
  watch: {
    email() {
      this.message = null;
    },
  },
};
</script>
<style scoped>
input {
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
</style>
