<template>
  <v-container fluid>
    <h3 class="font-weight-medium">Meetings</h3>
    <v-row class="mb-1">
      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="createMeeting"
          icon="mdi-plus"
          class="mr-2"
        ></v-btn>
        <v-btn color="button_color" icon="mdi-export">
          <download-excel :data="excelOutput">
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <vue-cal
      :events="generateCalenderObjects()"
      active-view="week"
      :time-from="6 * 60"
      :disable-views="['years', 'year']"
      :time-step="30"
      today-button
      :locale="customLocale"
      events-on-month-view="short"
      :on-event-click="handleEventClick"
      style="min-height: 1000px"
    >
      <template #event="{ event, view }">
        <template v-if="view === 'month'">
          <div @mousedown.stop.prevent>
            <v-chip
              @mouseenter="disableTooltip"
              v-bind="event"
              class="d-inline-flex align-center text-caption"
              @click="handleEventClick(event)"
            >
              <v-badge dot inline :color="event.color"></v-badge>
              {{ event.title }}
            </v-chip>
          </div>
        </template>
      </template>
      <template #today-button>
        <v-tooltip>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              style="position: absolute; right: 50px; display: inline-flex"
              text="Today"
            >
            </v-btn>
          </template>
        </v-tooltip>
      </template>
    </vue-cal>
    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="closeModals()"
    ></Success_Modal>
    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="meetingDetailsModal" width="auto">
      <meeting-details-modal
        @editMeeting="handleEditMeetingSignal"
        @deleteMeeting="handleDeleteModalSignal"
        :meeting="this.clickedMeeting"
      ></meeting-details-modal>
    </v-dialog>
    <v-dialog v-model="createMeetingModal" max-width="700">
      <create-meeting-modal
        @closeModal="this.createMeetingModal = false"
        @successfulMeetingOperation="this.getAllMeetings()"
        :meeting-to-edit="meetingToEdit"
      ></create-meeting-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import meetingService from '@/services/meeting.service.js';
import { unifiedResponseHandler } from '@/utils/helpers.js';
import MeetingDetailsModal from '@/components/HomeManagement/MyDashboard/meeting-details-modal.vue';
import CreateMeetingModal from '@/components/HomeManagement/MyDashboard/create-meeting-modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

export default {
  name: 'meeting_v01',
  components: {
    Success_Modal,
    Error_Modal,
    CreateMeetingModal,
    MeetingDetailsModal,
    VueCal,
  },
  data: () => ({
    events: [],
    message: '',
    excelOutput: [],
    error: false,
    success: false,
    clickedMeeting: null,
    meetingDetailsModal: false,
    createMeetingModal: false,
    meetingToEdit: null,
    timeFontSize: '10px',
    customLocale: {
      weekDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      weekStart: 6,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      year: 'Year',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      today: 'Today',
      noEvent: 'No Event',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
        dayPopover: 'L',
      },
    },
  }),

  async mounted() {
    await this.getAllMeetings();
    this.generateCalenderObjects();
    this.createOutputData();
  },

  methods: {
    disableTooltip(event) {
      const element = event.target;
      element.dataset.originalTitle = element.getAttribute('title'); // Save the title
      element.removeAttribute('title'); // Remove the title
    },
    closeModals() {
      this.success = false;
      this.meetingDetailsModal = false;
      this.createMeetingModal = false;
    },
    handleEditMeetingSignal(data) {
      this.meetingDetailsModal = false;
      this.editMeeting(data);
    },
    handleDeleteModalSignal(data) {
      this.deleteMeeting(data);
    },
    generateCalenderObjects() {
      const generatedEvents = [];
      for (let i = 0; i < this.events.length; i++) {
        const startTime = new Date(this.events[i].startTime);
        const endTime = new Date(this.events[i].endTime);
        const durationInMinutes = (endTime - startTime) / (1000 * 60);
        const fontSize = durationInMinutes < 70;
        let styleClass = this.events[i].importanceLevel;
        if (fontSize) styleClass += ' small-font';
        generatedEvents.push({
          id: i,
          title: this.events[i].title.slice(0, 25),
          start: startTime,
          end: endTime,
          class: styleClass,
          color: this.getColor(this.events[i].importanceLevel),
        });
      }
      return generatedEvents.sort((a, b) => a.start - b.start);
    },

    getColor(importanceLevel) {
      switch (importanceLevel) {
        case 'LOW':
          return 'blue';
        case 'MEDIUM':
          return 'green';
        case 'HIGH':
          return '#e0b000';
        case 'CRITICAL':
          return 'red';
        default:
          return 'gray';
      }
    },

    async getAllMeetings() {
      let response = unifiedResponseHandler(
        await meetingService.getAllMeetings()
      );
      if (response.success) this.events = response.data;
    },
    createOutputData() {
      this.excelOutput = this.events.map((event) => {
        return {
          Id: event.id,
          Title: event.title,
          Note: event.note,
          StartTime: event.startTime,
          EndTime: event.endTime,
          ImportanceLevel: event.importanceLevel,
          CreatedBy: event.createdBy.firstName + ' ' + event.createdBy.lastName,
        };
      });
    },

    async deleteMeeting(id) {
      let response = unifiedResponseHandler(
        await meetingService.deleteMeeting(id)
      );
      if (response.success) {
        this.message = response.data;
        this.success = true;
        await this.getAllMeetings();
      } else {
        this.message = response.message;
        this.error = true;
      }
    },

    handleEventClick(event) {
      this.clickedMeeting = this.events[event.id];
      this.meetingDetailsModal = true;
    },

    createMeeting() {
      this.meetingToEdit = null;
      this.createMeetingModal = true;
    },

    editMeeting(meeting) {
      this.meetingToEdit = meeting;
      this.createMeetingModal = true;
    },
  },
};
</script>
