<script>
import { requiredRule } from '@/utils/rules.js';
import userService from '@/services/user.service.js';
import { unifiedResponseHandler } from '@/utils/helpers.js';
import { authEmail, authRole } from '@/services/auth-header.js';
import AuthService from '@/services/auth.service.js';

export default {
  name: 'change-password-modal',
  props: ['userUnderEdit'],
  data() {
    return {
      windowWidth: window.innerWidth,
      oldPass: null,
      newPass: null,
      confirmNewPass: null,
      requiredRule: requiredRule,
      message: null,
      authRole: null,
      authEmail: null,
      success: false,
      error: false,
      showOldPass: false,
      showNewPass: false,
      showConfirmNewPass: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showOldPass = !this.showOldPass;
    },
    togglePasswordVisibilityShowNewPass() {
      this.showNewPass = !this.showNewPass;
    },
    togglePasswordVisibilityShowConfirmNewPass() {
      this.showConfirmNewPass = !this.showConfirmNewPass;
    },
    logout() {
      AuthService.logout();
      this.$router.push('login_v01');
    },
    async confirmChangePass() {
      let change_pass_form = {
        email: this.userUnderEdit ? this.userUnderEdit.email : this.authEmail,
        oldPass: this.oldPass,
        newPass: this.newPass,
        isOtpVerified: false,
      };
      const { valid } = await this.$refs.change_pass_form.validate();
      if (this.newPass === this.confirmNewPass) {
        if (valid) {
          let response = unifiedResponseHandler(
            await userService.changePassword(change_pass_form)
          );
          if (response.success) {
            if (
              this.userUnderEdit == null ||
              this.userUnderEdit.email === this.authEmail
            ) {
              this.$refs.change_pass_form.reset();
              this.logout();
            } else {
              this.message = 'Password updated successfully';
              this.success = true;
            }
          } else {
            this.error = true;
            this.message = response.message;
          }
        }
      } else {
        this.error = true;
        this.message = "The old and new password didn't match";
      }
    },
    clearResultValues() {
      this.error = false;
      this.success = false;
      this.message = null;
    },
  },
  mounted() {
    this.authEmail = authEmail();
    this.authRole = authRole();
  },
  watch: {
    newPass() {
      this.clearResultValues();
    },
    confirmNewPass() {
      this.clearResultValues();
    },
    oldPass() {
      this.clearResultValues();
    },
  },
};
</script>

<template>
  <v-card width="400px">
    <v-form fast-fail class="pa-md-12" ref="change_pass_form">
      <v-text-field
        v-if="!userUnderEdit"
        :type="showOldPass ? 'text' : 'password'"
        v-model="oldPass"
        :rules="authRole === 'ADMIN' ? [] : requiredRule"
        label="old password"
        class="password-field"
      >
        <v-icon @click="togglePasswordVisibility" class="v-icon pass-icon" small
          >{{ showOldPass ? 'mdi-eye' : 'mdi-eye-off' }}
        </v-icon>
      </v-text-field>
      <v-text-field
        :type="showNewPass ? 'text' : 'password'"
        :rules="requiredRule"
        v-model="newPass"
        label="new password"
        class="password-field"
      >
        <v-icon
          @click="togglePasswordVisibilityShowNewPass"
          class="v-icon pass-icon"
          small
          >{{ showNewPass ? 'mdi-eye' : 'mdi-eye-off' }}
        </v-icon>
      </v-text-field>
      <v-text-field
        :type="showConfirmNewPass ? 'text' : 'password'"
        :rules="requiredRule"
        v-model="confirmNewPass"
        label="confirm new password"
        class="password-field"
      >
        <v-icon
          @click="togglePasswordVisibilityShowConfirmNewPass"
          class="v-icon pass-icon"
          small
          >{{ showConfirmNewPass ? 'mdi-eye' : 'mdi-eye-off' }}
        </v-icon>
      </v-text-field>

      <v-btn color="button_color" @click="confirmChangePass" block class="mt-2"
        >{{ 'confirm' }}
      </v-btn>
      <br />
      <span v-if="error" style="color: red">{{ message }}</span>
      <span v-if="success" style="color: green">{{ message }}</span>
    </v-form>
  </v-card>
</template>

<style scoped>
.password-field {
  position: relative;
}

.pass-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the right distance as needed */
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
