<template>
  <v-sheet max-width="800" min-width="600" style="padding: 20px">
    <div class="border-1">
      <h4 class="border-h4">Money Transfer Summary</h4>
      <div class="grid-card-2" ref="pdfContent">
        <div
          class="summary-row"
          v-for="(value, label) in summaryData"
          :key="label"
        >
          <span style="flex: 1">{{ label }}:</span>
          <span style="flex: 1; text-align: left">{{ value }}</span>
        </div>
      </div>
      <v-row justify="end" align="center">
        <v-btn class="mr-4" color="button_color" @click="generatePDF"
          >PDF
        </v-btn>
        <v-btn
          v-if="chosen_request.status === 'WAITING_FOR_APPROVAL'"
          @click="approveRequest"
          class="mr-4"
          color="button_color"
        >
          {{ $t('confirm') }} request
        </v-btn>
        <v-btn
          v-if="attachmentExists"
          class="mr-4"
          variant="tonal"
          color="button_color"
          @click="viewAttachment"
          icon
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-if="attachmentExists"
          variant="tonal"
          class="mr-4"
          color="button_color"
          @click="downloadAttachment"
          icon
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-row>
    </div>
  </v-sheet>

  <v-dialog v-model="showDialog" :width="dialogWidth" :height="dialogHeight">
    <attachmentPreviewModal
      :attachment="createFileFromBase64(chosen_request.attachment)"
      @dimensions="updateDimensions"
    >
    </attachmentPreviewModal>
  </v-dialog>

  <Error_Modal
    :error="error"
    :message="message"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { formatNumber, unifiedResponseHandler } from '@/utils/helpers.js';
import money_transfer_requestService from '@/services/money_transfer_request.service.js';
import { getMoneyTransferRequestType } from '@/utils/NewFunctions/getMoneyTransferRequestType';
import getStatusDisplay from '@/utils/NewFunctions/getStatusDisplay.js';
import { mapGetters } from 'vuex';
import emitter from '@/plugins/mitt.js';
import html2pdf from 'html2pdf.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import {
  createFileFromBase64,
  downloadFile,
} from '@/utils/NewFunctions/utils.js';
import AttachmentPreviewModal from '@/components/Modals/attachmentPreviewModal.vue';

export default {
  name: 'money_transfer_request_modal',
  components: {
    AttachmentPreviewModal,
    Error_Modal,
  },
  data() {
    return {
      message: null,
      error: false,
      showDialog: false,
      dialogWidth: 800,
      dialogHeight: 600,
    };
  },
  methods: {
    createFileFromBase64,
    getStatusDisplay,
    formatNumber,
    getMoneyTransferRequestType,
    updateDimensions({ width, height }) {
      this.dialogWidth = width;
      this.dialogHeight = height;
    },
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: 'moneyTransferSummary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
      html2pdf(element, options);
    },
    async approveRequest() {
      let response = unifiedResponseHandler(
        await money_transfer_requestService.changeRequestStatusToApproved(
          this.chosen_request.id
        )
      );
      this.message = response.message;
      if (response.success) {
        emitter.emit('request_modal_close', this.message);
      } else {
        this.error = true;
      }
    },
    downloadAttachment() {
      downloadFile(this.chosen_request.attachment);
    },
    viewAttachment() {
      this.showDialog = true;
    },
  },
  computed: {
    ...mapGetters(['chosen_request', 'page']),
    summaryData() {
      if (!this.chosen_request) return {};

      return {
        Event: this.chosen_request.id,
        Customer: this.chosen_request.customerAccount?.cofferName || null,
        'Reference No.': this.chosen_request.referenceNo,
        'Request Type': this.getMoneyTransferRequestType(
          this.chosen_request.requestType
        ),
        Amount: `${this.formatNumber(this.chosen_request.amount)} ${this.chosen_request.currency.symbol}`,
        'Payer/Payee': this.chosen_request.secondaryThirdParty?.fullName || '',
        'Trustee Coffer':
          this.chosen_request.trusteeAccount?.cofferName || null,
        Status: this.getStatusDisplay(this.chosen_request.status),
        'Trustee Com Amt': this.chosen_request.trusteeCommissionAmount || 0,
        'Trustee Com %': `${this.chosen_request.trusteeCommissionPercent || 0}%`,
        'Customer Com Amt': this.chosen_request.ellingtonCommissionAmount || 0,
        'Customer Com %': `${this.chosen_request.ellingtonCommissionPercent || 0}%`,
        Note: this.chosen_request.description,
        'Created Date': this.chosen_request.createDate,
        'Modified On': this.chosen_request.modifiedDate,
      };
    },
    attachmentExists() {
      return this.chosen_request.attachment;
    },
  },
};
</script>

<style scoped></style>
