<template>
  <div>
    <v-container style="background-color: white" v-if="!broken_page" fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field label="Search" v-model="searchValue"></v-text-field>
        </v-col>
      </v-row>

      <div class="datatable-container">
        <EasyDataTable
          :headers="headers"
          :items="currencies"
          :search-field="searchField"
          :search-value="searchValue"
          alternating
          border-cell
          class="custom-data-table"
        >
          <template #item-choose="item">
            <v-icon @click="choose(item)">mdi-check-circle</v-icon>
          </template>
        </EasyDataTable>
      </div>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <!-- <v-dialog v-model="success" width="auto">
            <v-alert type="success"> success </v-alert>
        </v-dialog>

        <v-dialog v-model="error" width="auto">
            <v-alert type="error"> error </v-alert>
        </v-dialog> -->
    <v-dialog v-model="error">
      <div class="error-dialog">
        <div class="error-left">
          <div class="red-icon"></div>
        </div>
        <div class="error-right">
          <span @click="error = false" style="text-align: right">✖</span>
          <h4>Error</h4>
          <p class="error-message">{{ message }}</p>
          <button class="close-button" @click="error = false">Close</button>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="success">
      <div class="success-dialog">
        <div class="success-left">
          <div class="green-circle">
            <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M50 100 L80 130 L150 50"
                stroke="white"
                stroke-width="25"
                fill="transparent"
                class="success-tick"
              />
            </svg>
          </div>
        </div>
        <div class="success-right">
          <span @click="success = false" style="text-align: right">✖</span>
          <h4>Success</h4>
          <p class="success-message">{{ message }}</p>
          <button class="success-close-button" @click="success = false">
            Close
          </button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import currencyService from '@/services/currency.service';
import { unifiedResponseHandler } from '@/utils/helpers';
import emitter from '@/plugins/mitt';
import Broken_Page_V01 from '../Broken_Page_V01.vue';

export default {
  name: 'currency_list_modal_v01',
  components: {
    Broken_Page_V01,
  },

  data() {
    return {
      headers: [
        { text: 'Currency', value: 'name' },
        { text: 'Symbol', value: 'symbol' },
        { text: 'Choose', value: 'choose' },
      ],
      currencies: [],
      searchField: '',
      searchValue: '',

      message: null,
      broken_page: false,
      success: false,
      error: false,
    };
  },
  methods: {
    choose(currency_database) {
      emitter.emit('currency_database_id', currency_database);
    },
    async getCurrencyList() {
      let response = unifiedResponseHandler(
        await currencyService.getCurrencies()
      );

      if (response.success) {
        this.currencies = response.data;
        this.success = true;
      } else {
        this.broken_page = true;
      }
    },
  },
  async mounted() {
    await this.getCurrencyList();
  },
};
</script>
