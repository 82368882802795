<template>
  <v-card>
    <v-form ref="edit_meeting_form">
      <v-card-text>
        <v-row dense>
          <v-col v-if="isEditingMeeting" md="2">
            <v-text-field v-model="id" label="ID" readonly variant="outlined">
            </v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="title"
              :rules="requiredRule()"
              label="Title"
              color="primaryColor1"
            >
            </v-text-field>
          </v-col>
          <v-col :md="isEditingMeeting ? '3' : '4'">
            <v-select
              v-model="selectedImportanceLevel"
              :items="importanceLevels"
              item-title="text"
              item-value="value"
              label="Importance"
              color="primaryColor1"
            >
            </v-select>
          </v-col>
          <v-col :md="isEditingMeeting ? '3' : '4'">
            <v-select
              v-model="earlyReminder"
              :items="hasReminderOptions"
              item-title="text"
              item-value="value"
              clearable
              label="Reminder"
              color="primaryColor1"
            >
            </v-select>
          </v-col>
          <v-col md="12">
            <v-text-field
              v-model="note"
              clearable
              label="Note"
              color="primaryColor1"
            >
            </v-text-field>
          </v-col>
          <v-col md="12">
            <v-autocomplete
              v-model="selectedUsers"
              :items="this.users"
              item-title="firstName"
              item-value="id"
              label="Attendees"
              color="primaryColor1"
              multiple
              chips
              closable-chips
              :rules="requiredRule()"
            >
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <VueDatePicker
              placeholder="Select Date"
              v-model="meetingDate"
              format="yyyy-MM-dd"
              auto-apply
              :enable-time-picker="false"
              teleport-center
              class="custom-datepicker"
            ></VueDatePicker>
          </v-col>
          <v-col md="4">
            <v-text-field
              variant="outlined"
              type="text"
              v-model="startTime"
              v-maska="options"
              placeholder="Start Time"
              maxlength="5"
              prepend-inner-icon="mdi-clock-outline"
              class="custom-text-field"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field
              variant="outlined"
              type="text"
              v-model="endTime"
              v-maska="options"
              placeholder="End Time"
              maxlength="5"
              prepend-inner-icon="mdi-clock-outline"
              class="custom-text-field"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="this.disableButton"
        :variant="isEditingMeeting ? 'outlined' : 'flat'"
        :rules="requiredRule()"
        class="mr-4"
        color="button_color"
        @click="addOrUpdateMeeting"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="closeEntireModal()"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import { unifiedResponseHandler } from '@/utils/helpers.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import { requiredRule } from '@/utils/rules.js';
import meetingService from '@/services/meeting.service.js';
import userService from '@/services/user.service.js';

export default {
  name: 'createMeetingModal',
  components: { Success_Modal, Error_Modal },
  props: ['meetingToEdit'],

  data() {
    return {
      options: {
        mask: '##:##',
        eager: true,
      },
      message: '',
      id: null,
      meeting: null,
      isEditingMeeting: false,
      success: false,
      error: false,
      title: null,
      note: null,
      selectedImportanceLevel: 'Medium',
      meetingDate: null,
      startTime: null,
      endTime: null,
      disableButton: false,
      importanceLevels: [
        { text: 'Low', value: 'LOW' },
        { text: 'Medium', value: 'MEDIUM' },
        { text: 'High', value: 'HIGH' },
        { text: 'Critical', value: 'CRITICAL' },
      ],
      hasReminderOptions: [
        { text: '30 Minutes', value: 'HALF_HOUR' },
        { text: '1 Hour', value: 'HOUR' },
        { text: '2 Hours', value: 'TWO_HOURS' },
        { text: '1 Day', value: 'DAY' },
      ],
      earlyReminder: null,
      users: [],
      selectedUsers: [],
    };
  },

  computed: {
    buttonText() {
      return this.isEditingMeeting ? 'Update Meeting' : 'Create Meeting';
    },
  },

  methods: {
    requiredRule() {
      return requiredRule;
    },
    closeEntireModal() {
      this.$emit('closeModal');
    },
    async addOrUpdateMeeting() {
      if (this.hasMissingFields()) {
        this.displayError('Please fill in all required fields');
        return;
      }
      this.isEditingMeeting
        ? await this.editMeeting()
        : await this.addMeeting();
    },

    hasMissingFields() {
      return (
        !this.startTime ||
        !this.endTime ||
        !this.title ||
        !this.meetingDate ||
        this.startTime.length !== 5 ||
        this.endTime.length !== 5
      );
    },

    displayError(message) {
      this.message = message;
      this.error = true;
    },

    buildMeetingForm() {
      return {
        id: this.id,
        title: this.title,
        note: this.note,
        importanceLevel: this.selectedImportanceLevel.toUpperCase(),
        startTime: this.formatDateTime(this.meetingDate, {
          hours: this.startTime.slice(0, 2),
          minutes: this.startTime.slice(3, 5),
          seconds: 0,
        }),
        endTime: this.formatDateTime(this.meetingDate, {
          hours: this.endTime.slice(0, 2),
          minutes: this.endTime.slice(3, 5),
          seconds: 0,
        }),
        earlyReminder: this.earlyReminder,
        userIds: this.selectedUsers,
      };
    },

    async editMeeting() {
      if (await this.$refs.edit_meeting_form.validate()) {
        const form = this.buildMeetingForm();
        this.disableButton = true;
        const response = unifiedResponseHandler(
          await meetingService.editMeeting(form)
        );
        response.success
          ? this.handleSuccess('Meeting has been edited successfully')
          : this.displayError(response.message);
        this.disableButton = false;
      }
    },

    async addMeeting() {
      if (await this.$refs.edit_meeting_form.validate()) {
        const form = this.buildMeetingForm();
        this.disableButton = true;
        const response = unifiedResponseHandler(
          await meetingService.addMeeting(form)
        );
        if (response.success) {
          this.handleSuccess(
            'Meeting has been added successfully',
            response.data
          );
        } else {
          this.displayError(response.message);
        }
        this.disableButton = false;
      }
    },

    async getUsers() {
      const response = unifiedResponseHandler(await userService.getUsers());
      this.users = response.success ? response.data : [];
    },

    setMeetingToEditVariables(newMeeting) {
      if (!newMeeting) return;
      this.isEditingMeeting = true;
      Object.assign(this, {
        meeting: newMeeting,
        id: newMeeting.id,
        title: newMeeting.title,
        selectedImportanceLevel: newMeeting.importanceLevel,
        note: newMeeting.note,
        meetingDate: newMeeting.startTime.slice(0, 10),
        startTime: newMeeting.startTime.slice(11, 16),
        endTime: newMeeting.endTime.slice(11, 16),
        earlyReminder: newMeeting.earlyReminder,
        selectedUsers: newMeeting.users.map((user) => user.id),
      });
    },

    extractTime(dateTime) {
      return {
        hours: dateTime.slice(11, 13),
        minutes: dateTime.slice(14, 16),
        seconds: '00',
      };
    },

    formatDateString(isoString) {
      const date = new Date(isoString);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    },

    formatDateTime(date, time) {
      return `${this.formatDateString(date)} ${this.formatTimeWithZeroPadding(time)}`;
    },

    formatTimeWithZeroPadding({ hours, minutes }) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    },

    handleSuccess(message, data = null) {
      this.message = message;
      this.success = true;
      if (data) this.setMeetingToEditVariables(data);
      this.$emit('successfulMeetingOperation');
    },
  },

  async mounted() {
    this.setMeetingToEditVariables(this.meetingToEdit);
    await this.getUsers();
  },
};
</script>

<style scoped>
:deep(.v-text-field .v-icon) {
  font-size: 20px;
  color: #5e5e5e;
}

.custom-datepicker :deep(input) {
  height: 56px;
}

.custom-text-field :deep(div) {
  font-size: 15px;
  border-color: #7e7e7e;
  transition: border-color 2s ease;
}
</style>
