<template>
  <v-container
    fluid
    style="
      background-color: white;
      min-height: 300px;
      width: 700px;
      border-radius: 5px;
    "
  >
    <v-card-title class="font-weight-bold text-h5" style="text-align: center">
      Meeting Details
    </v-card-title>

    <v-card-text class="text-body-1">
      <v-row class="mt-2">
        <v-col cols="6">
          <strong class="font-weight-bold">Title:</strong> {{ meeting.title }}
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">Importance:</strong>
          <v-chip :color="getImportanceColor(meeting.importanceLevel)" dark>
            {{ meeting.importanceLevel }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Start Time:</strong>
          <div>{{ formatDate(meeting.startTime) }}</div>
        </v-col>
        <v-col cols="6">
          <strong class="font-weight-bold">End Time:</strong>
          <div>{{ formatDate(meeting.endTime) }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="attendeesFirstNames.length > 0">
          <strong class="font-weight-bold">Attendees:</strong>
          <div>
            <v-chip
              v-for="(firstName, index) in attendeesFirstNames"
              :key="index"
              class="ma-1"
              color="primaryColor1"
              dark
            >
              {{ firstName }}
            </v-chip>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <strong class="font-weight-bold">Note:</strong>
          <div>{{ meeting.note }}</div>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <v-row>
        <v-col cols="6">
          <strong class="font-weight-bold">Created By</strong>
          <div>
            {{ meeting.createdBy.firstName }} {{ meeting.createdBy.lastName }}
          </div>
        </v-col>
        <v-col cols="3">
          <v-btn
            variant="outlined"
            class="mr-9 ma-3"
            color="button_color"
            @click="editMeeting(meeting)"
          >
            Edit Meeting
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            :disabled="disableDeleteButton"
            class="mr-9 ma-3"
            color="red"
            @click="deleteMeeting(meeting)"
          >
            Delete
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
export default {
  name: 'MeetingDetailsModal',
  props: ['meeting'],
  data() {
    return {
      attendeesFirstNames: [],
      disableDeleteButton: false,
    };
  },
  methods: {
    getImportanceColor(level) {
      switch (level) {
        case 'LOW':
          return 'blue';
        case 'MEDIUM':
          return 'green';
        case 'HIGH':
          return '#fcc603';
        case 'CRITICAL':
          return 'red';
        default:
          return 'gray';
      }
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return date.toLocaleDateString('en-US', options);
    },
    editMeeting(meeting) {
      this.$emit('editMeeting', meeting);
    },
    deleteMeeting(meeting) {
      this.disableDeleteButton = true;
      this.$emit('deleteMeeting', meeting.id);
    },
  },
  mounted() {
    if (this.meeting) {
      this.attendeesFirstNames = this.meeting.users.map(
        (user) => user.firstName
      );
    }
  },
};
</script>

<style scoped></style>
