<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium mb-2">Task Group</h3>

    <v-row>
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field placeholder="Search" v-model="searchValue">
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="addNewTaskGroup"
          icon="mdi-plus"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="taskGroups"
            ><v-icon>mdi-export</v-icon></download-excel
          >
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="taskGroups"
      :search-field="searchField"
      :search-value="searchValue"
      alternating
      border-cell
      body-text-direction="center"
      header-text-direction="center"
      class="custom-data-table"
    >
      <template #item-edit="item">
        <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
      </template>
      <template #item-delete="item">
        <v-icon @click="deleteCurrency(item)">mdi-delete</v-icon>
      </template>
    </EasyDataTable>
  </v-container>

  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>

  <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>
      </div>
      <div class="error-right">
        <span @click="error = false" style="text-align: right">✖</span>
        <h4>Error</h4>
        <p class="error-message">{{ message }}</p>
        <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
  </v-dialog>

  <v-dialog v-model="success">
    <div class="success-dialog">
      <div class="success-left">
        <div class="green-circle">
          <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M50 100 L80 130 L150 50"
              stroke="white"
              stroke-width="25"
              fill="transparent"
              class="success-tick"
            />
          </svg>
        </div>
      </div>
      <div class="success-right">
        <span @click="success = false" style="text-align: right">✖</span>
        <h4>Success</h4>
        <p class="success-message">{{ message }}</p>
        <button class="success-close-button" @click="success = false">
          Close
        </button>
      </div>
    </div>
  </v-dialog>
  <v-dialog v-model="newTaskGroupModal" max-width="500">
    <create-task-group-modal
      :taskGroupToEdit="this.selectedTaskGroupToEdit"
      @closeTaskGroupModal="closeEntireModal"
      @successfulTaskGroupOperation="this.getTaskGroups()"
    ></create-task-group-modal>
  </v-dialog>
</template>

<script>
import { unifiedResponseHandler } from '@/utils/helpers';
import { requiredRule } from '@/utils/rules.js';
import Broken_Page_V01 from '../../Broken_Page_V01.vue';
import taskGroupService from '@/services/taskGroup.service.js';
import CreateTaskGroupModal from '@/components/HomeManagement/Setup/create-task-group-modal.vue';

export default {
  name: 'task_group',
  components: {
    CreateTaskGroupModal,
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: 'Description', value: 'description' },
        { text: 'Edit', value: 'edit' },
        { text: 'Delete', value: 'delete' },
      ],
      taskGroups: [],
      itemsSelected: [],
      selectedTaskGroupToEdit: null,
      searchField: '',
      searchValue: '',
      broken_page: false,
      newTaskGroupModal: false,
      success: false,
      error: false,
      requiredRule: requiredRule,
      message: null,
    };
  },
  methods: {
    async getTaskGroups() {
      let response = unifiedResponseHandler(
        await taskGroupService.getAllTaskGroups()
      );
      if (response.success) {
        this.taskGroups = response.data;
      } else {
        this.broken_page = true;
      }
    },
    goToEdit(item) {
      this.selectedTaskGroupToEdit = item;
      this.newTaskGroupModal = true;
    },
    addNewTaskGroup() {
      this.selectedTaskGroupToEdit = null;
      this.newTaskGroupModal = true;
    },
    closeEntireModal() {
      this.newTaskGroupModal = false;
    },
    async deleteCurrency(item) {
      let response = unifiedResponseHandler(
        await taskGroupService.deleteTaskGroup(item.id)
      );
      this.message = response.data;
      if (response.success) {
        this.success = true;
        await this.getTaskGroups();
      } else {
        this.error = true;
        this.message = response.message || 'An error occurred';
      }
    },
  },
  async mounted() {
    await this.getTaskGroups();
  },
};
</script>
