<template>
  <v-sheet max-width="800" min-width="600">
    <div style="padding: 20px">
      <div class="border-1">
        <h4 class="border-h4">Safe Box Summary</h4>
        <div class="grid-card-2" ref="pdfContent">
          <div
            class="summary-row"
            v-for="(value, label) in summaryData"
            :key="label"
          >
            <span style="flex: 1">{{ label }}:</span>
            <span style="flex: 1; text-align: left">{{ value }}</span>
          </div>
        </div>
        <v-row justify="end">
          <v-btn class="mr-4 top-20" color="button_color" @click="generatePDF"
            >PDF</v-btn
          >
        </v-row>
      </div>
    </div>
  </v-sheet>

  <Error_Modal
    :error="error"
    :message="message"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import { mapGetters } from 'vuex';
import html2pdf from 'html2pdf.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import { formatNumber } from '@/utils/helpers.js';

export default {
  name: 'request_modal_v01',
  components: {
    Error_Modal,
  },
  data() {
    return {
      request: null,
      currency: null,
      customer: null,
      amount: null,
      createDate: null,
      modifiedDate: null,
      status: null,
      requestType: null,
      trusteeCommissionAmount: null,
      trusteeCommissionPercent: null,
      ellingtonCommissionPercent: null,
      ellingtonCommissionAmount: null,
      message: null,
      error: false,
    };
  },
  methods: {
    formatNumber,
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: 'safeboxSummary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf(element, options);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(['chosen_safe_box', 'page']),
    summaryData() {
      if (!this.chosen_safe_box) return {};
      return {
        Event: this.chosen_safe_box.id,
        CofferName: this.chosen_safe_box.safeBox.cofferName,
        'Payer/Payee': this.chosen_safe_box.secondaryThirdPartyFullName,
        'Withdrawal Amt':
          formatNumber(this.chosen_safe_box.withdrawalAmount) +
          ' ' +
          this.chosen_safe_box.safeBox.currency.symbol,
        'Deposit Amt':
          formatNumber(this.chosen_safe_box.depositAmount) +
          ' ' +
          this.chosen_safe_box.safeBox.currency.symbol,
        'Create Date':
          new Date(this.chosen_safe_box.createDate)
            .toISOString()
            .split('T')[0] || null,
      };
    },
  },
};
</script>
