<template>
  <div>
    <v-card color="surface">
      <v-container v-if="!broken_page">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field label="Search" v-model="searchValue"></v-text-field>
          </v-col>
        </v-row>
        <EasyDataTable
          v-if="!accountTHP"
          class="datatable-container"
          :headers="headers"
          :items="thirdParties"
          :search-field="searchField"
          :search-value="searchValue"
          alternating
          border-cell
        >
          <template #item-choose="item">
            <v-icon @click="chooseThirdParty(item)">mdi-check-circle</v-icon>
          </template>
        </EasyDataTable>
        <EasyDataTable
          v-if="accountTHP"
          class="datatable-container custom-data-table"
          :headers="headersAccount"
          :items="thirdPartyAccountsDetails"
          :search-field="searchField"
          :search-value="searchValue"
          alternating
          border-cell
        >
          <template #item-choose="item">
            <v-icon @click="chooseThirdParty(item)">mdi-check-circle</v-icon>
          </template>
        </EasyDataTable>
      </v-container>
      <div v-else>
        <Broken_Page_V01></Broken_Page_V01>
      </div>
    </v-card>
  </div>
</template>
<script>
import third_partyService from '@/services/third_party.service';
import { unifiedResponseHandler } from '@/utils/helpers';
import emitter from '@/plugins/mitt';
import Broken_Page_V01 from '../Broken_Page_V01.vue';

export default {
  props: ['thirdPartyType'],
  name: 'third_party_list_modal',
  components: {
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        { text: 'Identification code', value: 'identificationCode' },
        { text: 'Full Name', value: 'fullName' },
        { text: 'Choose', value: 'choose' },
      ],
      headersAccount: [
        { text: 'Coffer Name', value: 'cofferName' },
        { text: 'Full Name', value: 'fullName' },
        { text: 'Account', value: 'accountNumber' },
        { text: 'Currency', value: 'currency' },
        { text: 'Choose', value: 'choose' },
      ],
      searchField: '',
      searchValue: '',
      message: null,
      broken_page: false,
      thirdParties: [],
      thirdPartiesAccount: [],
      accountTHP: false,
    };
  },

  methods: {
    chooseThirdParty(new_third_party) {
      emitter.emit('new_third_party', new_third_party);
    },

    async getThirdParties() {
      let callResponse = null;
      switch (this.thirdPartyType) {
        case 'TRUSTEE':
          this.accountTHP = false;
          callResponse = await third_partyService.getTrustees();
          break;

        case 'CUSTOMER':
          this.accountTHP = false;

          callResponse = await third_partyService.getCustomers();
          break;

        case 'SECONDARY_THIRDPARTY':
          this.accountTHP = false;
          callResponse = await third_partyService.getSecondaryThirdParties();
          break;
        case 'SAFE_BOX':
          this.accountTHP = false;
          callResponse = await third_partyService.getSafeBoxes();
          break;
        case 'ALL':
          this.accountTHP = false;
          callResponse = await third_partyService.getThirdParties();
          break;
        case 'ACCOUNT':
          this.accountTHP = true;
          callResponse = await third_partyService.getThirdPartiesAccount();
          break;

        default:
          break;
      }
      let response;
      if (callResponse) {
        response = unifiedResponseHandler(callResponse);
        if (response.success) {
          if (this.accountTHP) {
            this.thirdPartiesAccount = response.data;
          } else {
            this.thirdParties = response.data;
          }
        } else {
          this.broken_page = true;
        }
        console.log('thirdParties modal', response);
      }
    },
  },
  computed: {
    thirdPartyAccountsDetails() {
      return this.thirdPartiesAccount.map((account) => ({
        fullName: account.thirdParty.fullName,
        accountNumber: account.thirdParty.accountNumber,
        cofferName: account.cofferName,
        currency: account.currency.symbol,
        id: account.id,
      }));
    },
  },
  async mounted() {
    await this.getThirdParties();
  },
};
</script>
