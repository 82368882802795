const globalStore = {
  state: {
    tab: 'tab1',
    page: 'home_main',
    customer_management_options: [
      'register customer',
      'edit customer',
      'delete costume',
      'customer info',
      'make account',
      'edit account',
      'delete account',
    ],
    user_management_options: [
      'add user',
      'delete user',
      'edit user',
      'all users',
    ],
    feeding_info_options: ['insert country', 'insert bank', 'insert currency'],
    request_management_options: [
      'cash receive',
      'cash payment',
      'bank transfer receive',
      'bank transfer send',
      'payment inquiry',
      'status report',
    ],
    side_options: {
      'My Dashboard': ['My Meetings', 'My Reminders', 'My Tasks', 'My Notes'],

      ThirdParties: [
        'customers',
        'trustees',
        'payers/payees',
        'safeBox',
        'Income Center',
        'Cost Center',
        'all third parties',
        'third party groups',
      ],
      Setup: [
        'users',
        'country database',
        'currency database',
        'product database',
        'exchange rate',
        'contacts',
        'task groups',
        'company database',
      ],
      Config: [
        'account types',
        'transaction types',
        'transaction type configs',
      ],
    },
    home_options: {
      'My Dashboard': ['My Meetings', 'My Reminders', 'My Tasks', 'My Notes'],

      ThirdParties: [
        'customers',
        'trustees',
        'payers/payees',
        'safeBox',
        'Income Center',
        'Cost Center',
        'all third parties',
        'third party groups',
      ],
      Setup: [
        'users',
        'country database',
        'currency database',
        'product database',
        'exchange rate',
        'contacts',
        'task groups',
        'company database',
      ],
      Config: [
        'account types',
        'transaction types',
        'transaction type configs',
      ],
    },

    money_transfer_options: {
      Report: [
        'transfer report',
        'internal report',
        'crypto report',
        'safe box report',
        'group deal report',
      ],
      'New Transfer': [
        'cash receive',
        'cash send',
        'bank receive',
        'bank send',
        'internal trustee',
        'internal customer',
        'crypto deal',
        'group deal',
        'safe box',
      ],
      Analysis: ['route analysis'],
    },

    bank_and_cash_options: [
      'trustee acc',
      'trustee entries',
      'customer acc',
      'customer entries',
      'payer payee acc',
      'payer payee entries',
      'safe box acc',
      'safe box entries',
      'checkbooks',
    ],

    accounting_options: [
      'journal entry',
      'journal entry report',
      'manual transaction',
      'manual report',
      'bill payment',
      'bills history',
      'journals',
      'chart of accounts',
      'trustee SOA',
      'customer SOA',
    ],

    reports_options: {
      Summary: [
        'currency exposure',
        'trustee acc sum',
        'trustee 6203',
        'customer acc sum',
        'customer 4018',
        'total 4018-6203',
      ],
      'Own Company': ['income statement', 'profit and loss', 'balance sheet'],
      Charts: ['customer chart', 'trustee chart'],
    },

    past_operation_options: [
      'p cash receive',
      'p cash send',
      'p bank receive',
      'p bank send',
      'p internal trustee',
      'p internal customer',
      'p crypto deal',
      'p group deal',
    ],
    commerce_options: {
      1: [
        'proforma invoice',
        'purchase order',
        'invoice',
        'patching list',
        'delivery note',
      ],
    },
    reports_chosen: '',

    individuals_options: {
      individuals: ['new individual', 'individual list'],
    },

    third_parties_options: {
      third_parties: ['new third party', 'third party list'],
    },

    basic_info_options: {
      first: ['insert country', 'insert bank', 'insert currency'],
    },
    operations_options: {
      first: [
        'cash receive',
        'cash payment',
        'bank transfer receive',
        'bank transfer send',
        'payment inquiry',
        'status report',
      ],
    },
  },
  mutations: {},
  getters: {
    customer_management_options: (state) => state.customer_management_options,
    feeding_info_options: (state) => state.feeding_info_options,
    user_management_options: (state) => state.user_management_options,
    request_management_options: (state) => state.request_management_options,
    side_options: (state) => state.side_options,
    page: (state) => state.page,
    section: (state) => state.section,
    home_options: (state) => state.home_options,
    individuals_options: (state) => state.individuals_options,
    third_parties_options: (state) => state.third_parties_options,
    basic_info_options: (state) => state.basic_info_options,
    operations_options: (state) => state.operations_options,
    chosen_user: (state) => state.chosen_user,
    reports_options: (state) => state.reports_options,
    past_operation_options: (state) => state.past_operation_options,
    commerce_options: (state) => state.commerce_options,
    money_transfer_options: (state) => state.money_transfer_options,
    bank_and_cash_options: (state) => state.bank_and_cash_options,
    accounting_options: (state) => state.accounting_options,
    is_editing_user: (state) => state.is_editing_user,
    is_editing_individual: (state) => state.is_editing_individual,
    chosen_individual: (state) => state.chosen_individual,
    chosen_third_party: (state) => state.chosen_third_party,
    is_editing_third_party: (state) => state.is_editing_third_party,
    chosen_third_party_group: (state) => state.chosen_third_party_group,
    is_editing_third_party_group: (state) => state.is_editing_third_party_group,
    is_editing_product: (state) => state.is_editing_product,
    chosen_product: (state) => state.chosen_product,
    is_editing_request: (state) => state.is_editing_request,
    chosen_request: (state) => state.chosen_request,
    chosen_attachment: (state) => state.chosen_attachment,
    is_editing_internal_transfer: (state) => state.is_editing_internal_transfer,
    chosen_internal_transfer: (state) => state.chosen_internal_transfer,
    chosen_safe_box: (state) => state.chosen_safe_box,
    chosen_group_deal: (state) => state.chosen_group_deal,
    chosen_crypto_deal: (state) => state.chosen_crypto_deal,
    chosen_safe_box_operation: (state) => state.chosen_safe_box_operation,
    is_editing_crypto_deal: (state) => state.is_editing_crypto_deal,
    chosen_manual_transaction: (state) => state.chosen_manual_transaction,
    is_editing_account_type: (state) => state.is_editing_account_type,
    chosen_account_type: (state) => state.chosen_account_type,
    is_editing_transaction_type: (state) => state.is_editing_transaction_type,
    chosen_transaction_type: (state) => state.chosen_transaction_type,
    is_editing_currency: (state) => state.is_editing_currency,
    chosen_currency: (state) => state.chosen_currency,
    is_editing_transaction_type_config: (state) =>
      state.is_editing_transaction_type_config,
    chosen_transaction_type_config: (state) =>
      state.chosen_transaction_type_config,
  },
  actions: {
    setPage({ state }, inp) {
      state.page = inp;
    },
    setSideOptions({ state }, inp) {
      state.side_options = inp;
    },
    setChosenUser({ state }, inp) {
      state.chosen_user = inp;
    },
    setIsEditingUser({ state }, inp) {
      state.is_editing_user = inp;
    },
    setIsEditingIndividual({ state }, inp) {
      state.is_editing_individual = inp;
    },
    setChosenIndividual({ state }, inp) {
      state.chosen_individual = inp;
    },
    setChosenThirdParty({ state }, inp) {
      state.chosen_third_party = inp;
    },
    setIsEditingThirdParty({ state }, inp) {
      state.is_editing_third_party = inp;
    },
    setChosenThirdPartyGroup({ state }, inp) {
      state.chosen_third_party_group = inp;
    },
    setIsEditingThirdPartyGroup({ state }, inp) {
      state.is_editing_third_party_group = inp;
    },
    setIsEditingProduct({ state }, inp) {
      state.is_editing_product = inp;
    },
    setChosenProduct({ state }, inp) {
      state.chosen_product = inp;
    },
    setIsEditingRequest({ state }, inp) {
      state.is_editing_request = inp;
    },
    setChosenRequest({ state }, inp) {
      state.chosen_request = inp;
    },
    setChosenAttachment({ state }, inp) {
      state.chosen_attachment = inp;
    },
    setIsEditingInternalTransfer({ state }, inp) {
      state.is_editing_internal_transfer = inp;
    },
    setChosenInternalTransfer({ state }, inp) {
      state.chosen_internal_transfer = inp;
    },
    setChosenSafeBox({ state }, inp) {
      state.chosen_safe_box = inp;
    },
    setChosenGroupDeal({ state }, inp) {
      state.chosen_group_deal = inp;
    },
    setChosenCryptoDeal({ state }, inp) {
      state.chosen_crypto_deal = inp;
    },
    setIsEditingCryptoDeal({ state }, inp) {
      state.is_editing_crypto_deal = inp;
    },
    setChosenManualTransaction({ state }, inp) {
      state.chosen_manual_transaction = inp;
    },
    setIsEditingAccountType({ state }, inp) {
      state.is_editing_account_type = inp;
    },
    setChosenAccountType({ state }, inp) {
      state.chosen_account_type = inp;
    },
    setIsEditingTransactionType({ state }, inp) {
      state.is_editing_transaction_type = inp;
    },
    setChosenTransactionType({ state }, inp) {
      state.chosen_transaction_type = inp;
    },
    setIsEditingTransactionTypeConfig({ state }, inp) {
      state.is_editing_transaction_type_config = inp;
    },
    setChosenTransactionTypeConfig({ state }, inp) {
      state.chosen_transaction_type_config = inp;
    },
    setIsEditingCurrency({ state }, inp) {
      state.is_editing_currency = inp;
    },
    setChosenCurrency({ state }, inp) {
      state.chosen_currency = inp;
    },
  },
};

export default globalStore;
