<script>
import list_of_accounts_modal_v01 from '@/components/Modals/List_Of_Accounts_Modal_V01.vue';
import {
  ExchangeRateOption,
  MoneyOption,
  PercentageOption,
} from '@/utils/maska-options.js';
import {
  extractNumber,
  formatNumber,
  formatNumberInternal,
  handleKeyDown,
  unifiedResponseHandler,
} from '@/utils/helpers';
import { requiredRule } from '@/utils/rules';
import exchange_rateService from '@/services/exchange_rate.service';
import emitter from '@/plugins/mitt';

export default {
  name: 'internal-transfer-form',
  components: { list_of_accounts_modal_v01 },
  props: {
    isPastForm: Boolean,
    currentStatus: String,
    internalTransferType: String,
    is_editing_internal_transfer: Boolean,
    chosen_internal_transfer: Object,
  },
  data() {
    return {
      isLoading: false,
      exchangeRateOption: ExchangeRateOption,
      percentOption: PercentageOption,
      requiredRule: requiredRule,
      sourceAccountListModal: false,
      targetAccountListModal: false,
      secondaryThirdPartyAccountListModal: false,
      rateReadOnly: false,
      amountReadOnly: false,
      targetReadOnly: false,
      liveXERate: null,
      sourceToTargetMustBeSeenExRate: null,
      targetToSourceMustBeSeenExRate: null,
      spreadValue: null,
      XESourceToTargetRate: null,
      XETargetToSourceRate: null,
      bidAmount: null,
      askAmount: null,
      tickBid: null,
      tickAsk: null,
      inputDate: null,

      formData: {
        amount: null,
        sourceAccount: null,
        targetAccount: null,
        exchangeRate: null,
        transactionTimeXERate: null,
        description: null,
        withdrawalCommissionAmount: null,
        withdrawalCommissionPercent: null,
        depositCommissionPercent: null,
        depositCommissionAmount: null,
        fromCurrency: null,
        toCurrency: null,
        referenceNo: null,
        targetAmount: null,
        secondaryThirdPartyAccount: null,
        date: null,
      },
    };
  },
  async mounted() {
    emitter.on('chosenAccount', (value) => {
      if (value.dir === 'source') {
        this.formData.sourceAccount = value;
        this.formData.fromCurrency = this.formData.sourceAccount.currency;
        this.sourceAccountListModal = false;
      } else if (value.dir === 'target') {
        this.formData.targetAccount = value;
        this.formData.toCurrency = this.formData.targetAccount.currency;
        this.targetAccountListModal = false;
      }
      if (value.dir === 'secondaryThirdPartyAccount') {
        this.formData.secondaryThirdPartyAccount = value;
        this.secondaryThirdPartyAccountListModal = false;
      }
    });
    emitter.on('clearForm', () => {
      this.clearForm();
    });
    if (this.is_editing_internal_transfer) {
      this.formData.secondaryThirdPartyAccount =
        this.chosen_internal_transfer.secondaryThirdPartyAccount;
      this.formData.description =
        this.chosen_internal_transfer.description !== null
          ? this.chosen_internal_transfer.description.trimStart()
          : null;
      this.formData.referenceNo = this.chosen_internal_transfer.referenceNo;
      this.formData.amount = this.chosen_internal_transfer.amount;
      this.formData.withdrawalCommissionAmount =
        this.chosen_internal_transfer.withdrawalCommissionAmount;
      this.formData.withdrawalCommissionPercent =
        this.chosen_internal_transfer.withdrawalCommissionPercent;
      this.formData.depositCommissionPercent =
        this.chosen_internal_transfer.depositCommissionPercent;
      this.formData.depositCommissionAmount =
        this.chosen_internal_transfer.depositCommissionAmount;
      this.formData.sourceAccount = this.chosen_internal_transfer.sourceAccount;
      this.formData.targetAmount = this.chosen_internal_transfer.targetAmount;
      this.formData.targetAccount = this.chosen_internal_transfer.targetAccount;
      this.formData.fromCurrency = this.chosen_internal_transfer.sourceAccount
        ? this.chosen_internal_transfer.sourceAccount.currency
        : null;
      this.formData.toCurrency = this.chosen_internal_transfer.targetAccount
        ? this.chosen_internal_transfer.targetAccount.currency
        : null;
      this.formData.exchangeRate = this.chosen_internal_transfer.exchangeRate;
      this.formData.transactionTimeXERate =
        this.chosen_internal_transfer.transactionTimeXERate;
    }
  },

  computed: {
    creationState() {
      return (
        this.currentStatus === 'NOT INITIATED' ||
        this.currentStatus === 'INITIATED'
      );
    },
    creationOrReconciliationState() {
      return this.creationState || this.currentStatus === 'ACCOUNTS_UPDATED';
    },
    moneyOption() {
      if (this.formData.sourceAccount) {
        return MoneyOption(this.formData.sourceAccount.currency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    moneyOption2() {
      if (this.formData.targetAccount) {
        return MoneyOption(this.formData.targetAccount.currency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    targetAccountBalance() {
      return this.formData.targetAccount
        ? formatNumber(this.formData.targetAccount.actualAmount)
        : null;
    },
    sourceAccountBalance() {
      return this.formData.sourceAccount
        ? formatNumber(this.formData.sourceAccount.actualAmount)
        : null;
    },
    sourceAccountCofferName() {
      return this.formData.sourceAccount
        ? this.formData.sourceAccount.cofferName
        : null;
    },
    targetAccountCofferName() {
      return this.formData.targetAccount
        ? this.formData.targetAccount.cofferName
        : null;
    },

    sourceAccountId() {
      return this.formData.sourceAccount
        ? this.formData.sourceAccount.id
        : null;
    },
    formatNumberInternalBidAmount() {
      if (this.bidAmount && this.formData.exchangeRate) {
        return formatNumberInternal(this.bidAmount);
      } else {
        return null;
      }
    },
    formatNumberInternalAskAmount() {
      if (this.askAmount && this.formData.exchangeRate) {
        return formatNumberInternal(this.askAmount);
      } else {
        return null;
      }
    },
    targetAccountId() {
      return this.formData.targetAccount
        ? this.formData.targetAccount.id
        : null;
    },
    secondaryThirdPartyCofferName() {
      return this.formData.secondaryThirdPartyAccount
        ? this.formData.secondaryThirdPartyAccount.cofferName
        : null;
    },
    secondaryThirdPartyAccountId() {
      return this.formData.secondaryThirdPartyAccount
        ? this.formData.secondaryThirdPartyAccount.id
        : null;
    },
  },
  methods: {
    clearForm() {
      this.formData.secondaryThirdPartyAccount = null;
      this.formData.description = null;
      this.formData.referenceNo = null;
      this.formData.amount = null;
      this.formData.withdrawalCommissionAmount = null;
      this.formData.withdrawalCommissionPercent = null;
      this.formData.depositCommissionPercent = null;
      this.formData.depositCommissionAmount = null;
      this.formData.sourceAccount = null;
      this.formData.targetAmount = null;
      this.formData.targetAccount = null;
      this.formData.fromCurrency = null;
      this.formData.toCurrency = null;
      this.formData.date = null;
      this.formData.exchangeRate = null;
      this.formData.transactionTimeXERate = null;
      this.formData.spreadValue = null;
      this.inputDate = null;
      this.spreadValue = null;
      this.liveXERate = null;
    },
    async returnFormData() {
      let { valid } = await this.$refs.internal_transfer_form.validate();
      if (valid) {
        let internalTransferRegistrationForm = {
          internalTransferType: this.internalTransferType,
          sourceAccountId: this.sourceAccountId,
          sourceAccountCofferName: this.formData.sourceAccount.cofferName,
          targetAccountId: this.targetAccountId,
          targetAccountCofferName: this.formData.targetAccount.cofferName,
          amount: extractNumber(this.formData.amount),
          exchangeRate: this.formData.exchangeRate,
          transactionTimeXERate: this.formData.transactionTimeXERate,
          description: this.formData.description,
          referenceNo: this.formData.referenceNo,
          targetAmount: extractNumber(this.formData.targetAmount),
          secondaryThirdPartyAccountId: this.secondaryThirdPartyAccountId,
          secondaryThirdPartyCofferName: this.secondaryThirdPartyCofferName,
          askAmount: this.askAmount,
          bidAmount: this.bidAmount,
          spreadValue: extractNumber(this.spreadValue),
          sourceCurrency: this.formData.sourceAccount.currency,
          targetCurrency: this.formData.targetAccount.currency,
          withdrawalCommissionAmount: extractNumber(
            this.formData.withdrawalCommissionAmount
          ),
          withdrawalCommissionPercent: extractNumber(
            this.formData.withdrawalCommissionPercent
          ),
          depositCommissionPercent: extractNumber(
            this.formData.depositCommissionPercent
          ),
          depositCommissionAmount: extractNumber(
            this.formData.depositCommissionAmount
          ),
          date: this.inputDate,
        };
        let internalTransferReconciliationForm = {
          amount: extractNumber(this.formData.amount),
          targetAmount: extractNumber(this.formData.targetAmount),
          withdrawalCommissionAmount: extractNumber(
            this.formData.withdrawalCommissionAmount
          ),
          withdrawalCommissionPercent: extractNumber(
            this.formData.withdrawalCommissionPercent
          ),
          depositCommissionPercent: extractNumber(
            this.formData.depositCommissionPercent
          ),
          depositCommissionAmount: extractNumber(
            this.formData.depositCommissionAmount
          ),
          exchangeRate: this.formData.exchangeRate,
          description: this.formData.description,
          referenceNo: this.formData.referenceNo,
        };
        this.$emit('returnFormData', {
          internalTransferRegistrationForm: internalTransferRegistrationForm,
          internalTransferReconciliationForm:
            internalTransferReconciliationForm,
        });
      } else
        this.$emit('returnFormData', {
          internalTransferRegistrationForm: null,
          internalTransferReconciliationForm: null,
        });
    },
    formatNumber,
    clearSecondaryThirdPartyAccount() {
      this.formData.secondaryThirdPartyAccount = null;
    },
    formatNumberInternal,
    async getXERateAndUpdateBidAndAskAmounts() {
      const { currency: fromCurrency } = this.formData.sourceAccount;
      const fromCurrencySymbol =
        fromCurrency?.xeequivalent || fromCurrency?.symbol;
      const { currency: toCurrency } = this.formData.targetAccount;
      const toCurrencySymbol = toCurrency?.xeequivalent || toCurrency?.symbol;
      if (this.formData.sourceAccount && this.formData.targetAccount) {
        let sourceToTargetResponse = unifiedResponseHandler(
          await exchange_rateService.callXE(
            fromCurrencySymbol,
            toCurrencySymbol
          )
        );
        const targetToSourceResponse = {
          ...sourceToTargetResponse,
          data: 1 / sourceToTargetResponse.data,
        };
        if (sourceToTargetResponse.success && targetToSourceResponse.success) {
          this.XESourceToTargetRate = sourceToTargetResponse.data;
          this.XETargetToSourceRate = targetToSourceResponse.data;
          this.isLoading = true;
          if (this.XESourceToTargetRate >= this.XETargetToSourceRate) {
            this.sourceToTargetMustBeSeenExRate = sourceToTargetResponse.data;
            this.liveXERate =
              this.formData.fromCurrency.symbol +
              ' to ' +
              this.formData.toCurrency.symbol +
              ' : ' +
              formatNumberInternal(sourceToTargetResponse.data);
            const spreadPercentage = extractNumber(this.spreadValue) / 100;
            this.bidAmount =
              extractNumber(this.sourceToTargetMustBeSeenExRate) *
              (1 - spreadPercentage);
            this.askAmount =
              extractNumber(this.sourceToTargetMustBeSeenExRate) *
              (1 + spreadPercentage);
            if (this.askAmount > this.bidAmount) {
              this.tickBid = true;
              this.tickAsk = false;
            } else {
              this.tickBid = false;
              this.tickAsk = true;
            }
          } else {
            this.targetToSourceMustBeSeenExRate = targetToSourceResponse.data;
            this.liveXERate =
              this.formData.targetAccount.currency.symbol +
              ' to ' +
              this.formData.sourceAccount.currency.symbol +
              ' : ' +
              formatNumberInternal(targetToSourceResponse.data);
            const spreadPercentage = extractNumber(this.spreadValue) / 100;
            this.bidAmount =
              extractNumber(this.targetToSourceMustBeSeenExRate) *
              (1 - spreadPercentage);
            this.askAmount =
              extractNumber(this.targetToSourceMustBeSeenExRate) *
              (1 + spreadPercentage);
            if (this.askAmount > this.bidAmount) {
              this.tickBid = true;
              this.tickAsk = false;
            } else {
              this.tickBid = false;
              this.tickAsk = true;
            }
          }
        } else {
          if (!this.sourceToTargetMustBeSeenExRate) {
            this.sourceToTargetMustBeSeenExRate = 'NOT FOUND';
          } else {
            this.targetToSourceMustBeSeenExRate = 'NOT FOUND';
          }
        }
      }
    },
    computeMissingField() {
      if (
        this.formData.exchangeRate &&
        this.formData.amount &&
        !this.formData.targetAmount
      ) {
        if (this.XESourceToTargetRate > 1) {
          this.formData.targetAmount =
            extractNumber(this.formData.amount) *
            extractNumber(this.formData.exchangeRate);
          this.formData.targetAmount =
            formatNumberInternal(this.formData.targetAmount) +
            ' ' +
            this.formData.toCurrency.symbol;
        } else {
          this.formData.targetAmount =
            extractNumber(this.formData.amount) /
            extractNumber(this.formData.exchangeRate);
          this.formData.targetAmount =
            formatNumberInternal(this.formData.targetAmount) +
            ' ' +
            this.formData.toCurrency.symbol;
        }
        this.targetReadOnly = true;
      } else if (
        this.formData.targetAmount &&
        this.formData.amount &&
        !this.formData.exchangeRate
      ) {
        this.formData.exchangeRate =
          parseInt(extractNumber(this.formData.targetAmount)) >
          parseInt(extractNumber(this.formData.amount))
            ? extractNumber(this.formData.targetAmount) /
              extractNumber(this.formData.amount)
            : extractNumber(this.formData.amount) /
              extractNumber(this.formData.targetAmount);
        this.rateReadOnly = true;
      } else if (
        this.formData.targetAmount &&
        this.formData.exchangeRate &&
        !this.formData.amount
      ) {
        if (this.XESourceToTargetRate > 1) {
          this.formData.amount =
            extractNumber(this.formData.targetAmount) /
            extractNumber(this.formData.exchangeRate);
          this.formData.amount =
            formatNumberInternal(this.formData.amount) +
            ' ' +
            this.formData.fromCurrency.symbol;
        } else {
          this.formData.amount =
            extractNumber(this.formData.targetAmount) *
            extractNumber(this.formData.exchangeRate);
          this.formData.amount =
            formatNumberInternal(this.formData.amount) +
            ' ' +
            this.formData.fromCurrency.symbol;
        }
        this.amountReadOnly = true;
      } else if (this.formData.amount && this.formData.targetAmount) {
        const rate =
          extractNumber(this.formData.amount) /
          extractNumber(this.formData.targetAmount);
        this.formData.exchangeRate = rate > 1 ? rate : 1 / rate;
        this.rateReadOnly = true;
      }
    },
    clearSourceAccount() {
      this.formData.sourceAccount = null;
      this.formData.fromCurrency = null;
      this.XESourceToTargetRate = null;
      this.XETargetToSourceRate = null;
    },
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearAmountTargetRate() {
      this.formData.amount = null;
      this.formData.targetAmount = null;
      this.formData.exchangeRate = null;
      this.targetReadOnly = false;
      this.amountReadOnly = false;
      this.rateReadOnly = false;
    },
    clearTargetAccount() {
      this.formData.targetAccount = null;
      this.formData.toCurrency = null;
      this.XESourceToTargetRate = null;
      this.XETargetToSourceRate = null;
    },
  },
  watch: {
    'formData.sourceAccount'() {
      if (this.formData.sourceAccount && this.formData.targetAccount) {
        this.isLoading = false;
        this.tickBid = false;
        this.tickAsk = false;
        this.getXERateAndUpdateBidAndAskAmounts();
      }
    },
    'formData.targetAccount'() {
      this.targetAccountListModal = false;
      if (this.formData.sourceAccount && this.formData.targetAccount) {
        this.isLoading = false;
        this.tickBid = false;
        this.tickAsk = false;
        this.getXERateAndUpdateBidAndAskAmounts();
      }
    },
    spreadValue() {
      this.tickBid = false;
      this.tickAsk = false;
      this.getXERateAndUpdateBidAndAskAmounts();
    },
    formData: {
      handler() {
        this.returnFormData();
      },
      deep: true,
    },
  },
};
</script>

<template>
  <v-form ref="internal_transfer_form" style="margin-top: -50px">
    <v-row class="mt-4">
      <v-col cols="12" md="3" sm="12" xs="12">
        <v-tab
          :disabled="!creationState"
          prepend-icon="mdi-magnify"
          color="button_color"
          @click="sourceAccountListModal = true"
          >select
        </v-tab>
        <v-text-field
          @keyup.backspace="clearSourceAccount"
          @keydown="handleKeyDown"
          v-model="sourceAccountCofferName"
          label="Source Account"
          :disabled="!creationState && !isPastForm"
        ></v-text-field>
        <v-text-field
          readonly
          label="Source Actual Amount"
          v-model="sourceAccountBalance"
          variant="outlined"
          :disabled="!formData.sourceAccount"
          class="mt-25px"
        ></v-text-field>
        <v-text-field
          v-maska="moneyOption"
          v-model="formData.amount"
          label="Source Amount"
          :disabled="!creationOrReconciliationState && !isPastForm"
          @keyup.backspace="clearAmountTargetRate()"
          :variant="amountReadOnly === true ? 'outlined' : 'filled'"
          :readonly="amountReadOnly"
        >
        </v-text-field>

        <v-text-field
          v-maska="percentOption"
          :disabled="!creationState && !isPastForm"
          label="Spread%"
          v-model="spreadValue"
        ></v-text-field>
        <v-text-field
          v-maska="moneyOption"
          :disabled="!creationOrReconciliationState && !isPastForm"
          label="Source Com Amt"
          v-model="formData.withdrawalCommissionAmount"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12" xs="12">
        <v-tab
          :disabled="!creationState && !isPastForm"
          prepend-icon="mdi-magnify"
          color="button_color"
          @click="targetAccountListModal = true"
          >select
        </v-tab>
        <v-text-field
          @keyup.backspace="clearTargetAccount"
          @keydown="handleKeyDown"
          v-model="targetAccountCofferName"
          label="Target Account"
          :disabled="!creationState && !isPastForm"
        ></v-text-field>
        <v-text-field
          label="Target Actual Amount"
          v-model="targetAccountBalance"
          variant="outlined"
          :disabled="!formData.targetAccount"
          class="mt-25px"
          readonly
        >
        </v-text-field>

        <v-text-field
          :disabled="!creationOrReconciliationState && !isPastForm"
          label="Target Amount"
          v-model="formData.targetAmount"
          v-maska="moneyOption2"
          @keyup.backspace="clearAmountTargetRate()"
          :variant="targetReadOnly === true ? 'outlined' : 'filled'"
          :readonly="targetReadOnly"
        >
        </v-text-field>

        <v-text-field
          :disabled="
            (!(
              spreadValue &&
              (sourceToTargetMustBeSeenExRate ||
                targetToSourceMustBeSeenExRate) &&
              isLoading &&
              formData.fromCurrency &&
              formData.toCurrency &&
              formData.exchangeRate
            ) ||
              !creationState) &&
            !isPastForm
          "
          readonly
          label="Bid:"
          v-model="formatNumberInternalBidAmount"
        >
          <template v-slot:append-inner v-if="tickBid">
            <v-icon color="button_color" icon="mdi-check"></v-icon>
          </template>
        </v-text-field>
        <v-text-field
          v-maska="percentOption"
          :disabled="!creationOrReconciliationState && !isPastForm"
          label="Source Com %"
          v-model="formData.withdrawalCommissionPercent"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12" xs="12">
        <v-text-field
          class="mt-25px"
          label="Reference No."
          v-model="formData.referenceNo"
          :disabled="!creationOrReconciliationState && !isPastForm"
        ></v-text-field>
        <v-text-field
          v-if="internalTransferType === 'CUSTOMER_TO_CUSTOMER'"
          style="visibility: hidden"
          class="mt-25px"
        >
        </v-text-field>
        <v-tab
          v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'"
          :disabled="!creationState && !isPastForm"
          @click="secondaryThirdPartyAccountListModal = true"
          prepend-icon="mdi-magnify"
          color="button_color"
          >select
        </v-tab>

        <v-text-field
          v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'"
          label="Payer/Payee"
          v-model="secondaryThirdPartyCofferName"
          @keyup.backspace="clearSecondaryThirdPartyAccount"
          @keydown="handleKeyDown"
          :disabled="!creationState && !isPastForm"
        ></v-text-field>
        <v-text-field
          :disabled="!creationOrReconciliationState && !isPastForm"
          v-maska="exchangeRateOption"
          :label="$t('exchange rate')"
          v-model="formData.exchangeRate"
          @keyup.backspace="clearAmountTargetRate()"
          :variant="rateReadOnly === true ? 'outlined' : 'filled'"
          :readonly="rateReadOnly"
        >
          <template v-slot:append>
            <v-btn
              color="button_color"
              @click="computeMissingField"
              icon="mdi-check"
            ></v-btn>
          </template>
        </v-text-field>
        <v-text-field
          :disabled="
            (!(
              spreadValue &&
              (sourceToTargetMustBeSeenExRate ||
                targetToSourceMustBeSeenExRate) &&
              isLoading &&
              formData.fromCurrency &&
              formData.toCurrency &&
              formData.exchangeRate
            ) ||
              !creationState) &&
            !isPastForm
          "
          readonly
          label="Ask:"
          v-model="formatNumberInternalAskAmount"
        >
          <template v-slot:append-inner v-if="tickAsk">
            <v-icon color="button_color" icon="mdi-check"></v-icon>
          </template>
        </v-text-field>
        <v-text-field
          v-maska="moneyOption2"
          :disabled="!creationOrReconciliationState && !isPastForm"
          label="Target Com Amt"
          v-model="formData.depositCommissionAmount"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12" xs="12">
        <div class="mt-5" v-if="isPastForm">
          <label for="date-picker" style="color: black; margin-top: 20px">
            Date
          </label>
          <VueDatePicker
            auto-apply="true"
            id="date-picker"
            v-model="inputDate"
            :show-timepicker="false"
          ></VueDatePicker>
        </div>
        <v-text-field style="visibility: hidden" class="mt-25px">
        </v-text-field>
        <v-text-field style="visibility: hidden" class="mt-25px">
        </v-text-field>
        <v-text-field
          v-maska="exchangeRateOption"
          label="Transaction Time XE Rate"
          v-model="formData.transactionTimeXERate"
          :variant="amountReadOnly === true ? 'outlined' : 'filled'"
          :disabled="!creationOrReconciliationState && !isPastForm"
        >
        </v-text-field>
        <v-text-field
          :disabled="
            (!(isLoading && formData.fromCurrency && formData.toCurrency) ||
              !creationState) &&
            !isPastForm
          "
          readonly
          label="Live XE Rate"
          v-model="liveXERate"
        >
          <template v-slot:append-inner>
            <v-btn
              color="button_color"
              @click="getXERateAndUpdateBidAndAskAmounts"
              icon="mdi-update"
            >
            </v-btn>
          </template>
        </v-text-field>
        <v-text-field
          v-maska="percentOption"
          :disabled="!creationOrReconciliationState && !isPastForm"
          label="Target Com %"
          v-model="formData.depositCommissionPercent"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12" md="12">
        <v-text-field
          v-model="formData.description"
          label="Note"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>

  <v-dialog
    v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'"
    v-model="sourceAccountListModal"
    width="auto"
  >
    <list_of_accounts_modal_v01
      dir="source"
      third-party-type="TRUSTEE"
    ></list_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog
    v-if="internalTransferType === 'CUSTOMER_TO_CUSTOMER'"
    v-model="sourceAccountListModal"
    width="auto"
  >
    <list_of_accounts_modal_v01
      dir="source"
      third-party-type="CUSTOMER"
    ></list_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog
    v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'"
    v-model="targetAccountListModal"
    width="auto"
  >
    <list_of_accounts_modal_v01
      dir="target"
      third-party-type="TRUSTEE"
    ></list_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog v-model="secondaryThirdPartyAccountListModal" width="auto">
    <list_of_accounts_modal_v01
      dir="secondaryThirdPartyAccount"
      third-party-type="SECONDARY_THIRDPARTY"
    >
    </list_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog
    v-if="internalTransferType === 'CUSTOMER_TO_CUSTOMER'"
    v-model="targetAccountListModal"
    width="auto"
  >
    <list_of_accounts_modal_v01
      dir="target"
      third-party-type="CUSTOMER"
    ></list_of_accounts_modal_v01>
  </v-dialog>
</template>

<style scoped></style>
