<script>
import Individual_List_Modal_V01 from '@/components/Modals/Individual_List_Modal_V01.vue';
import { handleKeyDown, unifiedResponseHandler } from '@/utils/helpers';
import { requiredRule } from '@/utils/rules';
import third_partyService from '@/services/third_party.service';
import { mapGetters } from 'vuex';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';

export default {
  name: 'ThirdPartyContactsForm',
  components: { Error_Modal, Success_Modal, Individual_List_Modal_V01 },

  data() {
    return {
      individualListModal: false,
      newContact: null,
      message: null,
      success: false,
      error: false,
      contacts: [],
    };
  },
  methods: {
    requiredRule() {
      return requiredRule;
    },
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearNewContact() {
      console.log('clean');
      this.newContact = null;
    },
    async addContact() {
      let { valid } = await this.$refs.add_contact_form.validate();
      console.log('validation', valid);
      if (!valid) return;
      if (!this.chosen_third_party) {
        this.error = true;
        this.message = 'The third party is not loaded';
        return;
      }
      let form = {
        identityId: this.newContactId,
        thirdPartyId: this.chosen_third_party.id,
      };
      let response = unifiedResponseHandler(
        await third_partyService.addContact(form)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.contacts.push(response.data);
        this.clearNewContact();
      } else {
        this.error = true;
      }
    },

    async deleteContactPerson(contact) {
      let contactPersonId;
      if (contact.id !== undefined) contactPersonId = contact.id;
      if (contact.identityId !== undefined)
        contactPersonId = contact.identityId;
      if (contactPersonId === undefined) return;
      let response = unifiedResponseHandler(
        await third_partyService.deleteContactPerson(
          this.chosen_third_party.id,
          contactPersonId
        )
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        await this.getContactPersons(this.chosen_third_party.id);
      } else {
        this.error = true;
      }
    },

    async getContactPersons(id) {
      let response = unifiedResponseHandler(
        await third_partyService.getContactPersons(id)
      );

      if (response.success) {
        if (response.data) this.contacts = response.data;
        else this.contacts = [];
      } else {
        this.message = response.message;
        this.error = true;
      }
    },

    individualChosen(item) {
      this.newContact = item;
      this.individualListModal = false;
    },
  },
  async mounted() {
    if (this.is_editing_third_party)
      await this.getContactPersons(this.chosen_third_party.id);
  },

  computed: {
    ...mapGetters(['chosen_third_party', 'is_editing_third_party']),
    newContactFullName() {
      if (this.newContact) {
        return this.newContact.firstName + ' ' + this.newContact.lastName;
      } else {
        return null;
      }
    },
    newContactId() {
      if (this.newContact) {
        return this.newContact.id;
      } else {
        return null;
      }
    },
  },
};
</script>

<template>
  <v-row class="mt-4">
    <v-container fluid="">
      <v-sheet class="m-12" width="300">
        <v-form ref="add_contact_form">
          <v-tab
            color="button_color"
            @click="individualListModal = true"
            prepend-icon="mdi-magnify"
            >select
          </v-tab>

          <v-text-field
            v-model="newContactFullName"
            label="Contact to be added to third-party"
            item-title="firstName"
            item-value="id"
            return-object
            filled
            @keyup.backspace="clearNewContact"
            @keydown="handleKeyDown"
            :rules="requiredRule()"
          ></v-text-field>
          <div class="d-flex top-20">
            <v-btn color="button_color" @click="addContact()">
              Add Contact
            </v-btn>
          </div>
        </v-form>
      </v-sheet>
      <v-divider></v-divider>
      <v-toolbar-title>{{ $t('contacts') }}:</v-toolbar-title>
      <v-table height="300px">
        <thead>
          <tr>
            <th class="text-left">First Name</th>
            <th class="text-left">Last Name</th>
            <th class="text-left">E mail</th>
            <th class="text-left">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(contact, index) in contacts" :key="index">
            <td>{{ contact.firstName }}</td>
            <td>{{ contact.lastName }}</td>
            <td>{{ contact.email }}</td>
            <td>
              <v-icon @click="deleteContactPerson(contact)">mdi-delete</v-icon>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-container>
  </v-row>

  <v-dialog v-model="individualListModal" width="auto">
    <Individual_List_Modal_V01 @individualChosen="individualChosen">
    </Individual_List_Modal_V01>
  </v-dialog>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<style scoped></style>
