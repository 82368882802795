<template>
  <v-navigation-drawer
    v-model="showSidebar"
    class="hide-10"
    :rail="rail"
    permanent
    color="side_color"
    :location="drawerLocation"
    :width="sideBarWidth"
  >
    <v-list class="pa-0">
      <template v-if="Array.isArray(allowedSections)">
        <v-list-item
          @click="setPage(fav)"
          class="pointer deep-padding"
          v-for="(fav, m) in allowedSections"
          :key="m"
          :title="toTitleCase(fav)"
          :value="fav"
          :color="primaryColor2"
          :active="isSelected(fav)"
        >
        </v-list-item>
      </template>
      <template v-else>
        <v-list-group
          class="pointer"
          v-for="(item, listName) in allowedSections"
          :key="listName"
          :value="listName"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              class="text-capitalize deep-padding list-title"
              :title="listName"
              v-bind="props"
              :color="primaryColor2"
            >
            </v-list-item>
          </template>
          <v-list-item
            @click="setPage(fav)"
            class="pointer text-capitalize deep-padding list-items"
            v-for="(fav, m) in item"
            :key="m"
            :title="toTitleCase(fav)"
            :value="fav"
            :color="primaryColor2"
            :active="isSelected(fav)"
          >
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>

  <v-tabs
    v-if="!showSidebar"
    class="show-10"
    fixed-tabs
    bg-color=""
    next-icon="mdi-arrow-right-bold-box-outline"
    prev-icon="mdi-arrow-left-bold-box-outline"
    show-arrows
  >
    <v-tab
      class="pointer"
      v-for="(item, listName) in allowedSections"
      :key="listName"
    >
      <v-tab nav>
        <v-tab
          @click="setPage(fav)"
          class="pointer"
          v-for="(fav, m) in item"
          :key="m"
        >
          {{ $t(fav) }}
        </v-tab>
      </v-tab>
    </v-tab>
  </v-tabs>
</template>
<script>
import { mapGetters } from 'vuex';
import { setPage } from '@/utils/helpers';
import { authRole } from '@/services/auth-header';
import { allowedSections } from '@/services/access.js';
import emitter from '@/plugins/mitt';
import { useTheme } from 'vuetify';
import { toTitleCase } from '@/utils/NewFunctions/utils';

export default {
  name: 'sidebar_v01',
  computed: {
    ...mapGetters(['side_options', 'page']),
    allowedSections: function () {
      return allowedSections(this.side_options);
    },
    showSidebar() {
      return this.windowWidth > 1000;
    },
    primaryColor2() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor1;
    },
    sideBarWidth() {
      const screenWidth = this.windowWidth;
      return screenWidth * 0.15;
    },
  },
  data() {
    return {
      rail: false,
      authRoles: null,
      drawerLocation: 'start',
      windowWidth: window.innerWidth,
      selectedItem: null,
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.authRoles = authRole();
    emitter.on('drawer_location', (value) => {
      this.drawerLocation = value;
    });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    isSelected(fav) {
      return this.selectedItem === fav;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    toTitleCase,
    setPage(page) {
      this.selectedItem = page;
      setPage('');
      this.$nextTick(() => {
        setPage(page);
      });
    },
    filter() {
      return allowedSections(this.side_options);
    },
  },
};
</script>
<style scoped>
::v-deep(.deep-padding .v-list-item-title) {
  padding-left: 12px;
}

::v-deep(.list-title .v-list-item-title) {
  font-weight: 500;
}

::v-deep(.list-items .v-list-item-title) {
  font-weight: 300;
}
</style>
