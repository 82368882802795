// src/utils/utils.js
export function formatString(str) {
  return (
    str
      // Replace underscores with spaces
      .replace(/_/g, ' ')
      // Convert to lowercase, then capitalize the first letter of each word
      .toLowerCase()
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
  );
}

export function toTitleCase(str) {
  return str
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export function getImageUrl(name, ext) {
  return new URL(`../../assets/img/homeImg/${name}.${ext}`, import.meta.url)
    .href;
}

export function shortenStringForTable(s) {
  const words = s.split(' ');
  if (words.length <= 3) {
    return words.join(' ');
  }
  return words.slice(0, 2).join(' ') + ' ' + words[words.length - 1];
}

export function downloadFile(attachment) {
  const mimeType = getMimeType(attachment);

  if (!mimeType) {
    console.error('Unknown file type. Cannot download.');
    return;
  }

  const byteCharacters = atob(attachment);
  const byteNumbers = new Array(byteCharacters.length)
    .fill(0)
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  const fileExtension = mimeType === 'application/pdf' ? 'pdf' : 'png';
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `attachment.${fileExtension}`;
  downloadLink.click();

  URL.revokeObjectURL(downloadLink.href);
}

export function createFileFromBase64(base64String) {
  const mimeType = getMimeType(base64String);
  if (!mimeType) {
    console.error('Invalid Base64 string or unsupported file type.');
    return null;
  }

  const extension = mimeType === 'application/pdf' ? 'pdf' : 'png';
  const fileName = `attachment.${extension}`;
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length)
    .fill(0)
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  const file = new File([blob], fileName, { type: mimeType });
  return file;
}

function getMimeType(base64String) {
  const signature = base64String.substring(0, 20);
  if (signature.includes('JVBER')) {
    return 'application/pdf';
  } else if (signature.includes('iVBOR') || signature.includes('/9j/')) {
    return 'image/png';
  }
  return null;
}
