<template>
  <div class="paading_info">
    <v-container class="info-box" v-if="!broken_page" fluid>
      <h3>Welcome to the Exchange Rate Page!</h3>
      <p style="margin-left: 15px">
        This is where you can manage currency exchange rates effortlessly.
        Here's what you can do:
      </p>
      <ul>
        <li>
          <h6>Select Currency Pair:</h6>
          <p>
            Choose the currencies you want to work with from the dropdown menu.
          </p>
        </li>
        <li>
          <h6>Update Exchange Rate:</h6>
          <p>
            Enter the new exchange rate and click the "Update Exchange Rate"
            button.
          </p>
        </li>
        <li>
          <h6>View Exchange Rates:</h6>
          <p>
            Check the current exchange rates and download any attached files.
          </p>
        </li>
        <li>
          <h6>Upload Files:</h6>
          <p>Attach files when updating exchange rates.</p>
        </li>
      </ul>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <v-dialog v-model="success" width="auto">
      <v-alert type="success">success</v-alert>
    </v-dialog>

    <v-dialog v-model="error" width="auto">
      <v-alert type="error">error</v-alert>
    </v-dialog>
  </div>
</template>

<script>
import Broken_Page_V01 from '../Broken_Page_V01.vue';

export default {
  name: 'Q-ExchangeRate-Modal',
  components: {
    Broken_Page_V01,
  },
};
</script>
