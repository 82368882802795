<template>
  <v-container fluid>
    <h3 class="font-weight-medium">Currency Cost</h3>
    <v-form ref="exchange_rate_form">
      <v-row class="mb-2">
        <v-col class="d-flex justify-end">
          <v-btn
            color="button_color"
            @click="QExchangeRate = true"
            icon="mdi-help"
            class="ml-2"
          >
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      :headers="headers"
      :items="tableItems"
      :items-per-page="tableItems.length"
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:item.funds="{ item }">
        {{ formatNumber(item.funds) }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt.split(' ')[0] }}
      </template>
    </v-data-table>

    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="QExchangeRate" width="auto">
      <Q_ExchangeRate_Modal_V01></Q_ExchangeRate_Modal_V01>
    </v-dialog>
  </v-container>
</template>
<script>
import Q_ExchangeRate_Modal_V01 from '@/components/ModalQ/Q_ExchangeRate_Modal_V01.vue';
import exchangeRateService from '@/services/exchange_rate.service.js';
import {
  formatNumber,
  formatNumberInternal,
  unifiedResponseHandler,
} from '@/utils/helpers.js';
import { defineComponent } from 'vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';

export default defineComponent({
  name: 'exchange_rate_v01',
  components: {
    Error_Modal,
    Success_Modal,
    Q_ExchangeRate_Modal_V01,
  },
  data() {
    return {
      currencies: [],
      error: false,
      success: false,
      QExchangeRate: false,
      selectedKey: null,
      rate: null,
      message: null,
      selectedToCurrency: null,
      selectedFromCurrency: null,
      AllExchangeRate: [],
      loading: 'button_color',
      headers: [
        { title: 'Pair', align: 'center', key: 'pair' },
        { title: this.$t('exchange rate'), align: 'end', key: 'rate' },
        { title: 'Funds', align: 'end', key: 'funds' },
        { title: 'Update date', align: 'center', key: 'updatedAt' },
      ],
    };
  },
  methods: {
    formatNumber,
    formatNumberInternal,
    async getAllExchangeRate() {
      let response = unifiedResponseHandler(
        await exchangeRateService.getAllExchangeRate()
      );
      this.loading = undefined;
      if (response.success) {
        this.AllExchangeRate = response.data;
      }
    },
  },
  async mounted() {
    await this.getAllExchangeRate();
  },
  computed: {
    tableItems() {
      return this.AllExchangeRate.map((item) => ({
        ...item, // Spread the rest of the properties
        pair: `${item.currency.symbol} to AED`, // Create the 'pair' property
        currency: undefined, // Optionally remove the 'currency' property
      }));
    },
  },
});
</script>
