<script>
import { mapGetters } from 'vuex';
import {
  formatCurrencyInfo,
  formatNumber,
  handleKeyDown,
  unifiedResponseHandler,
} from '@/utils/helpers';
import { requiredRule } from '@/utils/rules';
import accountService from '@/services/account.service';
import CurrencyService from '@/services/currency.service';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import account_typeService from '@/services/account_type.service.js';
import emitter from '@/plugins/mitt.js';

export default {
  name: 'newAccountFormModal',
  props: ['chosen_third_party', 'chosen_account'],
  components: { Error_Modal, Success_Modal },
  data() {
    return {
      disableAddOrUpdateButton: false,
      success: false,
      error: false,
      message: '',
      cofferName: null,
      accountType: null,
      bankAccountName: null,
      bankAccountNumber: null,
      bankAccountOwnerFullName: null,
      selectedCurrency: null,
      isEditingAccount: false,
      underEditAccountId: null,
      currencies: [],
      iban: null,
      bicOrSwiftCode: null,
      loading: false,
    };
  },
  methods: {
    formatNumber,
    handleKeyDown,
    requiredRule() {
      return requiredRule;
    },
    async getCurrencies() {
      let response = unifiedResponseHandler(
        await CurrencyService.getCurrencies()
      );
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },

    async addOrUpdateAccount() {
      this.disableAddOrUpdateButton = true;
      if (!this.isEditingAccount) await this.addNewAccount();
      else await this.editAccount();
      this.disableAddOrUpdateButton = false;
    },

    async addNewAccount() {
      let { valid } = await this.$refs.account_form.validate();
      if (valid) {
        let form = {
          thirdPartyId: this.chosen_third_party.id,
          currencyId: this.selectedCurrency.id,
          cofferName: this.cofferName,
          bankName: this.bankAccountName,
          bankAccountNumber: this.bankAccountNumber,
          iban: this.iban,
          bicOrSwiftCode: this.bicOrSwiftCode,
          bankAccountOwnerFullName: this.bankAccountOwnerFullName,
          accountTypeId: this.accountType.id,
        };

        let response = unifiedResponseHandler(
          await accountService.addAccount(form)
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          emitter.emit('successAccountOperation', response.data);
          this.cofferName = null;
          this.accountTypeName = null;
          this.bankAccountName = null;
          this.bankAccountNumber = null;
          this.iban = null;
          this.bicOrSwiftCode = null;
          this.bankAccountOwnerFullName = null;
          this.selectedCurrency = null;
        } else {
          this.error = true;
        }
      }
    },

    async editAccount() {
      let { valid } = await this.$refs.account_form.validate();
      if (valid) {
        let form = {
          id: this.underEditAccountId,
          cofferName: this.cofferName,
          bankName: this.bankAccountName,
          iban: this.iban,
          bankAccountNumber: this.bankAccountNumber,
          bicOrSwiftCode: this.bicOrSwiftCode,
          bankAccountOwnerFullName: this.bankAccountOwnerFullName,
        };
        let response = unifiedResponseHandler(
          await accountService.editAccount(form)
        );
        this.message = response.message;
        if (response.success) {
          this.message = 'Account updated successfully';
          this.success = true;
          emitter.emit('successAccountOperation', response.data);
        } else {
          this.message = response.message;
          this.error = true;
        }
      }
    },

    goToEdit(account) {
      this.isEditingAccount = true;
      this.underEditAccountId = account.id;
      this.cofferName = account.cofferName;
      this.accountType = account.accountType;
      this.selectedCurrency = account.currency;
      this.accountTypeName =
        account.accountType.accountGroup + ' ' + account.accountType.ledgerCode;
      if (account.accountBankInfo != null) {
        this.bankAccountName = account.accountBankInfo.bankName;
        this.bankAccountNumber = account.accountBankInfo.accountNumber;
        this.iban = account.accountBankInfo.ibannumber;
        this.bicOrSwiftCode = account.accountBankInfo.swiftCode;
        this.bankAccountOwnerFullName =
          account.accountBankInfo.bankAccountOwnerFullName;
      }
    },
    cancelEdit() {
      this.isEditingAccount = false;
      this.underEditAccountId = null;
      this.cofferName = null;
      this.bankAccountName = null;
      this.bankAccountNumber = null;
      this.iban = null;
      this.bicOrSwiftCode = null;
      this.bankAccountOwnerFullName = null;
      this.selectedCurrency = null;
    },
    async getAccountTypesByDescription(description) {
      let updatedDescription = description;
      if (updatedDescription === 'SAFE_BOX') updatedDescription = 'safeBox';
      else if (updatedDescription === 'SECONDARY_THIRDPARTY')
        updatedDescription = 'PayerPayee';
      else if (updatedDescription === 'INCOME_CENTER')
        updatedDescription = 'income Center';
      else if (updatedDescription === 'COST_CENTER')
        updatedDescription = 'cost Center';
      let response = unifiedResponseHandler(
        await account_typeService.findAccountTypeByDescription(
          updatedDescription
        )
      );
      if (response.success) {
        this.accountType = response.data;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.getCurrencies();
    if (this.chosen_account) this.goToEdit(this.chosen_account);
    await this.getAccountTypesByDescription(
      this.chosen_third_party.thirdPartyType
    );
    this.loading = false;
  },
  computed: {
    ...mapGetters(['chosen_third_party']),
    accountTypeName() {
      if (this.accountType) {
        return 'CASH ' + this.accountType.generalLedger.code;
      } else {
        return null;
      }
    },
  },
};
</script>

<template>
  <v-container
    fluid
    style="
      background-color: white;
      width: 600px;
      height: 450px;
      border-radius: 5px;
    "
  >
    <v-form ref="account_form" :disabled="loading">
      <v-row>
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-text-field
            label="Coffer Name (Third Party + ... + Currency)"
            v-model="this.cofferName"
            :rules="requiredRule()"
          ></v-text-field>

          <v-text-field
            readonly
            variant="outlined"
            label="Account Type"
            v-model="accountTypeName"
            @keydown="handleKeyDown"
          ></v-text-field>

          <v-text-field
            label="Bank Name"
            v-model="bankAccountName"
          ></v-text-field>
          <v-text-field
            label="Bank Account Number"
            v-model="bankAccountNumber"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-text-field
            label="Bank Account Owner Full Name"
            v-model="bankAccountOwnerFullName"
          ></v-text-field>
          <v-autocomplete
            :label="!isEditingAccount ? 'Select Currency' : 'Currency'"
            v-model="selectedCurrency"
            :items="currencies"
            item-title="name"
            item-value="name"
            filled
            return-object
            :readonly="isEditingAccount"
            :rules="requiredRule()"
          ></v-autocomplete>
          <v-text-field label="IBAN" v-model="iban"></v-text-field>
          <v-text-field
            label="BIC Or Swift Code"
            v-model="bicOrSwiftCode"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <div class="d-flex top-20">
          <v-btn color="button_color" @click="cancelEdit"> Cancel</v-btn>
          <v-btn
            :variant="isEditingAccount ? 'tonal' : 'elevated'"
            class="ml-4 mb-10"
            color="button_color"
            @click="addOrUpdateAccount"
            :disabled="disableAddOrUpdateButton"
          >
            {{ isEditingAccount ? 'Edit ' : 'Add ' }} Account
          </v-btn>
        </div>
      </v-row>
    </v-form>
  </v-container>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<style scoped></style>
