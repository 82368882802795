<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card
          class="mx-auto"
          max-width="344"
          color="side_color"
          @click="setPage('cash_receive')"
        >
          <v-card-text>
            <div>{{ $t('Money Transfer') }}</div>
            <p class="text-h5 text--primary">{{ $t('Cash Receive') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('cash_receive')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          class="mx-auto mt-8"
          max-width="344"
          color="side_color"
          @click="setPage('cash_send')"
        >
          <v-card-text color="grey-lighten-4">
            <div>{{ $t('Money Transfer') }}</div>
            <p class="text-h5 text--primary">{{ $t('Cash send') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('cash_send')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          class="mx-auto mt-8"
          max-width="344"
          color="side_color"
          @click="setPage('internal_trustee')"
        >
          <v-card-text>
            <div>{{ $t('Internal Transfer') }}</div>
            <p class="text-h5 text--primary">{{ $t('Internal T Transfer') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('internal_trustee')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <div
          style="
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 2rem;
          "
        >
          <h1 class="font-weight-medium">{{ $t('Money Transfer') }}</h1>
          <v-img
            :width="550"
            aspect-ratio="1"
            cover
            :src="getImageUrl('bankAndCash-g', 'svg')"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <v-card
          class="mx-auto"
          max-width="344"
          color="side_color"
          @click="setPage('bank_receive')"
        >
          <v-card-text>
            <div>{{ $t('Money Transfer') }}</div>
            <p class="text-h5 text--primary">{{ $t('Bank Receive') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('bank_receive')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          color="side_color mt-8"
          class="mx-auto"
          max-width="344"
          @click="setPage('bank_send')"
        >
          <v-card-text>
            <div>{{ $t('Money Transfer') }}</div>
            <p class="text-h5 text--primary">{{ $t('Bank Send') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('bank_send')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          class="mx-auto mt-8"
          max-width="344"
          color="side_color"
          @click="setPage('internal_customer')"
        >
          <v-card-text>
            <div>{{ $t('Internal Transfer') }}</div>
            <p class="text-h5 text--primary">{{ $t('Internal C Transfer') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="text"
              color="button_color"
              @click="setPage('internal_customer')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { setPage } from '@/utils/helpers';
import { getImageUrl } from '@/utils/NewFunctions/utils.js';

export default {
  name: 'money_transfer_home_page_v01',
  methods: {
    getImageUrl,
    setPage(page) {
      setPage(page);
    },
  },
  data() {},
};
</script>