<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium mb-2">Contacts</h3>
      <v-row>
        <v-col cols="12" md="4" sm="8">
          <div class="d-flex">
            <v-text-field placeholder="Search" v-model="searchValue">
              <template v-slot:prepend-inner>
                <v-icon
                  icon="mdi-magnify"
                  variant="tonal"
                  color="button_color"
                  class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            color="button_color"
            @click="NewIndividual()"
            icon="mdi-plus"
            class="ml-2"
          >
          </v-btn>
          <v-btn
            color="button_color"
            @click="QIndividual = true"
            icon="mdi-help"
            class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="individuals"
              ><v-icon>mdi-export</v-icon></download-excel
            >
          </v-btn>
        </v-col>
      </v-row>
      <EasyDataTable
        :headers="headers"
        :items="individuals"
        :search-field="searchField"
        :search-value="searchValue"
        body-text-direction="center"
        header-text-direction="center"
        alternating
        border-cell
        class="custom-data-table"
        :loading="loading"
      >
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="deleteIndividual(item)">mdi-delete</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>

    <Confirmation_Modal
      :confirmation="confirmation"
      @DoConfirmation="getConfirmation()"
      @closeConfirmationModal="confirmation = false"
    ></Confirmation_Modal>
    <v-dialog v-model="QIndividual" width="auto">
      <Q_Individual_Modal_V01></Q_Individual_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import individualService from '@/services/individual.service.js';
import { setPage, unifiedResponseHandler } from '@/utils/helpers.js';
import Broken_Page_V01 from '../../Broken_Page_V01.vue';
import Q_Individual_Modal_V01 from '@/components/ModalQ/Q_Individual_Modal_V01.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Confirmation_Modal from '@/components/Utils/Confirmation_Modal.vue';
export default {
  name: 'individual_list_v01',
  components: {
    Confirmation_Modal,
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    Q_Individual_Modal_V01,
  },
  data() {
    return {
      headers: [
        { text: 'Last Name', value: 'lastName' },
        { text: 'First Name', value: 'firstName' },
        { text: 'Email:', value: 'email' },
        { text: 'Cell Phone', value: 'cellPhoneNumber' },
        { text: 'Edit/Details', value: 'edit' },
        { text: 'Delete', value: 'delete' },
      ],
      individuals: [],
      searchField: '',
      searchValue: '',
      itemsSelected: [],
      message: null,
      broken_page: false,
      success: false,
      error: false,
      confirmation: false,
      confirmed: false,
      QIndividual: false,
      loading: false,
    };
  },

  methods: {
    goToEdit(individual) {
      this.$store.dispatch('setChosenIndividual', individual);
      this.$store.dispatch('setIsEditingIndividual', true);
      setPage('new_individual');
    },
    NewIndividual() {
      setPage('new_individual');
    },
    async getIndividualsList() {
      this.loading = true;
      let response = unifiedResponseHandler(
        await individualService.getIndividuals()
      );
      if (response.success) {
        this.individuals = response.data;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },

    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },
    async deleteIndividual(individual) {
      let response = unifiedResponseHandler(
        await individualService.deleteIdentity(individual.id)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        await this.getIndividualsList();
      } else {
        this.error = true;
      }
      console.log(individual);
    },
  },
  async mounted() {
    await this.getIndividualsList();
  },
};
</script>
