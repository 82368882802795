<template>
  <div class="paading_info">
    <v-container class="info-box" v-if="!broken_page" fluid>
      <h3>Welcome to the User Management Page!</h3>
      <p style="margin-left: 15px">
        This page allows you to manage users in the application. Here's what you
        can do:
      </p>
      <ul>
        <li>
          <h6>Search Users & Add New User:</h6>
          <p>
            Search for specific users by entering search criteria in the
            "Search" field and pressing Enter. Click on the plus icon (+) to
            create a new user record.
          </p>
        </li>
        <li>
          <h6>Export User Data:</h6>
          <p>Download user data in Excel format by clicking the export icon.</p>
        </li>
      </ul>

      <p style="margin-left: 15px">
        Below these options, you'll find a table displaying a list of users with
        details. You can perform various actions on each user:
      </p>

      <ul>
        <li>
          <h6>Enable/Disable User:</h6>
          <p>Toggle a user's status between enabled and disabled.</p>
        </li>
        <li>
          <h6>Edit & Delete User:</h6>
          <p>Edit user details. Delete users (with confirmation dialog).</p>
        </li>
        <li>
          <h6>Change Password & Set Roles:</h6>
          <p>Change a user's password. Assign roles to users.</p>
        </li>
      </ul>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <v-dialog v-model="success" width="auto">
      <v-alert type="success">success</v-alert>
    </v-dialog>

    <v-dialog v-model="error" width="auto">
      <v-alert type="error">error</v-alert>
    </v-dialog>
  </div>
</template>

<script>
import Broken_Page_V01 from '../Broken_Page_V01.vue';

export default {
  name: 'Q-user-Modal',
  components: {
    Broken_Page_V01,
  },
};
</script>
