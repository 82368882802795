<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium mb-2">Internal Transfers Report</h3>
    <v-row class="mb-2">
      <v-col cols="12" md="5" sm="8">
        <div class="d-flex">
          <v-text-field
            placeholder="Search"
            v-model="searchValue"
            @keyup.enter="searchForItem(searchValue)"
          >
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
          <v-checkbox
            class="ml-5"
            v-model="openRequests"
            color="button_color"
            label="Open Requests"
            @change="getRequests"
          ></v-checkbox>
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="QInternalTransfersModal = true"
          icon="mdi-help"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="internalTransferDetails">
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="tableItems"
      v-model:serverOptions="serverOptions"
      :server-items-length="totalItems"
      :loading="loading"
      body-text-direction="center"
      header-text-direction="center"
      class="custom-data-table"
      border-cell
      :body-row-class-name="bodyRowClassNameFunction"
      no-hover
    >
      <template #pagination="{}">
        <v-btn
          density="compact"
          class="ma-4"
          :disabled="serverOptions.page === 1"
          @click="goToPrevPage"
          >prev page
        </v-btn>
        <v-btn
          density="compact"
          class="ma-4"
          :disabled="serverOptions.page === numberOfPages"
          @click="goToNextPage"
          >next page
        </v-btn>
      </template>

      <template #item-id="item">
        <v-icon @click="gotToRequest(item)"
          ><span class="modal-icon">{{ item.id }}</span></v-icon
        >
      </template>

      <template #item-debit="{ debit }">
        <div class="currency-amount">
          <span class="currency">{{ splitCurrencyValue(debit).currency }}</span>
          <span class="text-right">{{ splitCurrencyValue(debit).value }}</span>
        </div>
      </template>
      <template #item-credit="{ credit }">
        <div class="currency-amount">
          <span class="currency">{{
            splitCurrencyValue(credit).currency
          }}</span>
          <span class="text-right">{{ splitCurrencyValue(credit).value }}</span>
        </div>
      </template>

      <template #item-update="item">
        <Toggle_Confirmation
          @editTransfer="goToEdit(item)"
          @acceptTransfer="acceptTransfer(item)"
          @denyTransfer="denyTransfer(item)"
          @reconcileTransfer="goToEdit(item)"
          :item="item"
          :action="action"
          :reconciliation-possible="item.editable"
        >
        </Toggle_Confirmation>
      </template>
    </EasyDataTable>
  </v-container>

  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>

  <v-dialog v-model="internalTransferModal" width="auto">
    <Internal_Transfer_Modal_V01></Internal_Transfer_Modal_V01>
  </v-dialog>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>

  <v-dialog v-model="QInternalTransfersModal" width="auto">
    <Q_InternalTransfers_Modal_V01></Q_InternalTransfers_Modal_V01>
  </v-dialog>
</template>
<script>
import Broken_Page_V01 from '../../Broken_Page_V01.vue';
import Q_InternalTransfers_Modal_V01 from '@/components/ModalQ/Q_InternalTransfers_Modal_V01.vue';
import internal_transferService from '@/services/internal_transfer.service';
import {
  pageResponseHandler,
  unifiedResponseHandler,
  setPage,
  formatNumber,
} from '@/utils/helpers';
import Internal_Transfer_Modal_V01 from '../../Modals/transfer/Internal_Transfer_Modal_V01.vue';
import emitter from '@/plugins/mitt';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Toggle_Confirmation from '@/components/MoneyTransfer/Toggle_Confirmation.vue';
import { mapGetters } from 'vuex';
import getStatusDisplay from '@/utils/NewFunctions/getStatusDisplay';

export default {
  name: 'internal_transfer_list_v01',
  components: {
    Error_Modal,
    Success_Modal,
    Internal_Transfer_Modal_V01,
    Q_InternalTransfers_Modal_V01,
    Broken_Page_V01,
    Toggle_Confirmation,
  },
  data() {
    return {
      headers: [
        { text: 'Event', value: 'id', width: 50 },
        { text: 'From', value: 'from' },
        { text: 'Debit', value: 'debit' },
        { text: 'Exchange Rate', value: 'exchangeRate' },
        { text: 'To', value: 'to' },
        { text: 'Credit', value: 'credit' },
        { text: 'Status', value: 'status' },
        { text: 'Create Date', value: 'createDate' },
        { text: 'Modified Date', value: 'modifiedDate' },
        { text: 'User', value: 'user' },
        { text: 'Update', value: 'update' },
      ],
      tableItems: [],
      message: null,
      broken_page: false,
      success: false,
      error: false,
      action: false,
      internalTransfers: [],
      totalItems: 0,
      accountCofferName: null,
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
      loading: false,
      statusCriteria: 'ALL',
      showStatusFilter: false,
      internalTransferModal: false,
      QInternalTransfersModal: false,
      searchField: '',
      searchValue: '',
      openRequests: false,
      debounceTimer: null,
    };
  },

  methods: {
    isOnlySpaces(str) {
      return str.trim().length === 0;
    },
    bodyRowClassNameFunction(item) {
      switch (item.status) {
        case 'Completed':
          return 'row-completed';
        case 'Canceled':
        case 'Deleted':
          return 'row-canceled';
        case 'Initiated':
          return 'row-process';
        case 'First Party Confirmed':
        case 'Waiting for Approval':
        case 'Transaction Requested':
          return 'row-other-status';
        case 'Admin Approved':
        case 'Approved':
          return 'row-approved';
        default:
          return '';
      }
    },
    splitCurrencyValue(currencyString) {
      const parts = currencyString.split(' ');
      return {
        currency: parts[0],
        value: parts[1],
      };
    },
    goToPrevPage() {
      this.serverOptions.page = this.serverOptions.page - 1;
    },
    goToNextPage() {
      this.serverOptions.page = this.serverOptions.page + 1;
    },
    async searchForItem(searchValue) {
      if (this.isOnlySpaces(searchValue)) {
        this.searchValue = '';
        return;
      }
      searchValue = searchValue.replace(/^\s+|\s+$/g, '');
      const { page, rowsPerPage } = this.serverOptions;
      const isNumber = /^\d+(\.\d+)?$/.test(searchValue);
      const isString = /^[a-zA-Z ]+$/.test(searchValue);
      let numericalSearchField = isNumber ? searchValue : null;
      let searchField = isString ? searchValue : null;
      this.loading = true;
      let response = pageResponseHandler(
        await internal_transferService.searchForInternalTransfers(
          page - 1,
          rowsPerPage,
          searchField,
          numericalSearchField
        )
      );
      this.loading = false;
      if (response.success) {
        this.totalItems = response.totalItems;
        this.numberOfPages = response.numberOfPages;
        this.internalTransfers = response.data;
        this.tableItems = this.internalTransferDetails;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    async goToEdit(item) {
      let response = unifiedResponseHandler(
        await internal_transferService.getInternalTransferById(item.id)
      );
      if (response.success) {
        let requestToSet = response.data;
        this.$store.dispatch('setChosenInternalTransfer', requestToSet);
        this.$store.dispatch('setIsEditingInternalTransfer', true);
        switch (requestToSet.transferType) {
          case 'CUSTOMER_TO_CUSTOMER':
            setPage('internal customer');
            break;
          case 'TRUSTEE_TO_TRUSTEE':
            setPage('internal trustee');
            break;
          default:
        }
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async gotToRequest(item) {
      let response = unifiedResponseHandler(
        await internal_transferService.getInternalTransferById(item.id)
      );
      if (response.success) {
        let requestToSet = response.data;
        this.$store.dispatch('setChosenInternalTransfer', requestToSet);
        this.internalTransferModal = true;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },

    async getRequests() {
      let response;
      this.loading = true;
      if (!this.openRequests) {
        response = pageResponseHandler(
          await internal_transferService.getInternalTransfers(
            this.serverOptions.page - 1,
            this.serverOptions.rowsPerPage,
            this.accountCofferName
          )
        );
      } else {
        response = pageResponseHandler(
          await internal_transferService.getAllIncomplete(
            this.serverOptions.page - 1,
            this.serverOptions.rowsPerPage,
            this.accountCofferName
          )
        );
      }
      if (response.success) {
        this.totalItems = response.totalItems;
        this.numberOfPages = response.numberOfPages;
        this.internalTransfers = response.data;
        this.tableItems = this.internalTransferDetails;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    async acceptTransfer(item) {
      this.action = true;
      let response = unifiedResponseHandler(
        await internal_transferService.requestForAccountsUpdate(item.id)
      );
      this.action = false;
      if (response.success) {
        item.status = 'Acc Updated';
        let index = this.tableItems.findIndex(
          (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async denyTransfer(item) {
      this.action = true;
      let response = unifiedResponseHandler(
        await internal_transferService.changeRequestStatusToCanceled(item.id)
      );
      this.action = false;
      if (response.success) {
        item.status = 'Canceled';
        let index = this.tableItems.findIndex(
          (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },
  async mounted() {
    emitter.on('internal_transfer_modal_close', (value) => {
      this.internalTransferModal = false;
      this.message = value;
      this.success = true;
      this.getRequests();
    });
    await this.getRequests();
  },
  computed: {
    ...mapGetters(['page']),

    internalTransferDetails() {
      return this.internalTransfers.map((internalTransfer) => {
        let transactionStatus = getStatusDisplay(internalTransfer.status);
        return {
          id: internalTransfer.id,
          status: transactionStatus,
          amount: formatNumber(internalTransfer.amount),
          debit:
            internalTransfer.fromCurrencySymbol +
            ' ' +
            formatNumber(internalTransfer.amount),
          credit:
            internalTransfer.toCurrencySymbol +
            ' ' +
            formatNumber(internalTransfer.targetAmount),
          exchangeRate: internalTransfer.exchangeRate,
          from: internalTransfer.sourceAccountCofferName,
          to: internalTransfer.targetAccountCofferName,
          payerPayeeFullName: internalTransfer.payerPayeeFullName,
          transferType: internalTransfer.transferType,
          user: internalTransfer.createdBy,
          modifiedDate: new Date(internalTransfer.modifiedDate)
            .toISOString()
            .split('T')[0],
          createDate: new Date(internalTransfer.createDate)
            .toISOString()
            .split('T')[0],
          editable: internalTransfer.editable,
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
  },
  watch: {
    searchValue(newVal) {
      if (newVal === '') {
        clearTimeout(this.debounceTimer);
        this.serverOptions.page = 1;
        this.getRequests();
      } else {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.searchForItem(newVal);
        }, 500);
      }
    },
    serverOptions: {
      handler() {
        if (this.searchValue !== null && this.searchValue !== '')
          this.searchForItem(this.searchValue);
        else this.getRequests();
      },
      deep: true,
    },
  },
};
</script>
