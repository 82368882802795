<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium mb-2">Reminders</h3>

    <v-row>
      <v-col cols="12" md="4" sm="8">
        <div class="d-flex">
          <v-text-field placeholder="Search" v-model="searchValue">
            <template v-slot:prepend-inner>
              <v-icon
                icon="mdi-magnify"
                variant="tonal"
                color="button_color"
                class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn
          color="button_color"
          @click="addReminder"
          icon="mdi-plus"
          class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="reminders"
            ><v-icon>mdi-export</v-icon></download-excel
          >
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="updatedReminders"
      :search-field="searchField"
      :search-value="searchValue"
      :loading="loading"
      border-cell
      class="custom-data-table"
      body-text-direction="center"
      header-text-direction="center"
      :body-row-class-name="bodyRowClassNameFunction"
      no-hover
    >
      <template #item-edit="item">
        <v-icon @click="ShowReminderDetails(item)">mdi-pencil</v-icon>
      </template>
      <template #item-done="item">
        <v-icon
          v-model="item.done"
          :color="primaryColor1()"
          :icon="
            item.done ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
          "
          @click="changeReminderStatus(item)"
          hide-details
        />
      </template>
    </EasyDataTable>
  </v-container>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="closeModals()"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
  <v-dialog v-model="createReminderModal" width="auto">
    <create-reminder-modal
      @successfulReminderOperation="this.getReminders()"
      @closeModal="this.createReminderModal = false"
      :reminderToEdit="selectedReminder"
    ></create-reminder-modal>
  </v-dialog>
  <v-dialog v-model="reminderDetailsModal" width="auto">
    <reminder-details-modal
      @editReminder="handleEditReminderSignal"
      @deleteReminder="handleDeleteReminderSignal"
      :reminder="selectedReminder"
    ></reminder-details-modal>
  </v-dialog>
</template>

<script>
import { unifiedResponseHandler } from '@/utils/helpers';
import Broken_Page_V01 from '../../Broken_Page_V01.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import reminderService from '@/services/reminder.service.js';
import CreateReminderModal from '@/components/HomeManagement/MyDashboard/create-reminder-modal.vue';
import ReminderDetailsModal from '@/components/HomeManagement/MyDashboard/reminder-details-modal.vue';
import { useTheme } from 'vuetify';

export default {
  name: 'reminder_V01',
  components: {
    ReminderDetailsModal,
    CreateReminderModal,
    Success_Modal,
    Error_Modal,
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: 'Remind Date', value: 'nextAlarmDate' },
        { text: 'Created By', value: 'ownerName' },
        { text: 'Done', value: 'done' },
        { text: 'Edit/Details', value: 'edit' },
      ],
      reminders: [],
      searchField: '',
      searchValue: '',
      broken_page: false,
      createReminderModal: false,
      reminderDetailsModal: false,
      loading: false,
      success: false,
      error: false,
      message: null,
      selectedReminder: null,
    };
  },
  methods: {
    closeModals() {
      this.success = false;
      this.reminderDetailsModal = false;
      this.createReminderModal = false;
    },
    async getReminders() {
      this.loading = true;
      let response = unifiedResponseHandler(
        await reminderService.getAllReminders()
      );
      if (response.success) {
        this.reminders = response.data;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    ShowReminderDetails(item) {
      for (let i = 0; i < this.reminders.length; i++)
        if (this.reminders[i].id === item.id)
          this.selectedReminder = JSON.parse(JSON.stringify(this.reminders[i]));
      this.selectedReminder.remained = this.convertToHumanReadableFormat(
        this.selectedReminder.remained
      );
      this.reminderDetailsModal = true;
    },
    editReminder(item) {
      this.selectedReminder = item;
      this.createReminderModal = true;
    },
    addReminder() {
      this.selectedReminder = null;
      this.createReminderModal = true;
    },
    handleEditReminderSignal(data) {
      this.reminderDetailsModal = false;
      this.editReminder(data);
    },
    handleDeleteReminderSignal(data) {
      this.deleteReminder(data);
    },
    primaryColor1() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor1;
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async changeReminderStatus(item) {
      this.loading = true;
      let response = unifiedResponseHandler(
        await reminderService.updateReminderStatus(item.id, !item.done)
      );
      if (response.success) {
        this.message = response.data;
        this.success = true;
        await this.sleep(250);
        this.reminders.forEach(
          (reminder) =>
            reminder.id === item.id && (reminder.done = !reminder.done)
        );
        this.loading = false;
      } else {
        this.error = true;
        this.message = response.message || 'An error occurred';
      }
    },

    async deleteReminder(item) {
      let response = unifiedResponseHandler(
        await reminderService.deleteReminder(item)
      );
      if (response.success) {
        this.message = response.data;
        this.success = true;
        await this.getReminders();
      } else {
        this.error = true;
        this.message = response.message || 'An error occurred';
      }
    },
    convertToHumanReadableFormat(duration) {
      if (duration === '0-0 00:00') return "Time's Up!";
      const [datePart, timePart] = duration.split(' ');
      const [months, days] = datePart.split('-').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);
      if (months > 0) {
        return days > 0 ? `${months} months ${days} days` : `${months} months`;
      } else if (days > 0) {
        return hours > 0 ? `${days} days ${hours} hours` : `${days} days`;
      } else if (hours > 0) {
        return minutes > 0
          ? `${hours} hours ${minutes} minutes`
          : `${hours} hours`;
      } else {
        return `${minutes} minutes`;
      }
    },
    bodyRowClassNameFunction(item) {
      if (item.done) return '';
      return 'reminder-row';
    },
    parseRemained(remained) {
      const durationRegex = /^(\d+)-(\d+)\s+(\d+):(\d+)$/;
      const match = remained.match(durationRegex);
      if (!match) return 0;
      // eslint-disable-next-line no-unused-vars
      const [_, months = 0, days = 0, hours = 0, minutes = 0] =
        match.map(Number);
      return months * 30 * 24 * 60 + days * 24 * 60 + hours * 60 + minutes;
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return date.toLocaleDateString('en-US', options);
    },
  },
  computed: {
    updatedReminders() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.reminders
        .sort((a, b) => {
          if (a.done && !b.done) return 1;
          if (!a.done && b.done) return -1;
          return (
            this.parseRemained(a.remained) - this.parseRemained(b.remained)
          );
        })
        .map((reminder) => ({
          id: reminder.id,
          type: reminder.type,
          title: reminder.title,
          nextAlarmDate:
            reminder.nextAlarmDate != null
              ? this.formatDate(reminder.nextAlarmDate)
              : '-',
          ownerName: reminder.ownerName,
          done: reminder.done,
        }));
    },
  },
  async mounted() {
    await this.getReminders();
  },
};
</script>

<style>
.reminder-row {
  --easy-table-body-row-background-color: rgba(
    var(--v-theme-primaryColor2),
    0.4
  );
}
</style>
